import { Pipe, PipeTransform } from '@angular/core';
import { MenuWidgetItemActions } from '@shared/types/actions.types';

@Pipe({ name: 'isMenuWidgetSpecial' })
export class IsMenuWidgetSpecialPipe implements PipeTransform {

	transform(actionType: string): boolean {
		return Object.keys(MenuWidgetItemActions).includes(actionType);
	}

}
