import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArtifactWidgetTypeResponseDto } from '@api/models/artifact-widget-type-response-dto';
import { CardWidgetTypeResponseDto } from '@api/models/card-widget-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { ListWidgetTypeResponseDto } from '@api/models/list-widget-type-response-dto';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AttributeWithPriorityOrder, DefaultPageParams } from '@private/types/page-builder-helper.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { RangedStyleValue } from '@shared/components/grid-layout-generator/types/ranged-style-value';
import { DESCRIPTION_KEY, PRIMARY_TEXT_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { LinkMethods } from '@shared/methods/link.methods';
import { ArtifactTypeFormatEnum, NewArtifactType, NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DisplayAttributeType } from '@shared/types/display-at-types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { NewTableColumn, NewTableColumnMetaData } from '@shared/types/table.types';
import { WidgetResponseDto } from '@shared/types/widget.types';
import { ArtifactTabSettings, ArtifactWidgetFormItemDto } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { ArtifactWidgetOptions } from '@widgets/artifact-widget/types/artifact-widget-options.types';
import { ArtifactWidgetSettings } from '@widgets/artifact-widget/types/artifact-widget-settings.types';
import { ArtifactWidgetFormatsMap, ArtifactWidgetModel, ArtifactWidgetValue } from '@widgets/artifact-widget/types/artifact-widget.types';
import { CardWidgetAreaContentItem } from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { ContentType } from '@widgets/card-widget/types/content-type';
import { ListWidgetTableLoadModeEnum } from '@widgets/shared/components/artifact-list-table/types/list-widget-settings.types';
import { ListWidgetModel, ListWidgetType } from '@widgets/shared/components/artifact-list-table/types/list-widget.types';
import { ReplaceRuntimeVariablesPipe } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import {
  AttributeFormatSettings,
  AttributeLabelFormatSettings,
  AttributeValueFormatSettings,
  FormatStyles,
} from '@widgets/shared/types/attribute-format-settings.types';
import { FontWeightEnum } from '@widgets/shared/types/style.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WidgetGeneratorHelper {
  defaultLabelColor = '#CCCCCC';
  defaultLabelFontSize = '10px';

  constructor(
    private readonly tenantWidgetService: TenantWidgetService,
    private readonly cache: NewCacheService,
    private readonly route: ActivatedRoute,
    private readonly replaceRuntimeVariablesPipe: ReplaceRuntimeVariablesPipe,
  ) {}

  async createDefaultArtifactWidget(artifactType: NewArtifactType, defaultPageParams: DefaultPageParams): Promise<ArtifactWidgetTypeResponseDto> {
    const model = await this.createDefaultArtifactWidgetModel(artifactType, defaultPageParams);
    const blockPart = new BlockPartWidget({ code: WidgetType.artifact, value: new ArtifactWidgetValue(model) } as unknown as WidgetResponseDto);

    return (await lastValueFrom(this.tenantWidgetService.widgetControllerCreate({ body: blockPart.requestDto }))) as ArtifactWidgetTypeResponseDto;
  }

  async createDefaultCardWidget(artifactType: NewArtifactType, defaultPageParams: DefaultPageParams): Promise<CardWidgetTypeResponseDto> {
    const model = await this.createDefaultCardWidgetModel(artifactType, defaultPageParams);
    const blockPart = new BlockPartWidget({ code: WidgetType.card, value: { model: model.toServer() } } as unknown as WidgetResponseDto);

    return (await lastValueFrom(this.tenantWidgetService.widgetControllerCreate({ body: blockPart.requestDto }))) as CardWidgetTypeResponseDto;
  }

  async createDefaultListWidget(): Promise<ListWidgetTypeResponseDto> {
    const model = this.createDefaultListWidgetModel();
    const blockPart = new BlockPartWidget({ code: WidgetType.listNew, value: { model: new ListWidgetModel(model) } } as unknown as WidgetResponseDto);

    return (await lastValueFrom(this.tenantWidgetService.widgetControllerCreate({ body: blockPart.requestDto }))) as ListWidgetTypeResponseDto;
  }

  private async createDefaultArtifactWidgetModel(artifactType: NewArtifactType, defaultPageParams: DefaultPageParams): Promise<ArtifactWidgetModel> {
    const { attributes, dataTypes, artifactTypeDto, artifactTypes, linkTypes } = defaultPageParams;

    const form: ArtifactWidgetFormItemDto[] = Object.keys(artifactType.attributes).map(key => ({
      id: key,
      meta: null,
      columns: '12',
      tabSettings: new ArtifactTabSettings(),
    }));

    const relevantLinkTypes = (this.cache.data.linkTypes.value as LinkTypeResponseDto[]).filter(linkType => {
      const { restrictions } = linkType as LinkTypeResponseDto;

      if (restrictions) {
        return restrictions.some(
          restriction => restriction.sourceArtifactTypeId === artifactType.id || restriction.destinationArtifactTypeId === artifactType.id,
        );
      }

      return false;
    });
    const restrictions = LinkMethods.getLinkRestrictionsForArtifactType(artifactType.id, relevantLinkTypes);
    const settings = new ArtifactWidgetSettings({
      urlChangeAction: true,
      addCreatedArtifactIdToUrlParam: artifactType.format === ArtifactTypeFormatEnum.module,
      addCreatedFolderIdToUrlParam: artifactType.format === ArtifactTypeFormatEnum.module,
    });

    const options = new ArtifactWidgetOptions();
    options.artifactTypes = artifactTypes;
    options.artifactTypes.listMap[artifactType.id] = artifactType;
    options.attributes = attributes;
    options.dataTypes = dataTypes;
    await options.setLinkTypeOptionsAndIdsMap(restrictions, linkTypes);

    const model = new ArtifactWidgetModel({ replaceRuntimeVariablesPipe: this.replaceRuntimeVariablesPipe });
    await model.setFromDto({
      options,
      model: {
        selected: { artifactTypeId: artifactTypeDto.id, linkType: null },
        form,
        formatsMap: new ArtifactWidgetFormatsMap(),
        linkRestrictions: restrictions,
        settings,
      },
      attributes,
      cache: this.cache,
      route: this.route,
    });

    return model;
  }

  private async createDefaultCardWidgetModel(artifactType: NewArtifactType, defaultPageParams: DefaultPageParams): Promise<CardWidgetModel> {
    const model = CardWidgetModel.initial();
    model.grid.gridTemplateRows = RangedStyleValue.fromValueAndBreakpoint('auto', { value: Infinity });
    model.styles.background = 'transparent';

    const defaultLabelSettings = new AttributeLabelFormatSettings({
      styles: new FormatStyles({ color: this.defaultLabelColor, fontSize: this.defaultLabelFontSize }),
      visible: true,
    });

    const systemNameValueFormat = new AttributeValueFormatSettings({
      styles: new FormatStyles({ fontWeight: FontWeightEnum.bold, fontSize: '20px' }),
    });

    const systemNameAttributeItem = new CardWidgetAreaContentItem(ContentType.attribute, GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId));

    model.settings.artifactTypeId = artifactType.id;
    model.areas[0].content.items = [systemNameAttributeItem];
    model.settings.attributeStyles[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)] = {
      enumAttributeSettings: {},
      settings: new AttributeFormatSettings({ label: defaultLabelSettings, value: systemNameValueFormat }),
    };

    // push attributes sorted, create styles
    const attributesSorted = this.getAttributesSortedByPriority(
      artifactType.attributes,
      defaultPageParams,
      GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId),
    );
    attributesSorted.forEach(attribute => {
      model.areas[0].content.items.push(new CardWidgetAreaContentItem(ContentType.attribute, attribute.attribute.id));
      model.settings.attributeStyles[attribute.attribute.id] = {
        enumAttributeSettings: {},
        settings: new AttributeFormatSettings({
          label: defaultLabelSettings,
          value: this.getValueFormatSettings(attribute.dataType.baseDataType, attribute.attribute.multipleValues, attribute.dataType.kind),
        }),
      };
    });

    [NonAttributeKeys.CREATED_BY_ID, NonAttributeKeys.CREATED_ON_ID, NonAttributeKeys.UPDATED_BY_ID, NonAttributeKeys.UPDATED_ON_ID].forEach(id => {
      model.areas[0].content.items.push(new CardWidgetAreaContentItem(ContentType.attribute, id));
      model.settings.attributeStyles[id] = {
        enumAttributeSettings: {},
        settings: new AttributeFormatSettings({ label: defaultLabelSettings, value: this.getValueFormatSettings(BaseDataType.user, false) }),
      };
    });

    return model;
  }

  private getAttributesSortedByPriority(
    artifactTypeAttributes: Record<string, NewArtifactTypeClientAttribute>,
    defaultPageParams: DefaultPageParams,
    systemNameAttrId: string,
  ): AttributeWithPriorityOrder[] {
    const { attributes, dataTypes } = defaultPageParams;

    const attributesWithPriority: AttributeWithPriorityOrder[] = [];

    Object.keys(artifactTypeAttributes).forEach(id => {
      const attribute = attributes.listMap[id];
      const dataType = dataTypes.listMap[attribute.dataTypeId];
      const priority = this.getAttributeOrderPriority(attribute, dataType);
      attribute.id !== systemNameAttrId && attributesWithPriority.push({ attribute, dataType, priority });
    });

    return attributesWithPriority.sort((a, b) => a.priority - b.priority);
  }

  private getAttributeOrderPriority(attribute: NewAttribute, dataType: NewDataType): number {
    let priority = 4;
    if (dataType.baseDataType === BaseDataType.text) priority = 1;
    if (dataType.baseDataType === BaseDataType.html) {
      priority = 3;
      if ([DESCRIPTION_KEY, PRIMARY_TEXT_KEY].includes(attribute.name.toLowerCase())) priority = 2;
    }

    return priority;
  }

  private getValueFormatSettings(baseDataType: BaseDataType | null, multiValue: boolean, kind?: DataTypeKind): AttributeValueFormatSettings {
    if (baseDataType === BaseDataType.user) {
      return this.getUserValueFormatSettings(multiValue);
    }
    if (kind === DataTypeKind.enumerated) {
      return this.getEnumValueFormatSettings();
    }
    if (baseDataType === BaseDataType.file) {
      return this.getFileValueFormatSettings();
    }
    return new AttributeValueFormatSettings();
  }

  private getUserValueFormatSettings(multiValue: boolean): AttributeValueFormatSettings {
    if (multiValue) {
      return new AttributeValueFormatSettings({
        displayMetadata: { attributeType: DisplayAttributeType.user, selectedVariantCode: 'ICON_ONLY' },
      });
    } else {
      return new AttributeValueFormatSettings({
        displayMetadata: { attributeType: DisplayAttributeType.user, selectedVariantCode: 'ICON_AND_NAME' },
      });
    }
  }

  private getEnumValueFormatSettings(): AttributeValueFormatSettings {
    return new AttributeValueFormatSettings({
      displayMetadata: { attributeType: DisplayAttributeType.enumerated, selectedVariantCode: 'CHIP_V1' },
    });
  }

  private getFileValueFormatSettings(): AttributeValueFormatSettings {
    return new AttributeValueFormatSettings({
      displayMetadata: { attributeType: DisplayAttributeType.file, selectedVariantCode: 'DEFAULT' },
    });
  }

  private createDefaultListWidgetModel(): ListWidgetModel {
    const listWidgetModel = new ListWidgetModel();
    listWidgetModel.settings.loadMode = ListWidgetTableLoadModeEnum.byModule;
    listWidgetModel.settings.showAddButton = true;
    listWidgetModel.selected.columns = [
      new NewTableColumn({ label: NonAttributeKeys.IS_HEADING_LABEL, key: NonAttributeKeys.IS_HEADING }),
      new NewTableColumn({ label: NonAttributeKeys.SECTION_LABEL, key: NonAttributeKeys.SECTION }),
      new NewTableColumn({
        label: NonAttributeKeys.CONTENT_LABEL,
        key: NonAttributeKeys.CONTENT,
        meta: new NewTableColumnMetaData({
          displayAtEnumType: DisplayAttributeType.text,
          displayAtMetadata: { attributeType: DisplayAttributeType.text, selectedVariantCode: 'DEFAULT_CARD' },
        }),
      }),
    ];
    listWidgetModel.listType = ListWidgetType.artifact;
    listWidgetModel.settings.customColors = true;
    listWidgetModel.settings.background = { rows: 'transparent' };

    return listWidgetModel;
  }
}
