import { ListWidgetTypeResponseDto } from '@api/models/list-widget-type-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';

export class LinkingPopupUtil {
  static toServer(linkingPopupDtoMap: Record<string, Record<string, Partial<ListWidgetTypeResponseDto>>>): Record<string, Record<string, string>> {
    if (!linkingPopupDtoMap || !Object.keys(linkingPopupDtoMap)) return {};

    return Object.entries(linkingPopupDtoMap).reduce<Record<string, Record<string, string>>>(
      (res, [key, value]) => {
        res[key] = Object.entries(value).reduce<Record<string, string>>(
          (res, [key, value]) => {
            res[key] = value.id!;
            return res;
          },
          {} as Record<string, string>,
        );
        return res;
      },
      {} as Record<string, Record<string, string>>,
    );
  }

  static async processLinkingPopupDtoMap(
    linkingPopupDtoMap: Record<string, Record<string, string>>,
    cache: NewCacheService,
  ): Promise<Record<string, Record<string, ListWidgetTypeResponseDto>>> {
    if (!linkingPopupDtoMap || !Object.keys(linkingPopupDtoMap)) return {};

    const ids = LinkingPopupUtil.collectIdentifiers(linkingPopupDtoMap);
    const dtos = (await cache.data.widgets.getManyAsync(ids)) as ListWidgetTypeResponseDto[];
    const dtoMap = new Map<string, ListWidgetTypeResponseDto>();

    dtos.forEach(dto => dtoMap.set(dto.id, dto));

    return Object.entries(linkingPopupDtoMap).reduce<Record<string, Record<string, ListWidgetTypeResponseDto>>>(
      (res, [key, value]) => {
        res[key] = Object.entries(value).reduce<Record<string, ListWidgetTypeResponseDto>>(
          (res, [key, id]) => {
            dtoMap.has(id) && (res[key] = dtoMap.get(id)!);
            return res;
          },
          {} as Record<string, ListWidgetTypeResponseDto>,
        );
        return res;
      },
      {} as Record<string, Record<string, ListWidgetTypeResponseDto>>,
    );
  }

  private static collectIdentifiers(linkingPopupDtoMap: Record<string, Record<string, string>>): string[] {
    const values: string[] = [];

    for (const firstLevelKey in linkingPopupDtoMap) {
      const secondLevelObject = linkingPopupDtoMap[firstLevelKey];
      for (const secondLevelKey in secondLevelObject) {
        values.push(secondLevelObject[secondLevelKey]);
      }
    }

    return values;
  }
}
