<div
  (cdkDropListDropped)="dropWidget($event)"
  [cdkDragData]="{ partIndex: partIndex }"
  [cdkDropListConnectedTo]="'widgetList'"
  [cdkDropListData]="{ part: part }"
  [id]="(dragDropService.partIds$ | async)?.tree[sectionIndex][rowIndex][blockIndex][partIndex]"
  cdkDrag
  cdkDropList
  class="toolbar-container"
>
  <div class="toolbar" [attr.id]="part.htmlId">
    <i cdkDragHandle class="bi bi-arrows-move toolbar__icon" pRipple pTooltip="{{ 'Move' | translate }}"
       tooltipPosition="top"></i>
    <i (click)="style()" class="bi bi-sliders toolbar__icon" pRipple pTooltip="{{ 'Style' | translate }}"
       tooltipPosition="top"></i>
    <i (click)="advancedMode()" class="bi bi-tools toolbar__icon" pRipple pTooltip="{{ 'Advanced Mode' | translate }}"
       tooltipPosition="top"></i>
    <ng-container *ngIf="part.widget">
      <i
        (click)="saveAsTemplate()"
        *ngIf="!part.widget.templateId; else reusedTemplateIcon"
        class="pi pi-save toolbar__icon"
        pRipple
        pTooltip="{{ 'Save as Template' | translate }}"
        tooltipPosition="top"
      ></i>
      <ng-template #reusedTemplateIcon>
        <i
          [escape]="false"
          class="bi bi-recycle toolbar__icon"
          disabled
          pRipple
          pTooltip="<div style='text-align: center'>{{ 'Reused template' | translate }}<br>{{ part.widget.templateName }}</div>"
          tooltipPosition="top"
        ></i>
      </ng-template>
    </ng-container>
    <i (click)="copy()" class="pi pi-copy toolbar__icon" pRipple pTooltip="{{ 'Copy' | translate }}"
       tooltipPosition="top"></i>
    <i
      (click)="paste()"
      *ngIf="copyPasterService.hasBlockPartCopy$ | async"
      class="bi bi-clipboard-plus toolbar__icon"
      pRipple
      pTooltip="{{ 'Paste' | translate }}"
      tooltipPosition="top"
    ></i>
    <i (click)="delete()" class="bi bi-trash toolbar__icon" pRipple pTooltip="{{ 'Delete' | translate }}"
       tooltipPosition="top"></i>
    <i (click)="export()" class="pi pi-cloud-download toolbar__icon" pRipple pTooltip="{{ 'Export' | translate }}"
       tooltipPosition="top"></i>
    <i (click)="setId($event)" class="bi bi-key toolbar__icon" pRipple
       pTooltip="{{ 'Set id for autoscroll' | translate }}" tooltipPosition="top"></i>
  </div>

  <div (click)="addWidget()" *ngIf="!part.widget" class="add-widget">
    <i class="bi bi-plus-square-dotted add-widget__icon"></i>
  </div>

  <div [ngStyle]="part.styles | imageFilterStylesAdmin"
        class="block-part-body pseudo-opacity">
    <div *ngIf="part.widget && applicationSwitcherService.selectedApplication as application" [id]="part.hash || ''">
      <app-widget-container
        [applicationId]="application.id"
        [page]="page"
        [widgetId]="part.hash"
        [hash]="part.hash"
        [idPrefix]="idPrefix"
        [isLayoutMode]="true"
        [widget]="part.widget"
        appWidgetContainerStopPropagation
      ></app-widget-container>
    </div>
  </div>

  <i (click)="addBlockPart()" class="bi bi-plus-circle toolbar-container__add-sibling"></i>
</div>
