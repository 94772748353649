import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactListItemClickAction } from '../../../types/artifact-list-item-click-action';

@Pipe({ name: 'isListWidgetSpecial' })
export class IsListWidgetSpecialPipe implements PipeTransform {

	transform(actionType: string): boolean {
		return Object.keys(ArtifactListItemClickAction).includes(actionType);
	}

}
