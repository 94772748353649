<span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>

<ng-container *ngIf="!attribute.multipleValues">
  <ng-container *ngIf="formatSettings.value.fieldStyles as styles">
    <div [ngClass]="{'p-inputgroup': styles.prefix || styles.suffix}">
      <span *ngIf="styles.prefix" class="p-inputgroup-addon p-0">
        {{ styles.prefix }}
      </span>
      <input (blur)="onBlur()"
             [(ngModel)]="attr.value"
             (ngModelChange)="checkNumFormat($event, 'intField' + index, attr, 'value')"
             [id]="'intField' + index"
             [placeholder]="placeholder"
             pInputText />
      <span *ngIf="styles.suffix" class="p-inputgroup-addon p-0">
      {{ styles.suffix }}
    </span>
    </div>
  </ng-container>
  <app-artifact-attribute-form-field-label-with-icon [attribute]="attribute"
                                                     [formatSettings]="formatSettings"
                                                     [label]="label"
                                                     [fieldHtmlId]="'intField' + index"
                                                     [canContainPlaceholder]="true"
                                                     [labelBehaviour]="labelBehaviour"
                                                     [placeholder]="placeholder">
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>

<ng-container *ngIf="attribute.multipleValues">
  <p-chips (onBlur)="onBlur()"
           [(ngModel)]="attr.value"
           (ngModelChange)="checkNumFormatMultiple()"
           [inputId]="'intFieldM' + index"
           [ngClass]="{'p-chips-filled': attr.value?.length}"
           [placeholder]="placeholder">
  </p-chips>
  <app-artifact-attribute-form-field-label-with-icon [attribute]="attribute"
                                                     [formatSettings]="formatSettings"
                                                     [label]="label"
                                                     [fieldHtmlId]="'intFieldM' + index"
                                                     [canContainPlaceholder]="true"
                                                     [labelBehaviour]="labelBehaviour"
                                                     [placeholder]="placeholder">
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>
