import { DataTypeValueResponseDto } from '@api/models';
import { FlexContainer, Padding } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { AttributeLabelFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import {
  AlignItems,
  FlexDirection,
  FontFamilyEnum,
  FontStyleEnum,
  FontWeightEnum,
  JustifyContent,
  TextDecorationEnum,
  TextHorizontalAlignEnum,
  TextVerticalAlignEnum,
} from '@widgets/shared/types/style.types';
import { FilterMetadata } from 'primeng/api';
import { ColumnFilter } from 'primeng/table';
import { NewAttribute } from './attribute.types';
import { NewDataType } from './data-type.types';
import { DisplayAtMetadata, DisplayAttributeType } from './display-at-types';
import { LinkRestrictionParamsBase } from './link.types';

export class TableColumn {
  constructor(
    public label: string,
    public key: string,
    public filterType?: string,
    public meta?: any,
  ) {}
}

export class TableColumnGroup {
  label: string | null = null;
  value: string | null = null;
  meta: any = null;
  items: NewTableColumn[] = [];

  constructor(group?: Partial<TableColumnGroup>) {
    group && Object.assign(this, group);
  }
}

abstract class AbstractTableColumnSettings<T> {
  columns: Record<string, T> = {};

  constructor(columns?: Partial<T>) {
    columns && Object.assign(this, columns);
  }
}

export class TableFormatSettings extends AbstractTableColumnSettings<TableColumnFormatSettings> {}

export class LegacyTableColumnFormat implements FlexContainer, Padding {
  fontWeight: FontWeightEnum = FontWeightEnum.normal;
  fontStyle: FontStyleEnum = FontStyleEnum.normal;
  fontSize = '14px';
  textDecoration: TextDecorationEnum = TextDecorationEnum.none;
  textAlign: TextHorizontalAlignEnum = TextHorizontalAlignEnum.left;
  color?: string;
  backgroundColor?: string;
  fontFamily: FontFamilyEnum = FontFamilyEnum.nunito;
  // padding
  paddingTop: string;
  paddingRight: string;
  paddingBottom: string;
  paddingLeft: string;
  // flex
  flexDirection: FlexDirection = FlexDirection.row;
  justifyContent: JustifyContent = JustifyContent.spaceBetween;
  alignItems: AlignItems = AlignItems.flexEnd;

  constructor(tableColumnFormat?: Partial<LegacyTableColumnFormat>) {
    tableColumnFormat && Object.assign(this, tableColumnFormat);
  }
}

export class TableColumnFormat extends AttributeLabelFormatSettings {
  static readonly MANDATORY_ATTRIBUTE_NAME = 'styles';

  constructor(tableColumnFormat?: Partial<TableColumnFormat>) {
    super(tableColumnFormat);
    if (!tableColumnFormat) {
      this.styles.verticalAlign = TextVerticalAlignEnum.bottom;
    }
  }

  static fromLegacyTableColumnFormat(legacyTableColumnFormat: LegacyTableColumnFormat): TableColumnFormat {
    const tableColumnFormat = new TableColumnFormat();
    tableColumnFormat.styles.backgroundColor = legacyTableColumnFormat.backgroundColor;
    tableColumnFormat.styles.fontWeight = legacyTableColumnFormat.fontWeight;
    tableColumnFormat.styles.fontStyle = legacyTableColumnFormat.fontStyle;
    tableColumnFormat.styles.fontSize = legacyTableColumnFormat.fontSize;
    tableColumnFormat.styles.textDecoration = legacyTableColumnFormat.textDecoration;
    tableColumnFormat.styles.color = legacyTableColumnFormat.color;
    tableColumnFormat.styles.fontFamily = legacyTableColumnFormat.fontFamily;
    // alignment adaptation
    switch (legacyTableColumnFormat.justifyContent) {
      case JustifyContent.center:
        tableColumnFormat.styles.textAlign = TextHorizontalAlignEnum.center;
        break;
      case JustifyContent.flexEnd:
        tableColumnFormat.styles.textAlign = TextHorizontalAlignEnum.right;
        break;
      default:
        tableColumnFormat.styles.textAlign = TextHorizontalAlignEnum.left;
        break;
    }
    tableColumnFormat.styles.verticalAlign = TextVerticalAlignEnum.bottom;
    return tableColumnFormat;
  }

  static isLegacyTableColumnFormat(tableColumnFormat: any): boolean {
    return Object.keys(tableColumnFormat).length > 0 && !tableColumnFormat[TableColumnFormat.MANDATORY_ATTRIBUTE_NAME];
  }
}

export interface TableColumnFormatSettings {
  columnKey: string;
  columnFormat?: TableColumnFormat | LegacyTableColumnFormat;
  contentColumnFormat?: TableColumnFormat;
  hideFilter?: boolean;
  hideSorting?: boolean;
  selectedVariant?: string;
  selectedEditVariant?: string;
  selectedVariantMetadata?: any;
  sortBy?: SortEnumColumnBy;
}

export class TableParamFilterSettings extends AbstractTableColumnSettings<TableColumnParamFilterSettings> {}

export interface TableColumnParamFilterSettings {
  enableParamFiltering?: boolean;
  paramAttributeName?: string;
}

export class NewTableColumn {
  public label: string;
  public key: string;
  public meta = new NewTableColumnMetaData();

  constructor(column?: Partial<NewTableColumn>) {
    column && Object.assign(this, column);
  }
}

export class NewTableColumnMetaData {
  filterType: FilterType | null = null;
  filterKey: string; // TODO: should be attribute id - check this
  filterUrlTypeService: TableFilterUrlTypeService;
  dbFilterKey: string;
  // linkDirection: LinkDirection | null = null;
  isLink = false;
  isAttribute = false;
  isLinked = false;
  linkRestrictionParams?: LinkRestrictionParamsBase;
  // linkTypeId: string | null = null;
  artifactTypeId: string | null = null;
  displayAtEnumType: DisplayAttributeType = DisplayAttributeType.simple;
  /** meta data for attribute in case isAttribute is true */
  attributeMetadata?: NewTableColumnAttributeMetadata;
  displayAtMetadata?: DisplayAtMetadata;
  columnFormatSettings: TableColumnFormatSettings;
  columnParamFilterSettings: TableColumnParamFilterSettings;
  /** enum options for artifact-type column*/
  artifactTypeEnumOptions?: DataTypeValueResponseDto[];

  constructor(tableColumnMetaData?: Partial<NewTableColumnMetaData>) {
    tableColumnMetaData && Object.assign(this, tableColumnMetaData);
    if (this.attributeMetadata?.dataType?.isEnum && !this.columnFormatSettings.sortBy) this.columnFormatSettings.sortBy = SortEnumColumnBy.order;
  }
}

export class NewTableColumnAttributeMetadata {
  attribute: NewAttribute;
  dataType: NewDataType;

  constructor(attribute: NewAttribute, dataType: NewDataType) {
    this.attribute = attribute;
    this.dataType = dataType;
  }
}

export interface ComplexFilterMetadata {
  tableColumnMetadata: NewTableColumnMetaData;
  filterMetadata: FilterMetadata[];
}

export enum SortEnumColumnBy {
  label = 'Label',
  value = 'Value',
  order = 'Order',
}

export enum FilterType {
  text = 'text',
  numeric = 'numeric',
  date = 'date',
  datetime = 'datetime',
  time = 'time',
  boolean = 'boolean',
  enum = 'enum',
  user = 'user',
  link = 'link',
  folder = 'folder',
  hyperlink = 'hyperlink',
  counter = 'counter',
}

export interface TableFilterUrlTypeService {
  doApplyFilter(column: NewTableColumn, filterValue: string, columnFilterRef: ColumnFilter): void;

  getFilterMetadata(filterValue: string | string[]): FilterMetadata[];

  getUrlQueryAttributeValue(rawQueryAttributeValue: string): string | string[];
}
