import { Component, ElementRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { NAME_KEY } from '@shared/constants/constants';
import { AnnouncementService } from '@shared/services/announcement.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { NewArtifact } from '@shared/types/artifact.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewTableColumn } from '@shared/types/table.types';
import { ArtifactLinkDialogComponent } from '@widgets/link-popup/artifact-link-dialog.component';
import { ListWidgetHelper } from '@widgets/list-widget-new/services/list-widget.helper';
import { ListWidgetService } from '@widgets/list-widget-new/services/list-widget.service';
import { ArtifactGroupingService } from '@widgets/shared/components/artifact-list-table/services/grouping/artifact-grouping.service';
import { ListWidgetTableHelper } from '@widgets/shared/components/artifact-list-table/services/list-widget-table.helper';
import { ArtifactListTableIdentifiers } from '@widgets/shared/components/artifact-list-table/types/artifact-list-widget-table.types';
import { GroupAttributeItem } from '@widgets/shared/components/artifact-list-table/types/list-widget-grouping.types';
import { ListWidgetLinkSettingsTypes } from '@widgets/shared/components/artifact-list-table/types/list-widget-link-settings.types';
import { ListWidgetTableLoadModeEnum } from '@widgets/shared/components/artifact-list-table/types/list-widget-settings.types';
import { ListWidgetModel, ListWidgetValue } from '@widgets/shared/components/artifact-list-table/types/list-widget.types';
import { FolderPickerComponent } from '@widgets/shared/components/folder-picker/folder-picker.component';
import { ArtifactClickHandlerService } from '@widgets/shared/services/artifact-click-handler.service';
import { LinkDialogOpenArguments } from '@widgets/shared/types/link-popup/link-dialog-open-arguments';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, HASH, IS_LAYOUT_MODE, LABEL, LIST_SELECTION_SETTINGS, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';

@Component({
  selector: 'app-list-widget-new',
  templateUrl: './list-widget.component.html',
  styleUrls: ['./list-widget.component.scss'],
  providers: [ListWidgetHelper, ListWidgetService],
})
export class ListWidgetComponent extends WidgetsCoreComponent<ListWidgetValue> implements OnInit {
  @ViewChild('folderPicker') folderPicker: FolderPickerComponent;
  @ViewChild('linkDialog') linkDialog: ArtifactLinkDialogComponent;
  m: ListWidgetModel;
  /** used in dropdown */
  applicableColumns: NewTableColumn[];
  loadMode = ListWidgetTableLoadModeEnum;
  NAME_VALUE = NAME_KEY;
  private readonly listWidgetTableHelper: ListWidgetTableHelper = inject(ListWidgetTableHelper);

  constructor(
    router: Router,
    route: ActivatedRoute,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<ListWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    @Inject(HASH) public hash: string,
    @Inject(LIST_SELECTION_SETTINGS) public listSelectionSettings: ListWidgetLinkSettingsTypes,
    public readonly s: ListWidgetService,
    public readonly helper: ListWidgetHelper,
    public readonly groupingService: ArtifactGroupingService,
    public readonly localStorageService: LocalStorageService,
    protected readonly artifactClickHandlerService: ArtifactClickHandlerService,
  ) {
    super(route, router, announcement, elRef);
  }

  get ids(): ArtifactListTableIdentifiers {
    return { applicationId: this.applicationId, hash: this.hash };
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    // helper must be inited before service!
    this.helper.init(this);
    await this.s.init(this);
    this.initApplicableColumns();
  }

  onLoadModeChange(): void {
    this.s.updateColumns();
    this.initApplicableColumns();
  }

  onArtifactTypeChange(): void {
    this.s.resetColumns();
    this.s.updateColumns();
    this.s.resetGrouping();
    this.helper.generateAddButtonOptions();
    this.initApplicableColumns();
  }

  onSelectedColumnsChange(columns: NewTableColumn[]): void {
    this.m.selected.setColumns(columns);
  }

  onGroupAttributeChangeCb(groupAttribute: GroupAttributeItem | undefined): void {
    groupAttribute && this.groupingService.setGroupingHandler(this.hash, groupAttribute, this.m.options);
  }

  onLinkDialogOpen(linkDialogArguments: LinkDialogOpenArguments) {
    this.linkDialog.open(linkDialogArguments);
  }

  async onMultiselectChange(): Promise<void> {
    this.m.selected.artifact = null;
    this.m.selected.artifacts = [];
    await this.removeEmittingKeyFromUrl();
  }

  async onArtifactSelect(artifact: NewArtifact): Promise<void> {
    this.artifactClickHandlerService.handleClickActions(artifact, this.m.settings.actions, this.queryParams, this.m.settings.emitFolderUrl, this.m.settings.urlKeys.emittingKeys.saveToFolderId);
  }

  async onArtifactUnselect(): Promise<void> {
    !this.m.settings.multiselect && (await this.removeEmittingKeyFromUrl());
  }

  async onFolderPathChange(folder: any): Promise<void> {
    this.m.settings.folderPath = folder.folderPath + folder.label;
    this.m.settings.folderId = folder.id;
    const artifactModule = await this.s.getModuleFromFolderId(folder.moduleId); // TODO: delete this method
    if (artifactModule) {
      const artifactType = this.m.options.artifactTypes.listMap[artifactModule?.artifactTypeId || ''];
      this.m.selected.module = new NewArtifact({
        dto: artifactModule,
        artifactTypesMap: this.m.options.artifactTypes.listMap,
      });
      this.m.selected.artifactTypes = [new SelectOption(artifactType.name, artifactType)];
    }
    this.s.updateColumns(this.m);
    this.initApplicableColumns();
    this.helper.generateAddButtonOptions();
    // TODO: lazyLoad table
    this.m.settings.showTable = false;
    setTimeout(() => (this.m.settings.showTable = true));
  }

  onFolderParamKeyChange(): void {
    this.helper.setFolderIdToAddButtonItems();
  }

  showPopupFolder(): void {
    this.folderPicker.showFolderPicker(new NewArtifact());
  }

  private async removeEmittingKeyFromUrl(): Promise<void> {
    const actions = this.m.settings.actions.filter(action => action.type === ArtifactListItemClickAction.addToLink);
    for (const action of actions) {
      action.emittingKey && (await this.artifactClickHandlerService.removeEmittingKeyFromUrl(this.queryParams, action.emittingKey));
    }
  }

  private initApplicableColumns(): void {
    if (!this.isLayoutMode) {
      this.applicableColumns = this.generateApplicableColumns();
    }
  }

  private generateApplicableColumns(): NewTableColumn[] {
    if (this.m.settings.loadMode === ListWidgetTableLoadModeEnum.byModule) {
      return this.generateColumnsFromAllAttributes();
    }

    if (!this.m.selected.artifactTypes.length) {
      return [];
    }

    return this.m.options.columns.list.filter(column => {
      if (!column.meta.isLink) {
        return true;
      } else {
        if (!this.m.restrictions) {
          return false;
        }
        const restrictions = this.m.restrictions;
        const [key, direction] = column.key.split('_');
        let isAllowedByRestriction = false;
        // .some used for performance optimization
        this.m.selected.artifactTypes.some(artifactType => {
          if (artifactType.value.id && restrictions[artifactType.value.id]) {
            restrictions[artifactType.value.id].some(restriction => {
              if (restriction.linkType?.value === key && restriction.linkType.meta === direction) {
                isAllowedByRestriction = true;
                return true;
              } else {
                return false;
              }
            });
          }
          return isAllowedByRestriction;
        });
        return isAllowedByRestriction;
      }
    });
  }

  private generateColumnsFromAllAttributes(): NewTableColumn[] {
    return this.listWidgetTableHelper.generateOptionColumns(this.m);
  }
}
