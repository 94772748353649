<app-loader [loading]="m.loading"></app-loader>
<app-attribute-autocomplete-overlay></app-attribute-autocomplete-overlay>
<app-formula-autocomplete-overlay></app-formula-autocomplete-overlay>

<div *ngIf="isPageBuilderReadyToRender && replaceRuntimeVariablesService.loaded" class="page-builder">
  <small *ngIf="m.isDefaultTenantPage" style="color: red; margin-bottom: 20px">
    {{ "* You can't set Alias and Query Parameters if this page is default" | translate }}
  </small>

  <div class="page-builder_header" [ngClass]="m.isHeaderVisible ? '': 'hide'">
    <div class="page-builder_header_name">
      <span class="dynamic-label">
        <input [(ngModel)]="m.page.name" (ngModelChange)="onPageNameChange($event)" id="name" pInputText type="text" />
        <label class="dynamic-label-text" for="name">{{ NAME_LABEL | translate }}</label>
      </span>
      <span class="dynamic-label">
        <p-dropdown [(ngModel)]="m.page.applicationId" [autoDisplayFirst]="false" [filter]="true"
                    [options]="m.applicationOptions" appendTo="body" id="artifactType"
                    (onChange)="setPossiblePageStructureChange()">
        </p-dropdown>
        <label class="dynamic-label-text" for="name">{{ 'Application' | translate }}</label>
      </span>
      <span class="dynamic-label">
        <input [(ngModel)]="m.page.alias" [disabled]="m.isDefaultTenantPage" id="pageAlias" pInputText type="text"
               (ngModelChange)="setPossiblePageStructureChange()" />
        <label class="dynamic-label-text" for="pageAlias">{{ ALIAS_LABEL | translate }}</label>
      </span>
      <span class="dynamic-label">
        <input [(ngModel)]="m.page.pageParameters" [disabled]="m.isDefaultTenantPage" id="pageParameters" pInputText
               type="text" (ngModelChange)="setPossiblePageStructureChange()" />
        <label class="dynamic-label-text" for="pageParameters">{{ 'Query Parameters' | translate }}</label>
      </span>

      <span *ngIf="m.user?.tenant?.isPublic" class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox
            (ngModelChange)="changeDefaultTenantPageFlag($event)"
            [(ngModel)]="m.isDefaultTenantPage"
            binary="true"
            inputId="isDefaultTenantPage"
          ></p-checkbox>
          <label for="isDefaultTenantPage">{{ 'Home Page' }}</label>
        </span>
      </span>
      <span *ngIf="m.user?.tenant?.isPublic" class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox [(ngModel)]="m.page.isPublic" binary="true" inputId="isPublic"
                      (onChange)="setPossiblePageStructureChange()"></p-checkbox>
          <label for="isPublic">{{ 'Public' }}</label>
        </span>
      </span>
    </div>
    <div class="page-builder_header_buttons">
      <button
        pButton
        pRipple
        pTooltip="{{ 'Page settings' | translate }}"
        tooltipPosition="left"
        type="button"
        icon="pi pi-cog"
        class="p-button-rounded p-button-text p-button-plain page-settings-button"
        (click)="openPageSettings()"
      ></button>
      <button pButton label="{{ 'Workflows' | translate }}" class="p-button p-button-info m-0 mr-1"
              (click)="openWorkflowSettings()"></button>
      <button *ngIf="m && m.page && m.page.id" class="p-button p-button-secondary mr-1"
              label="{{ 'Preview' | translate }}" pButton type="button"
              (click)="navigateToPreview()"></button>
      <a [routerLink]="['/admin/pages']">
        <button class="p-button p-button-warning mr-1" label="{{ 'Cancel' | translate }}" pButton
                type="button"></button>
      </a>
      <button
        (click)="save()"
        class="p-button p-button-success"
        label="{{ 'Save' | translate }}"
        pButton
        type="button"
        [disabled]="m.isInSavingProcess"
      ></button>
    </div>
  </div>

  <div *ngIf="m.page.sections?.length" class="page">
    <div
      (cdkDropListDropped)="dropSection($event)"
      [cdkDropListAutoScrollStep]="3"
      [cdkDropListData]="{ modalId: m.page.modalId }"
      cdkDropList cdkDropListLockAxis="y"
      class="page_content"
    >
      <app-page-section
        *ngFor="let section of m.page.sections; index as sectionIndex"
        [sectionIndex]="section.index"
        [section]="section"
        [page]="m.page"
        class="page-section"
      ></app-page-section>
    </div>
  </div>
  <section *ngIf="!m.loading" class="empty-page-section">
    <button
      (click)="showSectionTemplates()"
      class="p-button-rounded p-button-info p-button-outlined"
      icon="pi pi-plus"
      pButton
      pRipple
      pTooltip="{{ 'Add new section' | translate }}"
      type="button"
    ></button>
  </section>
  <p-sidebar
    #pbSidebar
    (onHide)="onPageBuilderSidebarHide()"
    (onShow)="onPageBuilderSidebarShow(pbSidebar)"
    *ngIf="applicationSwitcherService.selectedApplication"
    [(visible)]="m.pageBuilderSidebar.visible"
    [dismissible]="true"
    [modal]="m.pageBuilderSidebar.modal"
    [position]="m.pageBuilderSidebar.position"
    [fullScreen]="m.pageBuilderSidebar.fullScreen"
    [appendTo]="'body'"
    styleClass="widget-advanced-mode-sidebar"
  >
    <ng-template pTemplate="header">
      <app-sidebar-header
        [name]="advancedWidget?.code | underscoreToDash"
        [widgetId]="advancedWidget?.id"
        [isPageSettings]="isPageSettings"
        (onChangePosition)="togglePanelPosition()"
      ></app-sidebar-header>
    </ng-template>
    <div class="layout-wrapper dynamic-float-label">
      <br />
      <ng-container *ngIf="advancedWidget">
        <app-widget-container
          [applicationId]="applicationSwitcherService.selectedApplication.id"
          [hash]="advancedWidget.value?.model?.hash"
          [label]="widgetHelper.getWidgetLabel(advancedWidget, m.allWidgetOptions)"
          [widget]="advancedWidget"
          [page]="m.page"
          appWidgetContainerStopPropagation
        ></app-widget-container>
      </ng-container>

      <app-widget-list
        (fromTemplateClick)="showWidgetTemplates()"
        (widgetSelection)="setWidgetToBlockPart($event)"
        *ngIf="blockPartForWidgetPlacement"
        [connectionIds]="dragDropService.allBlockAndPartIds$ | async"
        [isFromTemplateButtonVisible]="true"
        [widgetOptions]="m.widgetOptions"
      >
      </app-widget-list>

      <ng-container *ngIf="stylizationEvent">
        <app-page-settings *ngIf="stylizationEvent.pageElement | isElementOfType : elementType.page"
                           [page]="this.m.page"></app-page-settings>
        <app-page-section-settings
          *ngIf="stylizationEvent.pageElement | isElementOfType : elementType.section"
          [domElements]="stylizationEvent.domElements"
          [section]="$any(stylizationEvent.pageElement)"
          [applicationId]="applicationId"
        ></app-page-section-settings>
        <app-page-row-settings
          *ngIf="stylizationEvent.pageElement | isElementOfType : elementType.row"
          [row]="$any(stylizationEvent.pageElement)"
          [applicationId]="applicationId"
        ></app-page-row-settings>
        <app-page-block-part-settings
          *ngIf="stylizationEvent.pageElement | isElementOfType : elementType.blockPart"
          [part]="$any(stylizationEvent.pageElement)"
          [applicationId]="applicationId"
        ></app-page-block-part-settings>
      </ng-container>
    </div>
  </p-sidebar>
  <br />
</div>

<app-section-generation-form (generateSection)="redesignSection($event)"></app-section-generation-form>

<app-template-meta-form (saveTemplate)="createTemplate($event)"></app-template-meta-form>

<app-export-modal
  headerTitle="Export page part"
  [(visible)]="m.exportModalOpened"
  [selectedParts]="m.selectedPageParts"
  [exportPartsMethod]="s.getExportMethod()"
></app-export-modal>

<p-overlayPanel #htmlIdOp [style]="{ 'padding-top': '1rem' }" appendTo="body">
  <ng-template pTemplate>
    <div *ngIf="setHtmlIdEvent" class="dynamic-label p-float-label">
      <p-floatLabel>
        <input #htmlIdInput="ngModel" [(ngModel)]="setHtmlIdEvent.pageElementWithHtmlId.htmlId" [pattern]="htmlIdRegex"
               pInputText type="text" id="setHtmlId" />
        <label for="setHtmlId" translate>Html id</label>
      </p-floatLabel>
      <div *ngIf="htmlIdInput.errors?.pattern" style="margin-top: 1rem">
        <p style="color: red">{{ 'Must start with lowercase letter, underscore or dollar sign' | translate }}.</p>
        <p style="color: red">{{ 'Can only contain letters, numbers, underscore and dollar sign' | translate }}.</p>
        <p>{{ 'Examples' | translate }}: <strong>"saleSection1"</strong>, <strong>"_saleSection"</strong>, <strong>"$saleSection2"</strong>
        </p>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog
  [(visible)]="workflowsDialogVisible"
  [modal]="true"
  [draggable]="false"
  [blockScroll]="true"
  [closeOnEscape]="false"
  header="{{ 'WORKFLOWS.ADMINISTRATION.PAGE_DIALOG_HEADER' | translate }}"
  styleClass="cd-no-padding cd-with-radius-5 cd-97-vw cd-95-vh"
>
  <div class="page-workflows full-height-container">
    <app-workflows-page-admin [pageWidgets]="pageWidgets" [pageId]="m.page.id"
                              [visible]="workflowsDialogVisible"></app-workflows-page-admin>
  </div>
</p-dialog>
