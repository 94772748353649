import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { cloneDeep } from 'lodash';
import { FilterMatchMode, FilterMetadata } from 'primeng/api';

export class WidgetTableUtil {
  static convertStateToServer(state: Record<string, any> | null): Record<string, any> {
    if (!state) return {};

    const filters = (state['filters'] as Record<string, FilterMetadata[]>) || {};
    state['filters'] = WidgetTableUtil.getNonEmptyFilters(filters);

    return state;
  }

  static convertStateFromDto(dtoState: Record<string, any>, options: WidgetTableUtilCheckAndUpdateBooleanFilterOptions): Record<string, any> {
    if (!dtoState) return {};

    const state = cloneDeep(dtoState);

    const filters = (dtoState['filters'] as Record<string, FilterMetadata[]>) || {};
    const nonEmptyFilters = WidgetTableUtil.getNonEmptyFilters(filters);
    state['filters'] = WidgetTableUtil.checkAndUpdateBooleanFilters(nonEmptyFilters, options);

    return state;
  }

  private static checkAndUpdateBooleanFilters(
    filters: Record<string, FilterMetadata[]>,
    options: WidgetTableUtilCheckAndUpdateBooleanFilterOptions,
  ): Record<string, FilterMetadata[]> {
    Object.keys(filters)
      .filter(key => {
        const dataTypeId = options.attributes.listMap[key]?.dataTypeId;
        const isBoolean = (dataTypeId && options.dataTypes.listMap[dataTypeId].baseDataType === BaseDataType.boolean) || false;
        return isBoolean && filters[key].filter(filterMeta => filterMeta.matchMode === FilterMatchMode.EQUALS).length;
      })
      .forEach(key => {
        filters[key] = filters[key].map(
          filterMeta =>
            ({
              value: Array.isArray(filterMeta.value) ? filterMeta.value : [filterMeta.value],
              matchMode: FilterMatchMode.IN,
              operator: filterMeta.operator,
            }) as FilterMetadata,
        );
      });

    return filters;
  }

  private static getNonEmptyFilters(filters: Record<string, FilterMetadata[]>): Record<string, FilterMetadata[]> {
    return Object.fromEntries(Object.entries(filters).filter(([key, value]) => key && value.filter(filterItem => filterItem.value !== null).length));
  }
}

export interface WidgetTableUtilCheckAndUpdateBooleanFilterOptions {
  attributes: ListContainer<NewAttribute>;
  dataTypes: ListContainer<NewDataType>;
}
