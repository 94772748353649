import { SharedMethods } from '../methods/shared.methods';

export class CustomScriptModel {
  id: string = SharedMethods.getUniqueId();
  type: ScriptType = ScriptType.external;
  name: string = '';
  url?: string;
  body?: string;
  defer?: boolean;

  constructor(model?: Partial<CustomScriptModel>) {
    model && Object.assign(this, model);
  }
}

export enum ScriptType {
  external,
  custom,
}
