<div
  *ngIf="clientAttribute | getFilesForArtifact : artifactId : files : unSavedFiles : attributesMap as filesObject"
  [ngSwitch]="selectedVariant"
>

  <ng-container *ngSwitchCase="'DEFAULT'">
    <ng-container *ngTemplateOutlet="pAttachmentsTemplate; context: { params: { useShowMore: false, usePagination: false } }"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'SUMMARY_ICON'">
    <div *ngIf="isEditMode || (filesObject?.files?.length > 0)" class="icon-variant-container"
         (click)="attachmentsOverlayPanel.toggle($event)">
      <i class="bi bi-paperclip"></i>
      <span>{{ filesObject?.files?.length }}</span>
    </div>
    <p-overlayPanel
      #attachmentsOverlayPanel
      [style]="{  'max-width': '380px', 'min-width': '200px' }"
      appendTo="body"
    >
      <ng-container *ngTemplateOutlet="pAttachmentsTemplate; context: { params: { useShowMore: true, usePagination: false } }"></ng-container>
    </p-overlayPanel>
  </ng-container>

  <ng-container *ngSwitchCase="'PART_WITH_LOAD_MORE'">
    <ng-container *ngTemplateOutlet="pAttachmentsTemplate; context: { params: { useShowMore: true, usePagination: false } }"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'PART_WITH_PAGINATION'">
    <ng-container *ngTemplateOutlet="pAttachmentsTemplate; context: { params: { useShowMore: false, usePagination: true } }"></ng-container>
  </ng-container>

  <div class="text-center" *ngSwitchCase="'PICTURE'">
    <i class="pi pi-cog" style="font-size: 2rem; color: #f39c12;"></i>
  </div>

  <div class="text-center" *ngSwitchCase="'PICTURE_WITH_NAME'">
    <i class="pi pi-cog" style="font-size: 2rem; color: #f39c12;"></i>
  </div>

  <div class="text-center" *ngSwitchCase="'PICTURE_WITH_LINK'">
    <i class="pi pi-cog" style="font-size: 2rem; color: #f39c12;"></i>
  </div>

  <ng-template #pAttachmentsTemplate let-params="params">

    <div class="dynamic-label">
      <span [ngClass]="{ 'upload-hidden': !isEditMode || files[artifactId] }" class="dynamic-file-upload">
        <p-fileUpload
          #fileUpload
          (uploadHandler)="uploadHandler($event)"
          [auto]="true"
          [customUpload]="true"
          [multiple]="attributesMap[clientAttribute?.id]?.multipleValues"
          class="file-upload-wrapper"
          mode="basic"
          name="files[]"
        >
        </p-fileUpload>
      </span>

      <div class="table-cell-content-file">
        <div *ngIf="!attributesMap[clientAttribute?.id]?.multipleValues">
          <div [id]="'file-component' + index" class="file-cell">
              <span
                *ngIf="filesObject.files"
                appFileArtifactViewer
                [file]="files[clientAttribute?.value]"
                [ngClass]="{ 'p-text-truncate': truncate }"
                class="downloadable"
              >
                {{ filesObject.files?.formatData.filename }} [{{ filesObject.files.formatData.size | getFileSize }}]
              </span>
            <span *ngIf="filesObject.unSavedFiles"> {{ filesObject.unSavedFiles[0]?.name }}
              [{{ filesObject.unSavedFiles[0]?.size | getFileSize }}]
              <app-upload-progress *ngIf="isInSavingProcess && filesObject.unSavedFiles[0]"
                                   [fileName]="filesObject.unSavedFiles[0]?.name"></app-upload-progress>
            </span>

            <div *ngIf="isEditMode && (filesObject.files || filesObject.unSavedFiles)" class="button-box">
              <button (click)="deleteFile()" class="p-button-danger p-button-text utility-button" icon="pi pi-times"
                      pButton type="button"></button>
            </div>

            <ng-container *ngIf="files[artifactId]">
                <span
                  appFileArtifactViewer
                  [file]="files[artifactId]"
                  [ngClass]="{ 'p-text-truncate': truncate }"
                  class="downloadable"
                >
                  {{ files[artifactId].formatData.filename }} [{{ files[artifactId].formatData.size | getFileSize }}]
                </span>

              &lt;!&ndash; <span *ngIf="filesObject.unSavedFiles"> {{ filesObject.unSavedFiles[0]?.name }}
              [{{ filesObject.unSavedFiles[0]?.size | getFileSize }}] </span>&ndash;&gt;

              &lt;!&ndash;
              <div *ngIf="isEditMode" class="button-box">&ndash;&gt;
                &lt;!&ndash;
                <button (click)="deleteFile()" &ndash;&gt;
                        &lt;!&ndash; class="p-button-danger p-button-text utility-button" &ndash;&gt;
                        &lt;!&ndash; icon="pi pi-times" &ndash;&gt;
                        &lt;!&ndash; pButton type="button">&ndash;&gt;
                  &lt;!&ndash;
                </button>&ndash;&gt;
                &lt;!&ndash;
              </div>&ndash;&gt;
            </ng-container>
          </div>
        </div>

        <ul *ngIf="attributesMap[clientAttribute?.id]?.multipleValues" [id]="'file-component' + index"
            class="file-cell">
          <ng-container
            *ngIf="clientAttribute | getFilesForArtifact : artifactId : files : unSavedFiles : attributesMap as filesObject">
            <li *ngFor="let fileId of getVisibleFiles(clientAttribute.value); let i = index" class="file">
              <div class="file-item">
                  <span appFileArtifactViewer [file]="files[fileId]" [ngClass]="{ 'p-text-truncate': truncate }"
                        class="file-label">
                    {{ files[fileId]?.formatData.filename }} [{{ files[fileId]?.formatData.size | getFileSize }}]
                  </span>

                <div *ngIf="isEditMode" class="button-box">
                  <button (click)="deleteFile(fileId)" class="p-button-danger p-button-text utility-button"
                          icon="pi pi-times" pButton type="button"></button>
                </div>
              </div>
            </li>

            <div *ngIf="params.useShowMore && (clientAttribute.value.length > visibleFilesCount)" class="button-show-more"
                 (click)="showAllFiles = !showAllFiles">
              <i class="pi pi-arrow-down"></i>
              {{ (showAllFiles ? 'Hide more' : 'Show more') | translate }}
            </div>

            <div *ngIf="params.usePagination">
              <p-paginator
                [rows]="visibleFilesCount"
                [totalRecords]="clientAttribute.value.length"
                [showJumpToPageDropdown]="true"
                dropdownAppendTo="body"
                styleClass="paginator-transparent paginator-nowrap paginator-with-narrow-dropdown"
                [showPageLinks]="false"
                [alwaysShow]="false"
                (onPageChange)="paginate($event)"
              ></p-paginator>
            </div>

            <li *ngFor="let unsavedFile of filesObject.unSavedFiles">
              <div class="file-cell">
                {{ unsavedFile.name }} [{{ unsavedFile.size | getFileSize }}]
                <div *ngIf="isEditMode" class="button-box">
                  <button
                    (click)="deleteFile(null, unsavedFile)"
                    class="p-button-danger p-button-text utility-button"
                    icon="pi pi-times"
                    pButton
                    type="button"
                  ></button>
                </div>
              </div>
              <app-upload-progress *ngIf="isInSavingProcess && unsavedFile"
                                   [fileName]="unsavedFile.name"></app-upload-progress>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-template>
</div>
