import { Directive, Input, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { AttributeActionHandlerService } from '../services';
import { AttributeEditableSettable } from '../types/attribute-editable-settable';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';

@Directive({
  selector: '[appSetAttributeEditable]',
})
export class SetAttributeEditableDirective extends AbstractRuleAttributeDirective implements OnInit {
  @Input() attributeOwner: AttributeEditableSettable;

  constructor(protected actionAttributeHandler: AttributeActionHandlerService) {
    super();
  }

  ngOnInit() {
    this.actionAttributeHandler.registerSetAttributeEditableEventListeners(this.key);
    this.initSubscription = this.actionAttributeHandler.setAttributeEditableEventMap$
      .pipe(
        filter(() => this.actionAttributeHandler.containsSetAttributeEditableEvent(this.key)),
        map(() => this.actionAttributeHandler.getSetAttributeEditableEvent(this.key)),
      )
      .subscribe(setEditableEvent => {
        this.setAttributeEditable(setEditableEvent.editable);
        this.actionAttributeHandler.removeSetAttributeEditableEvent(this.key, false);
      });
  }

  ngOnDestroy() {
    this.actionAttributeHandler.unregisterSetAttributeEditableEventListeners(this.key);
    super.ngOnDestroy();
  }

  private setAttributeEditable(editable: boolean): void {
    this.attributeOwner.setAttributeEditable(editable, true);
  }
}
