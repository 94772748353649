import { SharedMethods } from '@shared/methods/shared.methods';
import { CustomScriptModel, ScriptType } from '../types/custom-script.types';

export class ScriptsUtil {
  static processAndAddScripts(scriptModels: CustomScriptModel[]): void {
    scriptModels.forEach(scriptModel => {
      const script: HTMLScriptElement = document.createElement('script');
      script.id = scriptModel.id;

      if (scriptModel.defer) script.defer = true;
      if (scriptModel.type === ScriptType.external) script.src = scriptModel.url as string;
      if (scriptModel.type === ScriptType.custom) script.textContent = scriptModel.body as string;

      document.body.appendChild(script);
    });
  }

  static removeScripts(scriptModels: CustomScriptModel[]): void {
    scriptModels.forEach(scriptModel => {
      const script = document.getElementById(scriptModel.id);

      if (script) script.remove();
      else console.warn(`No script with ID "${scriptModel.id}" and NAME "${scriptModel.name}" found.`);
    });
  }

  static parseScriptModelsFromHtmlString(htmlString: string): CustomScriptModel[] {
    const container: HTMLDivElement = document.createElement('div');
    container.innerHTML = htmlString;

    return Array.from(container.childNodes)
      .map(child => {
        if (child.nodeType !== Node.ELEMENT_NODE) return;
        return ScriptsUtil.parseElement(child as HTMLElement);
      })
      .filter(Boolean) as CustomScriptModel[];
  }

  private static parseElement(element: HTMLElement): CustomScriptModel {
    const tagName = element.tagName.toLowerCase();

    if (tagName === 'script') {
      if (!element.getAttribute('src'))
        return new CustomScriptModel({
          id: SharedMethods.getUniqueId(),
          name: 'Custom Script',
          type: ScriptType.custom,
          body: element.textContent || '',
        });

      return new CustomScriptModel({
        id: SharedMethods.getUniqueId(),
        name: 'External Script',
        type: ScriptType.external,
        url: element.getAttribute('src') || '',
      });
    }

    const variableName = `${tagName}`;
    let body = `const ${variableName} = document.createElement('${tagName}');\n`;

    Array.from(element.attributes).forEach(attr => {
      if (attr.name === 'class') body += `${variableName}.className = '${attr.value}';\n`;
      else body += `${variableName}.setAttribute('${attr.name}', '${attr.value?.replace(/'/g, "\\'")}');\n`;
    });

    if (element.textContent) body += `${variableName}.textContent = '${element.textContent}';\n`;
    if (element.style.cssText) body += `${variableName}.style.cssText = '${element.style.cssText}';\n`;

    body += `document.body.appendChild(${variableName});`;

    return new CustomScriptModel({ id: SharedMethods.getUniqueId(), name: 'Custom Script', type: ScriptType.custom, body });
  }
}
