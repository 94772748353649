import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { HTTP_HTTPS_VALIDATION_REGEX, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { DefaultElvisActions } from '@shared/types/actions.types';
import { ListContainer } from '@shared/types/list-container.types';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';
import { LinkQueryParamActionEnum, MenuItemCurrentPageBehaviorEnum } from '@widgets/menu-widget/types/tree-types';
import { ActionListService } from '@widgets/shared/components/action-list/services/action-list-service';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { TreeNode } from 'primeng/api/treenode';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrl: './action-list.component.scss',
})
export class ActionListComponent {
  @Input() showListWidgetGroup: boolean;
  @Input() showMenuWidgetGroup: boolean;

  @Input() filtersDisabled: boolean;
  @Input() filtersHolder: { [filtersKey: string]: ArtifactFilter[] };
  @Input() filtersKey: string;
  @Input() options: ClickActionSettingsOptions;

  @Input() isHideMultiselect: boolean;
  @Input() multiselect: boolean;
  @Input() isAddToLinkDisabled: boolean;
  @Input() showFolderEmittingOption: boolean;
  @Input() shouldEmitFolderUrlKey: boolean;
  @Input() emittingFolderUrlKey: string;

  @Input() actions: ActionItem[];
  @Input() showEmptyParamsAsActive: boolean;
  @Output() actionChange = new EventEmitter<ActionItem[]>();
  @Output() pageSelect = new EventEmitter<ActionItem>();
  @Output() isMultiselectChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emittingFolderUrlKeyChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() shouldEmitFolderUrlKeyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  defaultElvisActions = DefaultElvisActions;
  currentPageBehavior = MenuItemCurrentPageBehaviorEnum;
  queryParamActions = LinkQueryParamActionEnum;
  http_https_validation_regex = HTTP_HTTPS_VALIDATION_REGEX;
  linkQueryParamAction = GetSelectOptionsFromEnum(LinkQueryParamActionEnum);

  selectedAction: ActionItem;
  elvisActions: TreeNode[];
  pages = new ListContainer<Page>();
  handler: Subscription;

  ID_VALUE = ID_KEY;
  NAME_VALUE = NAME_KEY;

  constructor(private readonly s: ActionListService) {}

  ngOnInit(): void {
    this.s.init(this);
  }

  ngOnDestroy(): void {
    this.handler && this.handler.unsubscribe();
  }

  onAddQueryParamClick(): void {
    if (this.selectedAction) this.selectedAction.queryParams.push({ key: '', value: '', action: LinkQueryParamActionEnum.addNew });
  }

  onDeleteParamClick(index: number): void {
    if (this.selectedAction) this.selectedAction.queryParams.splice(index, 1);
  }

  add(): void {
    this.actions && this.actions.push(new ActionItem());
    this.onActionChange();
  }

  remove(index: number): void {
    this.actions && this.actions.splice(index, 1);
    this.onActionChange();
  }

  dropItem(event: CdkDragDrop<any[]>): void {
    this.actions && moveItemInArray(this.actions, event.previousIndex, event.currentIndex);
  }

  toggleEdit(action: ActionItem, index: number): void {
    action.isEdit = !action.isEdit;
    if (action.isEdit && this.actions) this.actions.forEach((action, i) => i !== index && (action.isEdit = false));
  }

  onNodeSelect({ node }: { node: TreeNode }, i: number): void {
    const action = this.actions[i];
    if (action?.type === ArtifactListItemClickAction.selectItem) {
      this.multiselect = false;
      this.isMultiselectChange.emit(false);
    }

    if (action) {
      action.type = node.data;
    }

    this.onActionChange();
  }

  onActionChange(): void {
    this.actionChange.emit(this.actions);
  }

  onPageSelect(event: ActionItem): void {
    this.pageSelect.emit(event);
  }
}
