import { inject, Injectable } from '@angular/core';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { SharedMethods } from '@shared/methods/shared.methods';
import { TableMethods } from '@shared/methods/table.methods';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { TableColumn } from '@shared/types/table.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageSectionStyles } from '@private/pages/page-management/page-builder-graphical/types/page-section-styles';
import { PageBlockPartStyles } from '@private/pages/page-management/page-builder-graphical/types/page-block-part-styles';
import { Styles } from '@private/pages/page-management/page-builder-graphical/types/styles';
import { PageElementTypes } from '@private/pages/page-management/page-builder-graphical/types/page-element-types';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { SidebarModalWidgetModel } from '@widgets/sidebar-modal-widget/types/sidebar-modal.types';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { StateKey } from '@shared/types/local-storage.types';
import {
  CardWidgetAreaContentItem,
  IsCardContentItemWidget
} from '@widgets/card-widget/types/card-widget-area-content-item';

type PageElementName = typeof PageSection.name | typeof PageRow.name | typeof PageBlockPart.name;

@Injectable({ providedIn: 'root' })
export class PageBuilderGraphicalCopyPasterHelperService {
  readonly copyMethods: Record<PageElementName, (...args: any[]) => any> = {
    [PageElementTypes.section]: (elementToCopy: PageSection) => this.copySection(elementToCopy),
    [PageElementTypes.row]: (elementToCopy: PageRow) => this.copyRow(elementToCopy),
    [PageElementTypes.blockPart]: (elementToCopy: PageBlockPart) => this.copyBlockPart(elementToCopy),
  };

  readonly pasteMethods: Record<PageElementName, (...args: any[]) => any> = {
    [PageElementTypes.section]: (elementToPasteInto: PageSection) => this.pasteIntoPageSection(elementToPasteInto),
    [PageElementTypes.row]: (elementToPasteInto: PageRow) => this.pasteIntoPageRow(elementToPasteInto),
    [PageElementTypes.blockPart]: (elementToPasteInto: PageBlockPart) => this.pasteIntoPageBlockPart(elementToPasteInto),
  };

  private copiedSectionSubject: BehaviorSubject<PageSection | null> = new BehaviorSubject<PageSection | null>(null);
  private copiedRowSubject: BehaviorSubject<PageRow | null> = new BehaviorSubject<PageRow | null>(null);
  private copiedBlockPartSubject: BehaviorSubject<PageBlockPart | null> = new BehaviorSubject<PageBlockPart | null>(null);

  private copiedSection$: Observable<PageSection | null> = this.copiedSectionSubject.asObservable();
  private copiedRow$: Observable<PageRow | null> = this.copiedRowSubject.asObservable();
  private copiedBlockPart$: Observable<PageBlockPart | null> = this.copiedBlockPartSubject.asObservable();

  private readonly localStorageService = inject(LocalStorageService);

  constructor() {
    this.updateFromLocalStorage();

    window.addEventListener('storage', (event) => {
      if (event.key === StateKey.copy) {
        this.updateFromLocalStorage();
      }
    });
  }

  get hasSectionCopy$(): Observable<boolean> {
    return this.copiedSection$.pipe(map(Boolean));
  }

  get hasRowCopy$(): Observable<boolean> {
    return this.copiedRow$.pipe(map(Boolean));
  }

  get hasBlockPartCopy$(): Observable<boolean> {
    return this.copiedBlockPart$.pipe(map(Boolean));
  }

  private updateFromLocalStorage(): void {
    const data = this.localStorageService.get(StateKey.copy) || {};
    const time = Date.now();
    const expiredTime = 5 * 60 * 1000;

    if (data.updatedOn + expiredTime < time ) {
      this.localStorageService.set(StateKey.copy, {});
      return;
    }

    data.copiedSection && (this.copiedSection = this.copySectionFromLocal(data.copiedSection));
    data.copiedRow && (this.copiedRow = this.copyRowFromLocal(data.copiedRow));
    data.copiedBlockPart && (this.copiedBlockPart = this.copyBlockPartFromLocal(data.copiedBlockPart));
  }

  private copySectionFromLocal(section: PageSection): PageSection {
    return new PageSection(section.rows.map(row => this.copyRowFromLocal(row)), null, new PageSectionStyles(section.styles), null, section.sectionHide);
  }

  private copyRowFromLocal(row: PageRow): PageRow {
    return new PageRow(row.blocks.map(block => this.copyBlockFromLocal(block)), row.layout, null, new Styles(row.styles), row.rowHide);
  }

  private copyBlockFromLocal(block: PageBlock): PageBlock {
    return new PageBlock(block.parts.map(part => this.copyBlockPartFromLocal(part)), block.templateId);
  }

  private copyBlockPartFromLocal(part: PageBlockPart): PageBlockPart {
    // @ts-ignore
    if (!part.widget) return part;
    // @ts-ignore
    part.widget! = new BlockPartWidget(part.widget!);
    part.widget.id = null

    if (part.widget.code === WidgetType.sidebarModal) {
      (part.widget.value.model as SidebarModalWidgetModel).page.sections = part.widget.value.model.page.sections.map((s: PageSection) => this.copySectionFromLocal(s));
    }

    if (part.widget.code === WidgetType.card) {
      (part.widget.value.model as CardWidgetModel).areas = (part.widget.value.model as CardWidgetModel).areas.map(area => {
        area.content.items = area.content.items.map((item: CardWidgetAreaContentItem) => {
          // @ts-ignore
          IsCardContentItemWidget(item) && (item.content = new BlockPartWidget(item.content));
          return item;
        });
        return area;
      })
    }
    return new PageBlockPart(part.widget, part.styles);
  }

  private get copiedSection(): PageSection | null {
    return this.copiedSectionSubject.value;
  }

  private set copiedSection(section: PageSection | null) {
    this.copiedSectionSubject.next(section);
  }

  private get copiedRow(): PageRow | null {
    return this.copiedRowSubject.value;
  }

  private set copiedRow(row: PageRow | null) {
    this.copiedRowSubject.next(row);
  }

  private get copiedBlockPart(): PageBlockPart | null {
    return this.copiedBlockPartSubject.value;
  }

  private set copiedBlockPart(blockPart: PageBlockPart | null) {
    this.copiedBlockPartSubject.next(blockPart);
  }

  private copySection(section: PageSection): void {
    this.copiedSection = section.copyDto as any;

    this.copiedSection?.rows?.forEach((row, rIndex) => {
      row.blocks.forEach((block, bIndex) => {
        block.parts.forEach((part, pIndex) => {
          if (part?.widget) {
            part.widget = section.rows[rIndex].blocks[bIndex].parts[pIndex]?.widget?.requestDtoInner as any;
            part.widget && (part.widget.id = null);
          }
        });
      });
    });

    // @ts-ignore
    const rows = this.copiedSection!.rows?.map(PageRow.fromDto);
    this.copiedSection = new PageSection(rows, null, new PageSectionStyles(this.copiedSection!.styles), null, this.copiedSection!.sectionHide);

    const data = this.localStorageService.get(StateKey.copy) || {};
    data.copiedSection = this.copiedSection;
    data.updatedOn = Date.now();
    this.localStorageService.set(StateKey.copy, data);
  }

  private copyRow(row: PageRow): void {
    this.copiedRow = row.requestDto as any;

    this.copiedRow?.blocks.map((block, bIndex) => {
      block.parts.forEach((part, pIndex) => {
        if (part?.widget) {
          part.widget = row.blocks[bIndex]?.parts[pIndex]?.widget?.requestDtoInner as any;
          part.widget && (part.widget.id = null);
        }
      });
    });

    // @ts-ignore
    const blocks = this.copiedRow!.blocks?.map(PageBlock.fromDto);
    this.copiedRow = new PageRow(blocks, row.layout, null, new Styles(this.copiedRow!.styles), row.rowHide);

    const data = this.localStorageService.get(StateKey.copy) || {};
    data.copiedRow = this.copiedRow;
    data.updatedOn = Date.now();
    this.localStorageService.set(StateKey.copy, data);
  }

  private copyBlockPart(blockPart: PageBlockPart): void {
    if (blockPart?.widget) {
      const copy = blockPart.widget?.requestDtoInner as any;
      copy && (copy.id = null);

      // @ts-ignore
      this.copiedBlockPart = PageBlockPart.fromDto(new PageBlockPart(copy, new PageBlockPartStyles(blockPart!.styles), null));
      this.assignStateToListWidget(copy, blockPart);
    }

    const data = this.localStorageService.get(StateKey.copy) || {};
    data.copiedBlockPart = this.copiedBlockPart;
    data.updatedOn = Date.now();
    this.localStorageService.set(StateKey.copy, data);
  }

  private assignStateToListWidget(copy: PageBlockPart, part: PageBlockPart): void {
    const innerWidget = part.widget;
    const state = this.localStorageService.get(part.hash);

    if (state && innerWidget) {
      if (!state.tableFormatSettings && innerWidget.value.model.state?.tableFormatSettings) {
        state.tableFormatSettings = innerWidget.value.model.state?.tableFormatSettings;
      }
      delete state.selection;
      state.columnOrder = innerWidget.value.model.selected.columns.map((col: TableColumn) => col.key);

      innerWidget.value.model.state = state;
      state.filters && TableMethods.formatTableFilterNamesToServer(innerWidget.value.model.state.filters);
    }

    copy.widget?.value?.model && (copy.widget!.value.model.state = state);
  }

  private setBlockPartToServerValue(blockPart: PageBlockPart): void {
    if (!blockPart.widget?.value?.model) {
      return;
    }

    if (blockPart.widget.value.model.copy) {
      const { settings, ...model } = blockPart.widget.value.model.copy();
      const { sidebarModal, sidebar, listMatrix, card } = WidgetType;
      [sidebarModal, sidebar, listMatrix, card].includes(blockPart.widget.code) && (model.settings = settings);
      blockPart.widget.value.model = model;
    } else {
      blockPart.widget.value.model = blockPart.widget.value.model.toServer();
    }
  }

  private async pasteIntoPageSection(sectionToPasteInto: PageSection): Promise<void> {
    if (!this.copiedSection) {
      return;
    }

    const section = this.copiedSection;
    section.modalId = sectionToPasteInto.modalId;

    Object.assign(sectionToPasteInto, section);
    Object.assign(sectionToPasteInto.rows, this.cloneRowArray(section.rows));
  }

  private cloneRowArray(rowArrayToClone: PageRow[]): PageRow[] {
    const clonedRows = new Array<PageRow>();
    rowArrayToClone.forEach(row => {
      const newRow = new PageRow();
      this.pasteIntoPageRow(newRow, row);
      clonedRows.push(newRow);
    });
    return clonedRows;
  }

  private pasteIntoPageRow(rowToPasteInto: PageRow, copiedRow?: PageRow): void {
    if (!copiedRow && !this.copiedRow) {
      return;
    }

    copiedRow ??= cloneDeep(this.copiedRow!);

    Object.assign(rowToPasteInto, copiedRow);
    Object.assign(rowToPasteInto.blocks, this.cloneBlockArray(copiedRow.blocks));
  }

  private cloneBlockArray(blockArrayToClone: PageBlock[]): PageBlock[] {
    const clonedBlocks = new Array<PageBlock>();
    blockArrayToClone.forEach(block => {
      const clonedBlockParts = new Array<PageBlockPart>();
      block.parts.forEach(blockPart => {
        const clonedBlockPart = new PageBlockPart();
        this.pasteIntoPageBlockPart(clonedBlockPart, blockPart);
        clonedBlockParts.push(clonedBlockPart);
      });
      clonedBlocks.push(new PageBlock(clonedBlockParts));
    });
    return clonedBlocks;
  }

  private pasteIntoPageBlockPart(blockPartToPasteInto: PageBlockPart, copiedBlockPart?: PageBlockPart): void {
    if (!copiedBlockPart && !this.copiedBlockPart) {
      return;
    }

    blockPartToPasteInto.widget = null;
    copiedBlockPart ??= cloneDeep(this.copiedBlockPart!);

    setTimeout(() => {
      Object.assign(blockPartToPasteInto, copiedBlockPart);
      blockPartToPasteInto.hash = SharedMethods.getUniqueId();
    });
  }
}
