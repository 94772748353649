import { LinkFilterNew } from '@api/models/link-filter-new';
import { LinkPopupSelectedDto } from '@api/models/link-popup-selected-dto';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { AdvancedDateFilterObject } from '@shared/components/date-filter/types/date-filter.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { LinkFilterEnum, UserFilterEntry } from '@shared/types/filter.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewTableColumn } from '@shared/types/table.types';
import { ListWidgetSelected } from '@widgets/shared/components/artifact-list-table/types/list-widget-selected.types';
import { Observable } from 'rxjs';
import { LinkPopupOptions } from './link-popup-options.types';

export class LinkPopupSelected extends ListWidgetSelected {
  columnsDto: { key: string }[] | null = null;

  columns: NewTableColumn[] = [];
  linkTypes: SelectOption<string, LinkType, LinkDirection>[] = [];
  artifact: NewArtifact | null = null;
  artifacts: NewArtifact[] = [];
  artifactType: NewArtifactType;
  artifactTypes: SelectOption<string, NewArtifactType>[] = [];
  linkFilters: Record<LinkFilterEnum | string, LinkFilterNew> = {};
  dateFilters: Record<string, AdvancedDateFilterObject> = {};
  userFilters: Record<string, UserFilterEntry> = {};

  // private columnsSubject: ReplaySubject<NewTableColumn[]> = new ReplaySubject(1);

  constructor(selected: Partial<LinkPopupSelected> | null = null) {
    super();
    selected && Object.assign(this, selected);
  }

  get columns$(): Observable<NewTableColumn[]> {
    return this.columnsSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  toServer(): LinkPopupSelectedDto {
    return {
      columns: this.columns.map(col => ({ key: col.key })),
      linkTypes: this.linkTypes.map(lt => ({ id: lt.value.id, meta: lt.meta })),
      linkFilters: this.linkFilters,
      dateFilters: this.dateFilters as any,
      userFilters: this.userFilters,
    };
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setFromDto(selected: LinkPopupSelectedDto, options: LinkPopupOptions): void {
    Promise.resolve().then(() => {
      const { columns, linkTypes } = selected;

      this.linkTypes = linkTypes.map(dto => {
        const linkTypeId = dto instanceof SelectOption ? dto.value.id : dto.id;
        const resultOption = options.linkTypesOptions.find((linkOption: any) => linkOption.value.id === linkTypeId && linkOption.meta === dto.meta);
        return resultOption as SelectOption<string, LinkType, LinkDirection>;
      });

      if (options.columns.list?.length) this.setColumnsFromDto(columns as { key: string }[], options.columns);
      else this.columnsDto = columns as { key: string }[];

      this.linkFilters = selected.linkFilters || {};
      this.dateFilters = (selected.dateFilters as any) || {};
      this.userFilters = (selected.userFilters as any) || {};
    });
  }

  setColumnsFromDto(dto: { key: string }[], options: ListContainer<NewTableColumn>): void {
    const columns = dto.map(col => options.listMap[col.key]).filter(col => Boolean(col));
    this.setColumns(columns);
  }

  setColumnsFromSavedDto(options: ListContainer<NewTableColumn>): void {
    if (this.columnsDto) {
      this.setColumnsFromDto(this.columnsDto, options);
      this.columnsDto = null;
    }
  }

  setColumns(columns: NewTableColumn[]): void {
    this.columns = columns;
    this.columnsSubject.next(columns);
  }
}
