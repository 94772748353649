<div cdkDropList (cdkDropListDropped)="dropItem($event)">
  <div *ngFor="let action of actions; let i = index" class="grid mt-2 action-wrapper" cdkDrag>
    <div class="col-1 p-1">
      <i class="bi bi-grip-vertical" cdkDragHandle pTooltip="{{'Move' | translate}}" tooltipPosition="top" pRipple></i>
    </div>
    <div class="col-9 p-1">
      {{ action.type | translate }}
    </div>
    <div class="col-1 p-1 pt-2 center cursor-pointer">
      <i class="pi" [ngClass]="action.isEdit ? 'pi-check': 'pi-pencil'" (click)="toggleEdit(action, i)"></i>
    </div>
    <div class="col-1 p-1 right">
      <a *ngIf="actions.length > 1" (click)="remove(i)"
         class="p-1 pi pi-trash p-button p-button-rounded p-button-text p-button-danger" pRipple> </a>
    </div>

    <ng-container *ngIf="action.isEdit">
      <div class="col-12">
        <p-treeSelect
          [(ngModel)]="action.selectedType"
          [options]="elvisActions"
          (onNodeSelect)="onNodeSelect($event, i)"
          [placeholder]="'Select action' | translate"
          [filter]="true"
          [filterInputAutoFocus]="true"
          class="w-full"
          containerStyleClass="w-full"
        >
          <ng-template let-expanded pTemplate="itemtogglericon">
            <i [class]="expanded ? 'pi pi-minus-circle' : 'pi pi-plus-circle'"></i>
          </ng-template>
        </p-treeSelect>
      </div>

            <div class="col-12" *ngIf="action.type | isListWidgetSpecial">
                <app-list-widget-actions
                        [action]="action"
                        [filtersDisabled]="filtersDisabled"
                        [filtersHolder]="filtersHolder"
                        [filtersKey]="filtersKey"
                        [options]="options"
                        [isAddToLinkDisabled]="isAddToLinkDisabled"
                        [showFolderEmittingOption]="showFolderEmittingOption"
                        [(shouldEmitFolderUrlKey)]="shouldEmitFolderUrlKey"
                        [(emittingFolderUrlKey)]="emittingFolderUrlKey"
                        [isHideMultiselect]="isHideMultiselect"
                        [multiselect]="multiselect"
                        (isMultiselectChange)="isMultiselectChange.emit($event)"
                        (actionChange)="onActionChange()"
                ></app-list-widget-actions>
            </div>

      <div class="col-12" *ngIf="action.type | isMenuWidgetSpecial">
        <app-menu-widget-actions
          [action]="action"
          (actionChange)="onActionChange()"
        ></app-menu-widget-actions>
      </div>

      <div class="col-12" *ngIf="action.type === defaultElvisActions.customEvent">
        <p-floatLabel>
          <input pInputText id="custom-event" class="full-width-container" [(ngModel)]="action.model.name" />
          <label for="custom-event" translate>Event name</label>
        </p-floatLabel>
      </div>

      <ng-container *ngIf="action.type === currentPageBehavior.leaveCurrentPage">
        <div class="col-4 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox [(ngModel)]="action.isPageSelection" (ngModelChange)="onActionChange()"
                          binary="true" id="typeUrl" class="mr-1"></p-checkbox>
              <label
                for="typeUrl">{{ action.isPageSelection ? ('Page' | translate) : ('Url' | translate) }}</label>
            </span>
          </span>

          <span class="dynamic-label dynamic-checkbox" *ngIf="action.isPageSelection">
              <span class="dynamic-checkbox-box">
                <p-checkbox [(ngModel)]="action.useAlias" (ngModelChange)="onActionChange()" binary="true"
                            id="useAlias" class="mr-1"></p-checkbox>
                <label for="useAlias">{{ 'Use page alias' | translate }}</label>
              </span>
          </span>

          <span class="dynamic-label dynamic-checkbox">
              <span class="dynamic-checkbox-box">
                <p-checkbox [(ngModel)]="action.openInNewTab" (ngModelChange)="onActionChange()" binary="true"
                            id="openInNewTab" class="mr-1"></p-checkbox>
                <label for="openInNewTab">{{ 'Open page in new tab' | translate }}</label>
              </span>
          </span>

          <span class="dynamic-label dynamic-checkbox">
              <span class="dynamic-checkbox-box">
                <p-checkbox [(ngModel)]="action.holdAllUrlParam" (ngModelChange)="onActionChange()" binary="true"
                            id="holdAllUrlParam" class="mr-1"></p-checkbox>
                <label for="holdAllUrlParam">{{ 'Keep all params' | translate }}</label>
              </span>
          </span>
        </div>

        <div class="col-8 my-0">
                  <span *ngIf="!action.isPageSelection" class="dynamic-label">
                    <input
                      #urlInput="ngModel"
                      [(ngModel)]="action.url"
                      (ngModelChange)="onActionChange()"
                      [pattern]="http_https_validation_regex"
                      id="url"
                      pInputText
                      type="text"
                    />
                    <label class="dynamic-label-text" for="url" translate>{{ 'Url' | translate }}</label>
                    <div *ngIf="urlInput.errors?.pattern" class="mt-3">
                      <p class="text-red">{{ 'Please include' | translate }} <span class="text-bold"> http:// </span> or <span
                        class="text-bold"> https:// </span></p>
                    </div>
                  </span>

          <span *ngIf="action.isPageSelection" class="dynamic-label">
                    <p-dropdown
                      [(ngModel)]="action.pageId"
                      (onChange)="onPageSelect(action)"
                      [options]="pages.list | transformArrayToSelectOptions : NAME_VALUE : ID_VALUE"
                      [autoDisplayFirst]="false"
                      [filter]="true"
                      id="page"
                      appendTo="body"
                      [virtualScroll]="false"
                      [virtualScrollItemSize]="10"
                      appNotifyIfSelectedPageDeletedDirective
                      [selectedPageId]="action.pageId"
                    >
                    </p-dropdown>
                    <label class="dynamic-label-text" for="page">{{ 'Select Page' | translate }}</label>
                  </span>
        </div>
      </ng-container>

      <div *ngIf="action.type === currentPageBehavior.stayOnCurrentPage" class="col-12 mb-0">
                <span class="dynamic-label dynamic-checkbox">
                  <span class="dynamic-checkbox-box">
                    <p-checkbox
                      [(ngModel)]="showEmptyParamsAsActive"
                      (ngModelChange)="onActionChange()"
                      binary="true"
                      id="showNoParamsAsActive"
                    >
                    </p-checkbox>
                    <label for="showNoParamsAsActive">{{ 'Show item with no parameters as active' | translate }}</label>
                  </span>
                </span>
      </div>

      <ng-container
        *ngIf="action.type === currentPageBehavior.leaveCurrentPage || action.type === currentPageBehavior.stayOnCurrentPage">
          <div class="col-6 mb-0 text-center">
            <button
              (click)="selectedAction = action; queryParamsOp.toggle($event)"
              label="{{ 'Query parameters' | translate }}"
              class="w-100"
              icon="bi bi-question-lg"
              pButton
              type="button"
            ></button>
          </div>
          <div class="col-6 mb-0 text-center">
            <button
              (click)="selectedAction = action; fragmentParamOp.toggle($event)"
              label="{{ 'Scroll to div parameter' | translate }}"
              class="w-100"
              icon="bi bi-hash"
              pButton
              type="button"
            ></button>
          </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-12 center p-0 pt-2">
    <i class="bi bi-plus-circle-fill cursor-pointer pl-4 pr-4" (click)="add()"></i>
  </div>
  <div class="col-12 center p-0">
    <button class="p-button p-button-text p-0" pButton type="button" (click)="add()">Add action</button>
  </div>
</div>

<p-overlayPanel #queryParamsOp [style]="{ 'min-width': '800px' }" appendTo="body">
  <div *ngIf="selectedAction" class="p-grid query-params-overlay">
    <div *ngFor="let param of selectedAction.queryParams; let i = index" class="row">
      <div class="col-3">
        <p-dropdown [(ngModel)]="param.action" [options]="linkQueryParamAction" [autoDisplayFirst]="false"
                    appendTo="body">
          <ng-template pTemplate="selectedItem" let-selected> {{ selected.label | translate }}</ng-template>
          <ng-template let-option pTemplate="item"> {{ option.label | translate }}</ng-template>
        </p-dropdown>
      </div>
      <div
        [ngClass]="{ 'col-4': param.action === queryParamActions.addNew, 'col-8': param.action === queryParamActions.removeExisting || param.action === queryParamActions.holdExisting }">
        <input type="text" [id]="'queryParamKey' + i" pInputText [(ngModel)]="param.key" placeholder="Enter key"
               class="w-100" />
      </div>
      <div *ngIf="param.action === queryParamActions.addNew" class="col-4">
        <input type="text" [id]="'queryParamVal' + i" pInputText [(ngModel)]="param.value" placeholder="Enter value"
               class="w-100" />
      </div>
      <div class="col-1 text-center">
        <button
          pButton
          class="p-button p-button-rounded p-button-danger p-button-outlined"
          type="button"
          pTooltip="{{ 'Delete' | translate }}"
          icon="pi pi-trash"
          iconPos="left"
          (click)="onDeleteParamClick(i)"
        ></button>
      </div>
    </div>
    <button (click)="onAddQueryParamClick()" label="{{ 'Add' | translate }}" icon="pi pi-plus" pButton
            type="button"></button>
  </div>
</p-overlayPanel>

<p-overlayPanel #fragmentParamOp [style]="{ 'max-width': '380px' }" appendTo="body">
  <div *ngIf="selectedAction">
    <input
      type="text"
      pInputText
      [(ngModel)]="selectedAction.fragmentParam"
      (ngModelChange)="onActionChange()"
      placeholder="Enter div ID to scroll into"
    />
  </div>
</p-overlayPanel>
