import { Directive, Input, OnInit } from '@angular/core';
import { AttributeActionHandlerService } from '@workflows/shared';
import { AbstractRuleAttributeDirective } from '@workflows/shared/directives/abstract-rule-attribute.directive';
import { AttributeMandatorySettable } from '@workflows/shared/types/attribute-mandatory-settable';
import { filter, map } from 'rxjs/operators';

@Directive({
  selector: '[appSetAttributeMandatory]',
})
export class SetAttributeMandatoryDirective extends AbstractRuleAttributeDirective implements OnInit {
  @Input() attributeOwner: AttributeMandatorySettable;

  constructor(protected actionAttributeHandler: AttributeActionHandlerService) {
    super();
  }

  ngOnInit() {
    this.actionAttributeHandler.registerSetAttributeMandatoryEventListeners(this.key);
    this.initSubscription = this.actionAttributeHandler.setAttributeMandatoryEventMap$
      .pipe(
        filter(() => this.actionAttributeHandler.containsSetAttributeMandatoryEvent(this.key)),
        map(() => this.actionAttributeHandler.getSetAttributeMandatoryEvent(this.key)),
      )
      .subscribe(setMandatoryEvent => {
        this.setAttributeMandatory(setMandatoryEvent.mandatory);
        this.actionAttributeHandler.removeSetAttributeMandatoryEvent(this.key, false);
      });
  }

  ngOnDestroy() {
    this.actionAttributeHandler.unregisterSetAttributeMandatoryEventListeners(this.key);
    super.ngOnDestroy();
  }

  private setAttributeMandatory(mandatory: boolean): void {
    this.attributeOwner.setAttributeMandatory(mandatory);
  }
}
