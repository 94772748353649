import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ArtifactLinkCreateRequestDto,
  ArtifactLinkResponseDto,
  ArtifactUpdateRequestDto,
  FolderDataResponseDto,
  LinkCreateDto,
  LinkTypeRestrictionResponseDto,
  PageResponseDto,
  RegisterRequestDto,
  SystemAdminUserResponseDto,
} from '@api/models';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { AuthService } from '@api/services/auth.service';
import { TenantLinkTypeService } from '@api/services/tenant-link-type.service';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import {
  BaseDataType,
  DataTypeKind,
  LinkValidationType,
} from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Constants, ID_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { FileHelper } from '@shared/helpers/file.helper';
import { PageHelper } from '@shared/helpers/page-helper';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { GetAttributeFromClientAttribute, GetDataTypeFromClientAttribute, GetDataTypeFromDataTypeId } from '@shared/methods/artifact.methods';
import { AttributeValueToClient, AttributeValueToServer } from '@shared/methods/client-attribute.methods';
import { IsBoolean, IsFile } from '@shared/methods/data-type.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { ServerSendEventMethods } from '@shared/methods/server-send-event.methods';
import { UrlMethods } from '@shared/methods/url.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { ServerSideEventService } from '@shared/services/server-side-event.service';
import { ArtifactTypeFormatEnum } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeToClientParams } from '@shared/types/attribute-convert.types';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { LinkType } from '@shared/types/link-type.types';
import { NewLink } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { StateKey } from '@shared/types/local-storage.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetComponent } from '@widgets/artifact-widget/artifact-widget.component';
import { ArtifactWidgetCustomAttributeHelper } from '@widgets/artifact-widget/helpers/artifact-widget-custom-attribute.helper';
import { ArtifactWidgetHelper } from '@widgets/artifact-widget/helpers/artifact-widget.helper';
import { ArtifactWidgetFormItem } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { ArtifactWidgetOptions } from '@widgets/artifact-widget/types/artifact-widget-options.types';
import {
  ArtifactFormItemSingleDateInitialValueType,
  ArtifactFormItemSingleUserInitialValueType,
  ArtifactSaveEventObject,
  ArtifactWidgetActionAfterLogin,
  ArtifactWidgetModel,
  ArtifactWidgetModelDto,
  ArtifactWidgetModelSelectedDto,
  ArtifactWidgetType,
  ArtifactWidgetValue,
} from '@widgets/artifact-widget/types/artifact-widget.types';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { ReplaceRuntimeVariablesPipe } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { cloneDeep } from 'lodash';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class ArtifactWidgetService {
  c: ArtifactWidgetComponent;
  m: ArtifactWidgetModel;

  constructor(
    public readonly tinymceHelper: TinyMceHelper,
    public readonly artifactWidgetCustomAttributeHelper: ArtifactWidgetCustomAttributeHelper,
    public readonly cache: NewCacheService,
    private readonly tenantLinkTypeService: TenantLinkTypeService,
    private readonly artifactWidgetHelper: ArtifactWidgetHelper,
    private readonly fileHelper: FileHelper,
    private readonly filtersService: ArtifactFiltersService,
    private readonly sseService: ServerSideEventService,
    private readonly announcement: AnnouncementService,
    private readonly authService: AuthService,
    private readonly blockUiService: BlockUiService,
    private readonly authorizationService: AuthorizationService,
    private readonly localStorageService: LocalStorageService,
    private readonly pageHelper: PageHelper,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly replaceRuntimeVariablesPipe: ReplaceRuntimeVariablesPipe,
  ) {}

  async init(context: ArtifactWidgetComponent): Promise<void> {
    if (context.isLayoutMode && (!context.m || (context.m && context.m.isFirstLoad))) {
      await this.initWidgetValue(context);
    } else {
      this.setContextAndModel(context);
      context.widget.value.model.updateNoAttributeEditableFlag();
      this.m.form.forEach(field => field.attribute && (field.attribute.value.value = undefined));
    }

    await this.redirectIfLoggedIn();
  }

  async initWidgetValue(context: ArtifactWidgetComponent): Promise<void> {
    const modelDto = context.widget.value?.model as any as ArtifactWidgetModelDto;
    context.widget.value = new ArtifactWidgetValue(new ArtifactWidgetModel({ replaceRuntimeVariablesPipe: this.replaceRuntimeVariablesPipe }));
    this.setContextAndModel(context);

    this.m.options.init(this.cache);
    this.m.options.systemAttributes.setList(this.filtersService.getSystemAttributes(), ID_KEY);

    this.setAllLinkTypeOptions(this.m.options.linkTypes.list);

    modelDto && !modelDto.selected && (modelDto.selected = {} as ArtifactWidgetModelSelectedDto);
    modelDto && (await this.initLinkRestrictions(this.c, modelDto.selected.artifactTypeId));
    await this.initLinkTypeOptions(this.m.options, this.c);
    await this.m.setFromDto({
      options: this.m.options,
      model: modelDto,
      attributes: this.m.options.attributes,
      cache: this.cache,
      route: this.route,
    });
    await this.m.mapFilesToArtifactType(this.cache, this.announcement, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap);

    this.m.isFirstLoad = false;
    this.m.options.createOptionsFromEnums(this.m);
    this.m.updateNoAttributeEditableFlag();

    this.initSubscriptions();
    this.artifactWidgetHelper.onFormItemChange(this.m);

    this.setRequiredFormLinks();

    this.artifactWidgetHelper.updateIsNoTabKeyActiveInUrl(this.m);
  }

  initArtifactTypeRequiredAttributes(): void {
    this.initRequiredFormAttributes();
    this.initRequiredFormLinks();
    this.setRequiredFormLinks();
    this.artifactWidgetHelper.onFormItemChange(this.m);

    if (!this.m.form.length) {
      this.m.form.push(new ArtifactWidgetFormItem());
    }
  }

  initRequiredFormAttributes(): void {
    this.m.options.clientAttributes.map(option => {
      if (option.value.isMandatory) {
        const item = {
          value: new SelectOption(option.label, option.value),
          attribute: new SelectOption(option.label, option.value),
        };
        this.m.form.push(new ArtifactWidgetFormItem(item));
        const editable = this.artifactWidgetHelper.isAttributeEditable(this.m, option.value.id);
        this.m.formatsMap.attribute[option.value.id] = new AttributeFormatSettings({ editable });

        if ([ArtifactWidgetType.login, ArtifactWidgetType.recoverPassword, ArtifactWidgetType.registration].includes(this.m.settings.widgetType)) return;

        const attribute = this.m.options.attributes.listMap[option.value.id];
        const dataType = this.m.options.dataTypes.listMap[attribute.dataTypeId];

        this.artifactWidgetHelper.setDisplayVariantsMetaData({
          formatSettings: this.m.formatsMap.attribute[option.value.id],
          dataType,
          attributes: this.m.options.attributes,
          attributeId: option.value.id,
          isDate: dataType?.isDate,
          isDateTime: dataType.isDateTime,
        });
      }
    });
  }

  initRequiredFormLinks(): void {
    this.m.options.linkTypeOptions.forEach((option, index) => {
      const direction: LinkDirection = option.meta;
      const usedLinkTypesIds = new Set<string>();
      option.value.restrictions.map(restriction => {
        if (
          restriction.isLinkRequired &&
          ((direction === LinkDirection.incoming && this.m.selected.artifactType?.id === restriction.destinationArtifactTypeId) ||
            (direction === LinkDirection.outgoing && this.m.selected.artifactType?.id === restriction.sourceArtifactTypeId))
        ) {
          const linkType = this.m.options.linkTypeOptions[index];
          linkType.disabled = true;
          if (!usedLinkTypesIds.has(linkType.value.id)) {
            usedLinkTypesIds.add(linkType.value.id);
            const item = {
              value: new SelectOption(linkType.label, { id: linkType.value.id, value: '', isMandatory: true, linkType: null }),
              linkType: new SelectOption(linkType.label, linkType.value, option.meta),
            };
            this.m.form.push(new ArtifactWidgetFormItem(item));
          }
        }
      });
    });
  }

  // TODO: check setRequiredFormLinks
  setRequiredFormLinks(): void {
    this.m.form.map(formItem => {
      if (!formItem.linkType) return;

      const direction: LinkDirection = formItem.linkType.meta;
      formItem.linkType.value.restrictions.forEach(restriction => {
        formItem.isLinkTypeRequired = this.isLinkRequired(restriction, direction);
      });
    });
  }

  isLinkRequired(restriction: LinkTypeRestrictionResponseDto, direction: LinkDirection): boolean {
    return (
      (direction === LinkDirection.outgoing &&
        this.m.selected.artifactType?.id === restriction.sourceArtifactTypeId &&
        restriction.isLinkRequired === LinkValidationType.source) ||
      (direction === LinkDirection.incoming &&
        this.m.selected.artifactType?.id === restriction.destinationArtifactTypeId &&
        restriction.isLinkRequired === LinkValidationType.destination)
    );
  }

  async initLinkTypeOptions(options: ArtifactWidgetOptions, context: ArtifactWidgetComponent): Promise<void> {
    await options.setLinkTypeOptionsAndIdsMap(context.widget.value.model.linkRestrictions, context.widget.value.model.options.linkTypes);
    options.attributes = cloneDeep(options.attributes) as ListContainer<NewAttribute>;
  }

  async register(): Promise<void> {
    if (this.m.settings.useReCaptcha && !this.m.isCaptchaValid) {
      await this.announcement.error('Solve Captcha first please');
      return;
    }

    if (!this.c.checkMandatoryFields(this.m.form, true)) return;

    try {
      const passwordAttribute = this.m.form.find(item => item?.value?.value?.id === NonAttributeKeys.PASSWORD_ID)?.value?.value as NewClientAttribute;
      const confirmPasswordAttribute = this.m.form.find(item => item?.value?.value?.id === NonAttributeKeys.CONFIRM_PASSWORD_ID)?.value
        ?.value as NewClientAttribute;
      const email = (
        this.m.form.find(item => item?.value?.value?.id === GlobalConstants.getValue(GlobalConstantsEnum.emailAttributeId))?.value?.value as NewClientAttribute
      )?.value;

      if (passwordAttribute?.value !== confirmPasswordAttribute?.value) return this.announcement.error('Passwords do not match');

      const attributes: Record<string, any> = {};

      await this.mapFilesToServer(this.m.form);
      this.mapOptionAttributesToServer(attributes);
      this.mapFormAttributesToServer(attributes, this.m.form);
      Object.keys(this.m.options.clientAttributesPattern).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(attributes, key)) {
          this.m.options.clientAttributesPattern[key] = attributes[key];
        } else {
          const attributeValue = Object.prototype.hasOwnProperty.call(this.m.options.clientAttributesPattern[key], 'value')
            ? this.m.options.clientAttributesPattern[key].value
            : this.m.options.clientAttributesPattern[key];
          this.m.options.clientAttributesPattern[key] = { value: attributeValue };
        }
      });

      delete this.m.options.clientAttributesPattern[NonAttributeKeys.PASSWORD_ID];
      delete this.m.options.clientAttributesPattern[NonAttributeKeys.CONFIRM_PASSWORD_ID];
      delete this.m.options.clientAttributesPattern[GlobalConstants.getValue(GlobalConstantsEnum.emailAttributeId)];

      const body: RegisterRequestDto = { email, password: passwordAttribute?.value, attributes: this.m.options.clientAttributesPattern };

      await lastValueFrom(this.authService.authControllerRegistration({ origin: window.location.origin, body }));

      await this.c.announcement.success('Registered successfully');
      this.m.isEditInProgress = false;

      if (this.m.settings.registrationRedirectionPageId) {
        await this.router.navigateByUrl('/' + this.m.settings.registrationRedirectionPageId);
      } else {
        this.resetForm(this.m.form);
        this.c.notifyTriggerWidgetCreateMode();

        passwordAttribute.value = '';
        confirmPasswordAttribute.value = '';
      }
    } catch (e) {
      console.error(e);
      await this.c.announcement.error('Something went wrong during registration');
    }
  }

  async login(): Promise<void> {
    const loginAttribute = this.m.form.find(item => item?.value?.value?.id === NonAttributeKeys.LOGIN_ID)?.value?.value as NewClientAttribute;
    if (!loginAttribute?.value) return this.announcement.error('Login is required');

    const passwordAttribute = this.m.form.find(item => item?.value?.value?.id === NonAttributeKeys.PASSWORD_ID)?.value?.value as NewClientAttribute;
    if (!passwordAttribute?.value) return this.announcement.error('Password is required');

    const login = loginAttribute?.value;
    const password = passwordAttribute?.value;

    try {
      this.blockUiService.blockUi();

      await this.authorizationService.login(login, password).then(async res => {
        if (res && typeof res === 'object') {
          const { user, redirectionPage } = res;

          this.authorizationService.resetUserMeta();

          setTimeout(async () => {
            if (typeof user === 'object') {
              this.cache?.user?.next(user as any);
              await this.redirectLoggedInUser(user, redirectionPage);
            }
          });
        }
      });
    } finally {
      this.blockUiService.unblockUi();
    }
  }

  async recoverPasswordInitiate(): Promise<void> {
    if (this.m.settings.useReCaptcha && !this.m.isCaptchaValid) {
      await this.announcement.error('Solve Captcha first please');
      return;
    }

    const email = (this.m.form.find(item => item?.value?.value?.id === NonAttributeKeys.LOGIN_ID)?.value?.value as NewClientAttribute)?.value;

    if (!email) {
      await this.announcement.error('Enter email first please');
      return;
    }

    this.blockUiService.blockUi();

    const success = await this.authorizationService.recoverPassword(email);

    if (success) {
      await this.announcement.success('Please check your email and reset the password');
      await this.router.navigateByUrl('/login');
    }

    this.blockUiService.unblockUi();
  }

  async tokenPasswordChange(token: string): Promise<void> {
    if (this.m.settings.useReCaptcha && !this.m.isCaptchaValid) {
      await this.announcement.error('Solve Captcha first please');
      return;
    }

    const email = (this.m.form.find(item => item?.value?.value?.id === NonAttributeKeys.LOGIN_ID)?.value?.value as NewClientAttribute)?.value;

    if (!email) {
      await this.announcement.error('Enter email first please');
      return;
    }

    const password = (this.m.form.find(item => item?.value?.value?.id === NonAttributeKeys.PASSWORD_ID)?.value?.value as NewClientAttribute)?.value;
    const confirmPassword = (this.m.form.find(item => item?.value?.value?.id === NonAttributeKeys.CONFIRM_PASSWORD_ID)?.value?.value as NewClientAttribute)
      ?.value;

    if (password !== confirmPassword) {
      this.announcement.error('Passwords do not match');
      return;
    }

    this.blockUiService.blockUi();

    const success = await this.authorizationService.tokenPasswordChange(email, password, token);

    if (success) {
      const redirectUrl = '/' + (this.m.settings.passwordRecoveryRedirectionPageId || 'login');
      await this.announcement.success('Now you can login with your new password');
      await this.router.navigateByUrl(redirectUrl);
    }

    this.blockUiService.unblockUi();
  }

  async recoverPassword(): Promise<void> {
    if (!this.c.checkMandatoryFields(this.m.form)) return;

    try {
      const { token } = this.route?.snapshot.queryParams || {};

      token ? await this.tokenPasswordChange(token) : await this.recoverPasswordInitiate();
    } catch (e) {
      console.error(e);
      await this.c.announcement.error('Something went wrong during registration');
    }
  }

  async save(shouldNotify = false, formRef?: ArtifactWidgetFormItem[], artifactIdRef?: string): Promise<void> {
    // formRef and artifactId are used in debouncing changes from old form
    const form = formRef || cloneDeep(this.m.form);
    const artifactId = artifactIdRef || this.m.artifactId;

    if (!this.c.checkMandatoryFields(form)) throw new Error('Mandatory fields are missing.');

    try {
      const attributes: Record<string, any> = {};

      await this.mapFilesToServer(form);
      this.mapOptionAttributesToServer(attributes);
      this.mapFormAttributesToServer(attributes, form);
      Object.keys(this.m.options.clientAttributesPattern).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(attributes, key)) {
          this.m.options.clientAttributesPattern[key] = attributes[key];
        } else {
          const attributeValue = Object.prototype.hasOwnProperty.call(this.m.options.clientAttributesPattern[key], 'value')
            ? this.m.options.clientAttributesPattern[key].value
            : this.m.options.clientAttributesPattern[key];
          this.m.options.clientAttributesPattern[key] = { value: attributeValue };
        }
      });

      // not send sequence if it not change
      if (this.m.options.clientAttributesPattern[GlobalConstants.getValue(GlobalConstantsEnum.sequenceAttributeId)]?.value === this.m.initSequence) {
        delete this.m.options.clientAttributesPattern[GlobalConstants.getValue(GlobalConstantsEnum.sequenceAttributeId)];
      }

      const pattern: Record<string, any> = {};
      Object.keys(this.m.options.clientAttributesPattern).forEach(key => {
        const current = this.m.options.clientAttributesPattern[key]?.value || '';
        const prev = this.m.initAttributesValue[key]?.value || '';

        if (!this.m.artifactId || current.toString() !== prev.toString()) {
          pattern[key] = { value: current };
        }
      });

      await this.saveAndNotify(pattern, form, artifactId, shouldNotify);
      Object.keys(pattern).forEach(key => {
        this.m.initAttributesValue[key] && (this.m.initAttributesValue[key].value = pattern[key]?.value);
      });

      if (!this.m.options.clientAttributesPattern[GlobalConstants.getValue(GlobalConstantsEnum.sequenceAttributeId)]) {
        this.m.options.clientAttributesPattern[GlobalConstants.getValue(GlobalConstantsEnum.sequenceAttributeId)] = { value: this.m.initSequence };
      }

      !artifactId && this.m.selected.artifact && (this.m.selected.artifact.folderData = {} as FolderDataResponseDto);

      await this.c.announcement.success('Saved successfully');
      this.m.isEditInProgress = false;
    } catch (e) {
      console.log(e);
      await this.c.announcement.error('Failed to save');
    }
  }

  async mapFilesToServer(form: ArtifactWidgetFormItem[]): Promise<void> {
    await this.fileHelper.createNewFiles(
      form
        .filter(
          item =>
            item.attribute &&
            GetDataTypeFromClientAttribute(item.attribute.value, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap)?.baseDataType ===
              BaseDataType.file,
        )
        .map((item: any) => item.attribute.value as NewClientAttribute),
      this.m.options.applications.listMap[this.c.applicationId],
      this.m.options.attributes.listMap,
    );
  }

  mapOptionAttributesToServer(attributes: Record<string, any>): void {
    this.m.options.clientAttributes.forEach(option => {
      const { value } = option.value;
      if (value || value === '') {
        const attribute = GetAttributeFromClientAttribute(option.value, this.m.options.attributes.listMap);
        const dataType = GetDataTypeFromClientAttribute(option.value, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap);
        attributes[option.value.id] = { value: AttributeValueToServer(dataType, attribute, value) };
      }
    });
  }

  mapFormAttributesToServer(attributes: Record<string, any>, form: ArtifactWidgetFormItem[]): void {
    form.forEach(item => {
      if (item.attribute) {
        const attribute = GetAttributeFromClientAttribute(item.attribute.value, this.m.options.attributes.listMap);
        const dataType = GetDataTypeFromClientAttribute(item.attribute.value, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap);
        attributes[item.attribute.value.id] = { value: AttributeValueToServer(dataType, attribute, item.attribute.value.value) };
      }
    });
  }

  async saveAndNotify(attributes: Record<string, any>, form: ArtifactWidgetFormItem[], artifactId: string | null, shouldNotify: boolean): Promise<void> {
    let artifactDto: (ArtifactResponseDto & { links?: LinkResponseDto[] }) | undefined;

    if (artifactId) {
      artifactDto = await this.updateArtifact(attributes, artifactId, shouldNotify);
      artifactDto && this.onSaveSuccess(RuntimeStateNotificationEnum.updateArtifact, artifactDto);
    } else {
      artifactDto = await this.createArtifact(attributes, form, shouldNotify);

      if (artifactDto) {
        this.onSaveSuccess(RuntimeStateNotificationEnum.createArtifact, artifactDto);

        if (artifactDto.links?.length) {
          this.onSaveSuccess(RuntimeStateNotificationEnum.createLink, artifactDto.links[0]);
        }
      }
      this.m.artifactId = null;
    }

    if (artifactDto) {
      this.cache.data.artifacts.setItem(artifactDto);
      artifactId && (await this.updateCurrentArtifact(new NewArtifact({ dto: artifactDto, artifactTypesMap: this.m.options.artifactTypes.listMap })));
      this.c.copyOriginalForm();
      this.m.setHasFormChangedFlag(false);
      this.m.updateShowSaveButtonFlag();
      setTimeout(() => {
        this.c.setFolderPath();
      });
      this.cache.data.artifacts.setItem(artifactDto);
    }
  }

  async updateArtifact(attributes: Record<string, any>, artifactId: string, shouldNotify = false): Promise<ArtifactResponseDto | undefined> {
    const { parentId, sequence } = this.m.selected.artifact?.folderData || {};
    const body: ArtifactUpdateRequestDto = {
      id: artifactId,
      attributes: this.removeAuthHeaderFromHtmlAttribute(attributes),
      // TODO resolve problem with folderData
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      folderData: { parentId, sequence: sequence <= 0 || !sequence ? 0 : sequence } as any,
    } as ArtifactUpdateRequestDto;

    try {
      return await firstValueFrom(this.c.tenantArtifactService.artifactControllerUpdate({ body, notify: shouldNotify, resetSequence: false }));
    } catch (e: any) {
      const errorMessage = e.status === 403 ? 'Failed to edit artifact due to insufficient permissions' : 'Failed to edit artifact';
      await this.announcement.error(errorMessage);
      throw new Error(errorMessage);
    }
  }

  async createArtifact(attributes: Record<string, any>, form: ArtifactWidgetFormItem[], shouldNotify = false): Promise<ArtifactResponseDto> {
    attributes = this.artifactWidgetCustomAttributeHelper.removeCustomAttribute(attributes);
    const links = this.prepareArtifactLinkCreateRequest();
    attributes = this.removeAuthHeaderFromHtmlAttribute(attributes);
    let parentId = this.m.selected.artifact?.folderData.parentId;
    // todo remove parentFolderId and add folderData: { parentId, sequence } after BE ready

    const { saveToFolderId } = this.m.settings.urlKeys.listeningKeys;
    !parentId && this.m.settings.listenForFolderUrlParam && this.m.queryParams[saveToFolderId] && (parentId = this.m.queryParams[saveToFolderId].split(',')[0]);

    const body: ArtifactLinkCreateRequestDto = {
      attributes,
      parentFolderId: parentId || null,
      artifactTypeId: this.m.selected.artifactType?.id || '',
      links: links as LinkCreateDto[],
    } as ArtifactLinkCreateRequestDto;

    for (const attributesKey in attributes) {
      const dataType = GetDataTypeFromDataTypeId(this.m.options.attributes.listMap[attributesKey].dataTypeId, this.m.options.dataTypes.listMap);
      if (dataType?.kind === DataTypeKind.counter) attributes[attributesKey] = { generateValue: true };
    }

    parentId && (await this.artifactWidgetHelper.insertModuleDataToArtifact(body, parentId, this.m));

    const artifactDto = await (async (): Promise<ArtifactResponseDto> => {
      if (this.m.selected.artifactType?.format === ArtifactTypeFormatEnum.file) {
        if (this.m.newArtifactFile === null) {
          this.announcement.error("Artifact type of format FILE can't be created without file selected");
          throw new Error('Failed to save');
        }

        return await firstValueFrom(
          this.c.tenantArtifactService.artifactControllerUploadWithLink({ body: { ...body, file: this.m.newArtifactFile }, notify: shouldNotify }),
        );
      }

      return await firstValueFrom(
        this.c.tenantArtifactService.artifactControllerCreateWithLink({
          body,
          notify: shouldNotify,
        }),
      );
    })();

    await this.artifactWidgetHelper.setEmittingKeysToUrl(this.m.settings, artifactDto, this.m.queryParams);

    if (this.m.settings.clearFormOnCreation) {
      try {
        this.resetForm(form);
        this.resetForm(this.m.form);
        if (this.shouldTriggerCreateModeAfterArtifactCreation(artifactDto)) {
          this.c.notifyTriggerWidgetCreateMode();
        }
      } catch (e) {
        console.error(e);
        await this.announcement.error('Failed to clear form');
      }
    } else {
      await this.c.setData({ [this.m.settings.urlKeys.listeningKeys.artifactId]: artifactDto.id });
    }

    this.c.resetConfigurableSettingsToDefault();
    return artifactDto;
  }

  resetForm(form: ArtifactWidgetFormItem[] = this.m.form): void {
    form.forEach(item => {
      if (item.value && item.attribute && item.value.value instanceof NewClientAttribute) {
        if (this.artifactWidgetCustomAttributeHelper.isCustomAttributes(item.attribute.value.id, this.m.selected.artifactType?.format)) {
          return;
        }

        const value = this.getAttributeInitialValue(item);

        item.attribute.value.value = AttributeValueToClient(
          new AttributeToClientParams({
            clientAttribute: item.attribute.value,
            dataTypes: this.m.options.dataTypes,
            attributes: this.m.options.attributes,
            value,
            users: this.m.options.users.list,
          }),
        );

        this.replaceRuntimeVariablesPipe.transform(item.attribute.value.value).then(value => (item.attribute!.value.value = value));
      }
    });
  }

  prepareArtifactLinkCreateRequest(): Partial<LinkCreateDto>[] {
    const links: Partial<LinkCreateDto>[] = [];
    const newLinksMapKeys = Object.entries(this.m.newLinksMap);

    newLinksMapKeys.map(([, linkType]) => {
      if (linkType.OUTGOING) {
        linkType.OUTGOING.map(link => {
          if (link.destinationId) {
            links.push({ destinationArtifactId: link.destinationId, linkTypeId: link.linkTypeId });
          }
        });
      }
      if (linkType.INCOMING) {
        linkType.INCOMING.map(link => {
          if (link.sourceId) {
            links.push({ sourceArtifactId: link.sourceId, linkTypeId: link.linkTypeId });
          }
        });
      }
    });

    const linkedArtifactLinkDto = this.getLinkedArtifactLinkDto();
    if (linkedArtifactLinkDto) {
      links.push(linkedArtifactLinkDto);
    }
    return links.length > 0 ? links : (null as any);
  }

  onSaveSuccess(notification: RuntimeStateNotificationEnum, data: any): void {
    let rtsNotification;
    if (ServerSendEventMethods.isCreateArtifact(notification)) rtsNotification = new RuntimeStateNotification<string>(notification, data, this.c.widget.id);
    else if (ServerSendEventMethods.isUpdateArtifact(notification))
      rtsNotification = new RuntimeStateNotification<string>(notification, data, this.c.widget.id);
    else if (ServerSendEventMethods.isCreateLink(notification))
      rtsNotification = new RuntimeStateNotification<string[]>(
        notification,
        [data.sourceArtifactId, data.destinationArtifactId],
        this.c.widget.id,
        data.linkTypeId,
      );

    this.c.runtimeStateNotificationService.events$.next(rtsNotification as RuntimeStateNotification);
    if (ServerSendEventMethods.isCreateArtifact(notification) && this.m.newLinksMap) {
      this.m.newLinksMap = {};
    }
    this.c.copyOriginalForm();
    this.m.setHasFormChangedFlag(false);
  }

  async initLinkRestrictions(context: ArtifactWidgetComponent, artifactTypeId: string | undefined): Promise<void> {
    if (artifactTypeId) {
      const filter = LinkMethods.getArtifactLinkTypesFilter(artifactTypeId);
      const relevantLinkTypes = (await firstValueFrom(this.tenantLinkTypeService.linkTypeControllerList({ filter }))).data;
      context.widget.value.model.linkRestrictions = LinkMethods.getLinkRestrictionsForArtifactType(artifactTypeId, relevantLinkTypes);
    }
  }

  async deleteLink(link: NewLink, index: number, linkTypeId: string, direction: LinkDirection): Promise<void> {
    try {
      const dto = await firstValueFrom(this.c.tenantLinkService.linkControllerDelete({ id: link.id }));

      this.m.linkMap[linkTypeId][direction].splice(index, 1);
      this.m.linkMap = cloneDeep(this.m.linkMap);
      this.c.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<string[]>(
          RuntimeStateNotificationEnum.deleteLink,
          [dto.destinationArtifactId, dto.sourceArtifactId],
          this.c.widget.id,
          dto.linkTypeId,
        ),
      );
    } catch (e) {
      await this.c.announcement.error('Failed to delete link');
    }
  }

  /**
   * @returns link create dto if the widget is in linkedArtifact mode, null otherwise
   */
  getLinkedArtifactLinkDto(): LinkCreateDto | null {
    if (this.m.settings.widgetType === ArtifactWidgetType.linkedArtifact) {
      const link: LinkCreateDto = { linkTypeId: this.m.selected.linkType?.value.id || '' };

      if (this.m.selected.linkType?.meta === LinkDirection.outgoing) {
        link.destinationArtifactId = this.m.queryParams[this.m.settings.urlKeys.listeningKeys.linkedArtifactId];
      } else {
        link.sourceArtifactId = this.m.queryParams[this.m.settings.urlKeys.listeningKeys.linkedArtifactId];
      }
      return link;
    }
    return null;
  }

  async redirectIfLoggedIn(): Promise<boolean> {
    if (this.m.settings.widgetType === ArtifactWidgetType.login && !window.location.href.includes('page-builder') && this.authorizationService.isLoggedIn) {
      this.pageHelper.usePublicToken = false;
      const user: SystemAdminUserResponseDto = this.localStorageService.getFromState(StateKey.session, Constants.user);
      const application = user?.tenant?.defaultApplicationId ? await this.cache.data.applications.getAsync(user.tenant?.defaultApplicationId) : null;
      const page = application?.defaultPageId ? await this.cache.data.pages.getAsync(application.defaultPageId) : undefined;

      await this.redirectLoggedInUser(user, page);
      return true;
    }

    return false;
  }

  private async redirectLoggedInUser(user: SystemAdminUserResponseDto, redirectionPage?: PageResponseDto): Promise<void> {
    let redirectUrl = this.route.snapshot.queryParams['redirectUrl'];

    if (user?.tenant?.id) {
      if (!redirectUrl) {
        if (this.m.settings.actionAfterLogin === ArtifactWidgetActionAfterLogin.defaultApplicationPage && redirectionPage) {
          redirectUrl = '/' + (this.m.settings.useAliasForRedirection ? redirectionPage.alias : redirectionPage.id);
        } else if (this.m.settings.actionAfterLogin === ArtifactWidgetActionAfterLogin.specificPage) {
          if (this.m.settings.loginRedirectionPageId) {
            const page = await this.cache.data.pages.getAsync(this.m.settings.loginRedirectionPageId).catch(e => {
              if (e.status === 404) {
                this.pageHelper.usePublicToken = false;
                return this.cache.data.pages
                  .getAsync(this.m.settings.loginRedirectionPageId!)
                  .catch(() => null)
                  .finally(() => (this.pageHelper.usePublicToken = true));
              } else return null;
            });
            if (page) redirectUrl = '/' + (this.m.settings.useAliasForRedirection ? page.alias : page.id);
            else redirectUrl = '/' + this.m.settings.loginRedirectionPageId;
          } else redirectUrl = '/application-selection';
        } else if (this.m.settings.actionAfterLogin === ArtifactWidgetActionAfterLogin.applicationSelection) {
          redirectUrl = '/application-selection';
        }
      }
    } else if (user?.isSystemAdmin) redirectUrl = '/system';

    UrlMethods.windowOpen(redirectUrl ? redirectUrl : '/application-selection');
  }

  private initSubscriptions(): void {
    const data = this.cache.data;

    this.c.registerSubscriptions([
      data.attributes.subscribe(() => this.m.options.setAttributes(this.cache)),
      data.dataTypes.subscribe(() => this.m.options.setDataTypes(this.cache)),
      data.users.subscribe(() => this.m.options.setUsers(this.cache)),
      data.artifactTypes.subscribe(() => this.m.options.setArtifactTypes(this.cache)),
      data.linkTypes.subscribe(() => this.m.options.setLinkTypes(this.cache)),
      data.applications.subscribe(() => this.m.options.setApplications(this.cache)),
      data.pages.subscribe(() => this.m.options.setPages(this.cache)),
      this.cache.userProfile.subscribe(profile => (this.m.options.currentUser = profile as ArtifactLinkResponseDto)),

      this.c.route.queryParams.subscribe(async (params: Params) => {
        const oldParams = this.m.queryParams;
        this.m.queryParams = params;
        this.artifactWidgetHelper.updateIsNoTabKeyActiveInUrl(this.m);
        await this.c.setData(params, false, false, oldParams);
      }),

      this.m.subscriptions.saveDebounce$.pipe(debounceTime(1500)).subscribe(async (eventObject: ArtifactSaveEventObject) => {
        if (eventObject.isChange && this.shouldForceChangeSave(eventObject))
          await this.save(this.m.settings.notifyOnAutomaticSave, eventObject.form, eventObject.artifactId);
        if (eventObject.isBlur && this.shouldForceBlurSave()) await this.save(this.m.settings.notifyOnAutomaticSave);
      }),

      this.c.runtimeStateNotificationService.events$.subscribe(async (event: RuntimeStateNotification) => {
        if (event.hash !== this.c.widget.id || event.hash === this.sseService.runtimeKey) {
          if (this.shouldReactToUpdateArtifactSse(event)) {
            await this.onSseUpdateArtifact(event);
          } else if (this.shouldReactToLinkSse(event)) {
            await this.c.setLinksMapAndLinkedArtifactsMap();
          }
        }
      }),
    ]);
  }

  private setContextAndModel(context: ArtifactWidgetComponent): void {
    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
  }

  // private async createNewLink(newlyCreatedArtifact: ArtifactResponseDto, shouldNotify = false): Promise<LinkResponseDto> {
  //   const linkedArtifactId = this.m.queryParams[this.m.settings.urlKeys.listeningKeys.linkedArtifactId];
  //   const linkTypeOption = this.m.selected.linkType;
  //   const linkBody: LinkRequestDto = {
  //     linkTypeId: linkTypeOption?.value.id || '',
  //     sourceArtifactId: '',
  //     destinationArtifactId: '',
  //   };
  //   if (linkTypeOption) {
  //     const direction = linkTypeOption.meta;
  //     if (LinkMethods.isOutgoing(direction)) {
  //       linkBody.sourceArtifactId = newlyCreatedArtifact.id;
  //       linkBody.destinationArtifactId = linkedArtifactId;
  //     } else if (LinkMethods.isIncoming(direction)) {
  //       linkBody.sourceArtifactId = linkedArtifactId;
  //       linkBody.destinationArtifactId = newlyCreatedArtifact.id;
  //     }
  //   }
  //   return (await firstValueFrom(this.tenantLinkService.linkControllerCreate({ body: { links: [linkBody] }, notify: shouldNotify })))?.data[0];
  // }

  private setAllLinkTypeOptions(linkTypes: LinkType[]): void {
    linkTypes.forEach((linkType: LinkType) => {
      this.m.options.allLinkTypeOptions.push(new SelectOption(linkType.outgoingName, linkType, LinkDirection.outgoing));
      this.m.options.allLinkTypeOptions.push(new SelectOption(linkType.incomingName, linkType, LinkDirection.incoming));
    });
  }

  private shouldForceChangeSave(eventObject: ArtifactSaveEventObject): boolean {
    return this.m.hasFormChanged || eventObject.artifactId !== this.m.selected.artifact?.id;
  }

  private shouldForceBlurSave(): boolean {
    return this.m.hasFormChanged && this.artifactWidgetHelper.shouldClearFormOnBlur(this.m) && this.c.checkMandatoryFields(this.m.form, false);
  }

  private shouldReactToUpdateArtifactSse(event: RuntimeStateNotification): boolean {
    return event.isOfTypeUpdateArtifact && ((event.data as ArtifactResponseDto).id === this.m.artifactId || !!this.m.linkedArtifactsMap[event.data.id]);
  }

  private shouldReactToLinkSse(event: RuntimeStateNotification): boolean {
    return (
      !!this.m.artifactId &&
      (event.isOfTypeCreateLink || event.isOfTypeDeleteLink || event.isOfTypeUpdateLinks) &&
      (event.data as string[]).includes(this.m.artifactId) &&
      this.m.options.linkTypeIdsMap[event.extras]
    );
  }

  private async onSseUpdateArtifact(event: RuntimeStateNotification): Promise<void> {
    const updatedArtifact = new NewArtifact({ dto: event.data, artifactTypesMap: this.m.options.artifactTypes.listMap });

    if ((event.data as ArtifactResponseDto).id === this.m.artifactId) await this.updateCurrentArtifact(updatedArtifact);
    if (this.m.linkedArtifactsMap[event.data.id]) this.updateLinkedArtifact(updatedArtifact);
  }

  private async updateCurrentArtifact(artifact: NewArtifact): Promise<void> {
    this.m.selected.artifact = artifact;
    this.m.form.forEach(item => {
      const attribute = item.attribute ? this.m.selected.artifact?.attributes[(item.attribute?.value as any).id] : null;
      if (attribute) {
        if (!item.attribute) item.attribute = new SelectOption<string, NewClientAttribute>(this.m.options.attributes.listMap[attribute.id].name);
        !this.isBoolean(attribute.id) && (item.attribute.value = attribute as any);
      }
    });
    await this.c.mapNewDataValuesToClient();
  }

  private isBoolean(attrId: string): boolean {
    const attribute: NewAttribute = this.m.options.attributes.listMap[attrId];
    const dataType = this.m.options.dataTypes.listMap[attribute?.dataTypeId];
    const baseDataType = dataType?.baseDataType as BaseDataType;
    return baseDataType && IsBoolean(baseDataType);
  }

  private updateLinkedArtifact(artifact: NewArtifact): void {
    this.m.linkedArtifactsMap[artifact.id] = artifact;
    this.m.linkedArtifactsMap = { ...this.m.linkedArtifactsMap };
  }

  private removeAuthHeaderFromHtmlAttribute(attributes: Record<string, any>): Record<string, any> {
    for (const attributeKey in attributes) {
      const datatype = this.m.options.dataTypes.listMap[this.m.options.attributes.listMap[attributeKey].dataTypeId];
      if (datatype && !datatype.isHtml) {
        continue;
      }
      attributes[attributeKey].value = this.tinymceHelper.addOrRemoveImageAuth(attributes[attributeKey].value, true);
    }
    return attributes;
  }

  private getAttributeInitialValue(item: ArtifactWidgetFormItem): any {
    if (this.isAttributeFileMultiple(item)) return [];
    if (!item.attribute || this.m.formatsMap.attribute[item.attribute.value.id].ignoreInitialValue) return null;

    const widgetItemInitialValue = this.m.formatsMap.attribute[item.attribute.value.id]?.initialValue;

    if (widgetItemInitialValue && widgetItemInitialValue === ArtifactFormItemSingleDateInitialValueType.today) return new Date();
    if (widgetItemInitialValue && widgetItemInitialValue === ArtifactFormItemSingleUserInitialValueType.currentUser)
      return new SelectOption(
        this.m.options.currentUser.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)],
        this.m.options.currentUser.id,
      );

    return widgetItemInitialValue || this.m.selected.artifactType?.attributes[item.attribute.value.id]?.initialValue;
  }

  private isAttributeFileMultiple(item: ArtifactWidgetFormItem): boolean {
    if (item.value.value instanceof NewClientAttribute) {
      const attribute = GetAttributeFromClientAttribute(item.value.value, this.m.options.attributes.listMap);
      const dataType = this.m.options.dataTypes.listMap[attribute?.dataTypeId || ''];
      return !!dataType?.baseDataType && IsFile(dataType.baseDataType) && !!attribute?.multipleValues;
    }
    return false;
  }

  private shouldTriggerCreateModeAfterArtifactCreation(artifactDto: ArtifactResponseDto): boolean {
    return (
      (!this.m.settings.addCreatedArtifactIdToUrlParam || !this.m.settings.urlChangeAction) &&
      (!this.m.settings.addCreatedFolderIdToUrlParam || !this.m.settings.listenForFolderUrlParam || !artifactDto.formatData)
    );
  }
}
