import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { cloneDeep } from 'lodash';
import { TextFilter } from '@widgets/shared/components/artifact-filters/components/text-filter/types/text-filter.types';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { FilterWidgetSelectTag } from '@widgets/filter-widget/types/filter-widget.types';
import { FilterTypeDetectionService } from '@widgets/shared/components/artifact-filters/services/filter-type-detection.service';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { HTTP_HTTPS_VALIDATION_REGEX } from '@shared/constants/constants';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { ActionWorkflow } from '@widgets/shared/components/action-list/components/menu-widget-actions/menu-widget-actions.component';

@Component({
  selector: 'app-list-widget-actions',
  templateUrl: './list-widget-actions.component.html',
  styleUrl: './list-widget-actions.component.scss'
})
export class ListWidgetActionsComponent {
  @Input() action: ActionItem;
  @Input() filtersDisabled: boolean;
  @Input() filtersHolder: { [filtersKey: string]: ArtifactFilter[] };
  @Input() filtersKey: string;
  @Input() options: ClickActionSettingsOptions;
  @Input() isHideMultiselect: boolean;
  @Input() multiselect: boolean;
  @Input() showFolderEmittingOption: boolean;
  @Input() shouldEmitFolderUrlKey: boolean;
  @Input() emittingFolderUrlKey: string;
  @Input() isAddToLinkDisabled: boolean;

  @Output() isMultiselectChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filtersChange: EventEmitter<ArtifactFilter[]> = new EventEmitter<ArtifactFilter[]>();
  @Output() actionChange = new EventEmitter<ActionItem>();

  @Output() emittingFolderUrlKeyChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() shouldEmitFolderUrlKeyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  tabsActiveState: boolean[] = [];

  protected readonly ArtifactListItemClickAction: typeof ArtifactListItemClickAction = ArtifactListItemClickAction;
  protected readonly http_https_validation_regex: RegExp = HTTP_HTTPS_VALIDATION_REGEX;
  protected readonly BaseDataType: typeof BaseDataType = BaseDataType;

  constructor(
      protected readonly filterTypeDetectionService: FilterTypeDetectionService,
      private readonly filterService: ArtifactFiltersService,
      private readonly cdRef: ChangeDetectorRef,
  ) {}

  get isEmittingUrlKeyEditable(): boolean {
    return !this.isAddToLinkDisabled && this.action.type !== this.ArtifactListItemClickAction.selectItem;
  }

  get isPageOpeningEditable(): boolean {
    return this.action.type !== this.ArtifactListItemClickAction.addToLink && this.action.type !== this.ArtifactListItemClickAction.selectItem && this.action.type !== this.ArtifactListItemClickAction.doNothing;
  }

  get filters(): ArtifactFilter[] {
    return this.filtersHolder[this.filtersKey];
  }

  set filters(filters: ArtifactFilter[]) {
    this.filtersHolder[this.filtersKey] = filters;
  }

  onFilterAttributeChange(newAttributeOrLinkType: NewAttribute | NewClientAttribute | DirectionalLinkType, updatedFilter: ArtifactFilter, index: number): void {
    this.filterService.updateFilter(updatedFilter, newAttributeOrLinkType, this.options.dataTypes);
    this.filters = this.filters.map((filter: ArtifactFilter) => {
      if (filter === updatedFilter) {
        const filterCopy = cloneDeep(filter);

        if (!filter.dataType?.isEnum && (filter.dataType?.baseDataType === BaseDataType.text || this.filterTypeDetectionService.isFolderPath(filter))) {
          filterCopy.value = new TextFilter();
        }

        return filterCopy;
      }

      return filter;
    });
    this.tabsActiveState = this.tabsActiveState.map((state: boolean, stateIndex: number) => stateIndex === index);
    this.cdRef.detectChanges();
  }

  addFilter(): void {
    this.filters = [...this.filters, ArtifactFilter.initial()];
  }

  removeFilter(removedFilter: ArtifactFilter): void {
    this.filters = this.filters.filter((filter: ArtifactFilter) => filter !== removedFilter);
    this.filtersChange.emit();
  }

  onEnumValueSelect({ index, isCtrl }: FilterWidgetSelectTag, filter: ArtifactFilter): void {
    const value = filter.dataType!.values![index].value;
    const isAlreadySelected = filter.value.selectedEnumValues.includes(value);
    let selectedEnumValues: string[] = [];

    if (isAlreadySelected) {
      selectedEnumValues = filter.value.selectedEnumValues.filter((existingValue: string) => existingValue !== value);
    } else {
      if (isCtrl) {
        selectedEnumValues = [...filter.value.selectedEnumValues, value];
      } else {
        selectedEnumValues = [value];
      }
    }

    filter.value.selectedEnumValues = selectedEnumValues;
  }

  onActionChange(): void {
    this.actionChange.emit(this.action);
  }

}
