<div class="grid">
    <div class="col-12">
        <p-dropdown
            (onChange)="onChangeWorkflow()"
            [(ngModel)]="workflow"
            [options]="workflows.list | transformArrayToSelectOptions: NAME_VALUE"
            [showClear]="true"
            [filter]="true"
            id="workflows"
            appendTo="body"
        >
        </p-dropdown>
    </div>

    <div class="col-12" *ngFor="let item of workflowData; index as i">
        <div class="grid">
            <div class="col-8">
                {{ item.key }}
            </div>
            <div class="col-4">
                <p-dropdown
                    (onChange)="onActionChange()"
                    [(ngModel)]="item.type"
                    [options]="valueItemTypeOptions"
                    id="workflows"
                    appendTo="body"
                >
                </p-dropdown>
            </div>
            <div class="col-12">
                <input
                    (blur)="onActionChange()"
                    [(ngModel)]="item.value"
                    [placeholder]="isStatic(item) ? item.valueType: 'url key or variable'"
                    class="p-inputtext full-width-container"
                    pInputText
                    type="text">
            </div>

        </div>
    </div>
</div>
