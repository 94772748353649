import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { PageBuilderGraphicalComponent } from '../page-builder-graphical.component';

@Injectable({ providedIn: 'root' })
export class PageChangeGuard implements CanDeactivate<PageBuilderGraphicalComponent> {
  async canDeactivate(
    component: PageBuilderGraphicalComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Promise<boolean> {
    nextState && (await component.checkIfCanBeDeactivated(nextState.url));
    return component.canDeactivate;
  }
}
