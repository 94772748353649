import { Injectable } from '@angular/core';

import { TenantWidgetService } from '@api/services/tenant-widget.service';

import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { NewCacheService } from '@shared/cache/new-cache.service';

import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { ID_KEY } from '@shared/constants/constants';
import { WidgetCreateRequestDto, WidgetResponseDto, WidgetUpdateRequestDto } from '@shared/types/widget.types';

import { ApexChartWidgetComponent } from '@widgets/apex-chart/apex-chart-widget.component';
import { ArtifactWidgetComponent } from '@widgets/artifact-widget/artifact-widget.component';
import { AuthWidgetComponent } from '@widgets/auth-widget/auth-widget.component';
import { AvrWidgetComponent } from '@widgets/avr-widget/avr-widget.component';
import { CardWidgetComponent } from '@widgets/card-widget/card-widget.component';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import { CardWidgetAreaContentItem, IsCardContentItemWidget } from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { ContentType } from '@widgets/card-widget/types/content-type';
import { ChartWidgetComponent } from '@widgets/chart-widget/chart-widget.component';
import { FilterWidgetComponent } from '@widgets/filter-widget/filter-widget.component';
import { FolderWidgetComponent } from '@widgets/folder-widget/folder-widget.component';
import { ListMatrixWidgetComponent } from '@widgets/list-matrix-widget/list-matrix-widget.component';
import { ListWidgetComponent } from '@widgets/list-widget-new/list-widget.component';
import { MenuWidgetComponent } from '@widgets/menu-widget/menu-widget.component';
import { NumberWidgetComponent } from '@widgets/number-widget/number-widget.component';
import { PictureWidgetComponent } from '@widgets/picture-widget/picture-widget.component';
import { SidebarModalWidgetComponent } from '@widgets/sidebar-modal-widget/sidebar-modal-widget.component';
import { SidebarModalWidgetModel, SidebarModalWidgetValue } from '@widgets/sidebar-modal-widget/types/sidebar-modal.types';
import { SidebarWidgetComponent } from '@widgets/sidebar-widget/sidebar-widget.component';
import { SidebarListItem, SidebarWidgetValue } from '@widgets/sidebar-widget/types/sidebar-widget.types';
import { TextWidgetComponent } from '@widgets/text-widget/text-widget.component';
import { WidgetOption, WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WidgetService {
  private static readonly widgetOptions: WidgetOption<any>[] = [
    new WidgetOption<TextWidgetComponent>(WidgetType.text, 'HTML', TextWidgetComponent, 'bi bi-textarea-t'),
    new WidgetOption<PictureWidgetComponent>(WidgetType.picture, 'Picture', PictureWidgetComponent, 'bi bi-card-image'),
    new WidgetOption<ListWidgetComponent>(WidgetType.listNew, 'List', ListWidgetComponent, 'bi bi-card-list'),
    new WidgetOption<ListMatrixWidgetComponent>(WidgetType.listMatrix, 'List matrix', ListMatrixWidgetComponent, 'bi bi-grid-3x3-gap'),
    new WidgetOption<MenuWidgetComponent>(WidgetType.menu, 'Menu', MenuWidgetComponent, 'bi bi-menu-button'),
    new WidgetOption<ArtifactWidgetComponent>(WidgetType.artifact, 'Artifact', ArtifactWidgetComponent, 'bi bi-columns-gap'),
    new WidgetOption<ChartWidgetComponent>(WidgetType.chart, 'Chart', ChartWidgetComponent, 'bi bi-graph-up'),
    new WidgetOption<ApexChartWidgetComponent>(WidgetType.apexChart, 'Apex Chart', ApexChartWidgetComponent, 'bi bi-graph-up'),
    new WidgetOption<CardWidgetComponent>(WidgetType.card, 'Card', CardWidgetComponent, 'bi bi-file-richtext'),
    new WidgetOption<SidebarWidgetComponent>(WidgetType.sidebar, 'Sidebar', SidebarWidgetComponent, 'bi bi-textarea-t'),
    new WidgetOption<SidebarModalWidgetComponent>(WidgetType.sidebarModal, 'Sidebar Modal', SidebarModalWidgetComponent, 'bi bi-textarea-t'),
    new WidgetOption<NumberWidgetComponent>(WidgetType.number, 'Number', NumberWidgetComponent, 'bi bi-123'),
    new WidgetOption<FolderWidgetComponent>(WidgetType.folder, 'Folder', FolderWidgetComponent, 'bi bi-folder'),
    // new WidgetOption<TagWidgetComponent>(WidgetType.tag, 'Tags', TagWidgetComponent, 'bi bi-tags'),
    new WidgetOption<FilterWidgetComponent>(WidgetType.filter, 'Filter', FilterWidgetComponent, 'bi bi-funnel'),
    new WidgetOption<AuthWidgetComponent>(WidgetType.auth, 'Login/Logout', AuthWidgetComponent, 'bi bi-lock'),
    new WidgetOption<AvrWidgetComponent>(WidgetType.avr, 'Artifact Representation', AvrWidgetComponent, 'bi bi-eye'),
  ];

  pageSidebars = new BehaviorSubject<SidebarListItem[]>([]);

  constructor(
    private readonly cache: NewCacheService,
    private readonly tenantWidgetService: TenantWidgetService,
  ) {}

  async saveWidget(body: WidgetCreateRequestDto | WidgetUpdateRequestDto): Promise<WidgetResponseDto> {
    const dto = await lastValueFrom(
      ID_KEY in body ? this.tenantWidgetService.widgetControllerUpdate({ body }) : this.tenantWidgetService.widgetControllerCreate({ body }),
    );

    this.cache.data.widgets.setItem(dto);

    return dto;
  }

  async loadWidgets(partsWithWidget: PageBlockPart[]): Promise<void> {
    if (!partsWithWidget.length) {
      return;
    }

    const widgetIds: string[] = [
      ...new Set(partsWithWidget.filter(({ widget }: PageBlockPart) => widget?.id).map(({ widget }: PageBlockPart) => widget!.id)),
    ] as string[];
    const widgets = await this.cache.data.widgets.getManyAsync(widgetIds);

    widgets.forEach((dto: WidgetResponseDto) => {
      partsWithWidget
        .filter(({ widget }: PageBlockPart) => widget!.id === dto.id)
        .forEach((part: PageBlockPart) => {
          const widget = new BlockPartWidget(cloneDeep({ ...dto, templateId: part.widget!.templateId }));

          widget.templateName = part.widget!.templateName;
          part.widget = widget;
        });
    });
  }

  async loadInnerWidgetsForPartsWithCard(partsWithCard: PageBlockPart[]): Promise<void> {
    const ids = new Set(partsWithCard.reduce((ids: string[], part: PageBlockPart) => [...ids, ...part.widget!.innerWidgetIds], []));

    if (!partsWithCard.length || !ids.size) {
      return;
    }

    const widgets = await this.cache.data.widgets.getManyAsync([...ids]);

    widgets.forEach((widgetDto: WidgetResponseDto) => {
      partsWithCard.forEach((part: PageBlockPart) => this.setCardInnerWidgets(part, widgetDto));
    });
  }

  async loadInnerWidgetsForCardsWithinSidebarWidgets(sidebars: BlockPartWidget<SidebarWidgetValue>[]): Promise<void> {
    const cardWidgetParts = sidebars.reduce((parts: PageBlockPart[], sidebar: BlockPartWidget<SidebarWidgetValue>) => {
      return [...parts, ...sidebar.value.model.parts.filter(({ widget }: PageBlockPart) => widget?.code === WidgetType.card)];
    }, []);

    await this.loadInnerWidgetsForPartsWithCard(cardWidgetParts);
  }

  async loadInnerWidgetsForCardsWithinSidebarModalWidgets(sidebars: BlockPartWidget<SidebarModalWidgetValue>[]): Promise<void> {
    const cardWidgetParts = sidebars.reduce((parts: PageBlockPart[], sidebar: BlockPartWidget<SidebarModalWidgetValue>) => {
      const currentParts: PageBlockPart[] = [];
      (sidebar.value?.model as SidebarModalWidgetModel)?.page?.sections.forEach(s => {
        s.rows.forEach(r => {
          r.blocks.forEach(block => {
            block.parts.forEach(part => {
              part.widget?.code === WidgetType.card && currentParts.push(part);
            });
          });
        });
      });
      return [...parts, ...currentParts];
    }, []);

    await this.loadInnerWidgetsForPartsWithCard(cardWidgetParts);
  }

  async loadCardInnerWidgets({ areas }: CardWidgetModel): Promise<void> {
    const widgetItems = areas.reduce(
      (widgetItems: CardWidgetAreaContentItem[], area: GenericArea<CardWidgetAreaContent>) => [
        ...widgetItems,
        ...area.content.items.filter(IsCardContentItemWidget),
      ],
      [],
    );
    const widgetIds: string[] = [...new Set(widgetItems.map((item: CardWidgetAreaContentItem) => (item.content as { id: string }).id))] as string[];
    const widgets = await this.cache.data.widgets.getManyAsync(widgetIds);

    widgets.forEach((dto: WidgetResponseDto) => {
      const item = widgetItems.find(({ content }: CardWidgetAreaContentItem) => (content as { id: string }).id === dto.id)!;
      item.content = new BlockPartWidget(dto);
    });
  }

  loadCardInnerWidgets$({ areas }: CardWidgetModel): Observable<WidgetResponseDto[]> {
    const widgetItems = areas.reduce(
      (widgetItems: CardWidgetAreaContentItem[], area: GenericArea<CardWidgetAreaContent>) => [
        ...widgetItems,
        ...area.content.items.filter(IsCardContentItemWidget),
      ],
      [],
    );
    const widgetIds: string[] = widgetItems.map((item: CardWidgetAreaContentItem) => (item.content as { id: string }).id);

    return this.cache.data.widgets.getMany$(widgetIds).pipe(
      tap((widgets: WidgetResponseDto[]) => {
        widgets.forEach((dto: WidgetResponseDto) => {
          const item = widgetItems.find(({ content }: CardWidgetAreaContentItem) => (content as { id: string }).id === dto.id)!;
          item.content = new BlockPartWidget(dto);
        });
      }),
    );
  }

  setPageSidebars(sidebars: SidebarListItem[]): void {
    this.pageSidebars.next(sidebars);
  }

  getWidgetOptions(exceptions: WidgetType[] = []): WidgetOption<any>[] {
    return exceptions.length ? WidgetService.widgetOptions.filter(({ code }: WidgetOption<any>) => !exceptions.includes(code)) : WidgetService.widgetOptions;
  }

  private setCardInnerWidgets({ widget }: PageBlockPart, dto: WidgetResponseDto): void {
    const model: CardWidgetModel = widget!.value.model;
    model.areas.forEach((area: GenericArea<CardWidgetAreaContent>) => {
      area.content.items = area.content.items.filter((item: CardWidgetAreaContentItem) => {
        return !(typeof item.content !== 'string' && !Object.keys(item.content).length);
      });
    });

    const area = model.areas.find(({ content }: GenericArea<CardWidgetAreaContent>) => {
      const index = content.items.findIndex(
        (item: CardWidgetAreaContentItem) => item.type === ContentType.widget && (item.content as BlockPartWidget).id === dto.id,
      );

      return index !== -1;
    });
    const widgetIndex = area?.content.items.findIndex(
      (item: CardWidgetAreaContentItem) => item.type === ContentType.widget && (item.content as BlockPartWidget).id === dto.id,
    );

    if (widgetIndex != null && widgetIndex >= 0) {
      area!.content.items = area!.content.items.map((item: CardWidgetAreaContentItem, index: number) => {
        return index === widgetIndex ? new CardWidgetAreaContentItem(ContentType.widget, new BlockPartWidget(dto)) : item;
      });
    }
  }
}
