<div
  [ngClass]="{
    'table-cell full-size-container': true,
    'container-with-ellipsis': settings.textWrap === 'nowrap',
    'p-text-wrap': settings.textWrap === 'wrap',
    'p-text-wrap-new-lines': isText() && settings.showLineBreaks
  }"
  appFormatAttribute
  [pageId]="pageId"
  [artifactTypeId]="artifact.artifactTypeId"
  [attributeId]="column.key"
  [attributeValue]="artifact.attributes[column.key]?.value"
>
  <ng-container *ngIf="editMode">
    <span *ngIf="isMandatory" class="table-mandatory-field"></span>
    <ng-container *ngIf="isAttribute()">
      <ng-container *ngIf="!isFile()" [ngTemplateOutlet]="editAttributeValueTemplate"></ng-container>
      <ng-container *ngIf="isFile()" [ngTemplateOutlet]="fileTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="isNonAttribute() && artifact.moduleData"
                  [ngTemplateOutlet]="editIsHeadingTemplate"></ng-container>
  </ng-container>

  <ng-container *ngIf="!editMode && isSimple">
    <ng-container *ngIf="isFileArtifact()" [ngTemplateOutlet]="fileArtifactTemplate"></ng-container>
  </ng-container>

  <ng-container *ngIf="!editMode && !isSimple">
    <ng-container
      *ngIf="column.meta.columnFormatSettings?.contentColumnFormat?.styles.prefix && artifact.attributes[column.key]?.value">
      <span class="prefix" [ngStyle]="column.meta.columnFormatSettings?.contentColumnFormat?.styles">
        {{ column.meta.columnFormatSettings?.contentColumnFormat?.styles.prefix }}
      </span>
    </ng-container>

    <ng-container
      *ngIf="!isIdColumn && !isHtml() && !isFile() && !isEnum() && !isUser() && !isHyperlink() && !isSystemDate()"
      [ngTemplateOutlet]="simpleTypeTemplate"></ng-container>
    <ng-container *ngIf="isHtml() && !isHyperlink()" [ngTemplateOutlet]="htmlTemplate"></ng-container>
    <ng-container *ngIf="isFile()" [ngTemplateOutlet]="fileTemplate"></ng-container>
    <ng-container *ngIf="isEnum()" [ngTemplateOutlet]="enumTemplate"></ng-container>
    <ng-container *ngIf="isUser()" [ngTemplateOutlet]="userTemplate"></ng-container>
    <ng-container *ngIf="isHyperlink()" [ngTemplateOutlet]="nonSimpleHyperlink"></ng-container>
    <ng-container *ngIf="isSystemDate()" [ngTemplateOutlet]="systemDate"></ng-container>

    <ng-container
      *ngIf="column.meta.columnFormatSettings?.contentColumnFormat?.styles.suffix && artifact.attributes[column.key]?.value">
      <span class="suffix" [ngStyle]="column.meta.columnFormatSettings?.contentColumnFormat?.styles">
        {{ column.meta.columnFormatSettings?.contentColumnFormat?.styles.suffix }}
      </span>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isSimple">
    <ng-container *ngIf="isUser()" [ngTemplateOutlet]="userTemplate"></ng-container>
    <ng-container
      *ngIf="isArtifactType()"
      [ngTemplateOutlet]="enumTemplate"
      [ngTemplateOutletContext]="{ customEnumOptions: column.meta.artifactTypeEnumOptions | getDataTypeValueResponseById : artifact.artifactTypeId }"
    ></ng-container>
    <ng-container *ngIf="isIdColumn && !isHtml()" [ngTemplateOutlet]="columnIdLinkTemplate"></ng-container>
    <div *ngIf="!isIdColumn && !isHtml() && !isArtifactType() && !isUser()" class="p-text-truncate" style="width: 100%">
      <ng-container *ngIf="column.meta.isLink; else simpleTypeTemplate"
                    [ngTemplateOutlet]="nonColumnIdLinkTemplate"></ng-container>
    </div>
  </ng-container>
</div>

<!-- TEMPLATE definitions -->
<ng-template #simpleTypeTemplate>
  <span
    *ngIf="!isBoolean()"
    [attr.data-key]="column.key + rowIndex"
    [ngClass]="{ 'tooltip-target': true, 'container-with-ellipsis': settings.textWrap === 'nowrap', 'p-text-wrap': settings.textWrap === 'wrap' }"
    [pTooltip]="
      artifact
        | showArtifactValueToTable : column.key : options.attributes.listMap : options.dataTypes.listMap : options.users.listMap
        | useDecimalPlaces : column.meta.columnFormatSettings?.contentColumnFormat?.styles.minDecimalPlaces
                           : column.meta.columnFormatSettings?.contentColumnFormat?.styles.maxDecimalPlaces
        | replaceRuntimeVariables : artifact
        | async
    "
    [showDelay]="500"
    [hideDelay]="2000"
    [tooltipDisabled]="!tooltipEnabled || isText()"
    tooltipPosition="left"
  >
    <ng-container [ngSwitch]="column.meta.displayAtEnumType">
      <ng-container *ngSwitchCase="displayAttributeTypeEnum.systemDate" [ngTemplateOutlet]="systemDate"></ng-container>
      <ng-container *ngSwitchCase="displayAttributeTypeEnum.dateTime" [ngTemplateOutlet]="dateTime"></ng-container>
      <ng-container *ngSwitchCase="displayAttributeTypeEnum.text">
        <app-display-at-text
          class="block-container full-size-container"
          [ownerId]="tableUniqueId"
          [attribute]="column.meta.attributeMetadata?.attribute"
          [artifactType]="options.artifactTypes.listMap[artifact.artifactTypeId]"
          [attributeId]="column.key"
          [artifact]="artifact"
          [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
          [forceShowContent]="column.key === contentKey || column.meta.displayAtMetadata?.selectedVariantCode === 'CUSTOM'"
          [truncate]="settings.textWrap === 'nowrap'"
          [options]="options"
          [files]="files"
          [filesLoaded]="filesLoaded"
          [links]="linksDto"
          [linkedArtifacts]="linkedData | mapToArray"
          [contentColumnFormat]="column.meta.columnFormatSettings?.contentColumnFormat"
        >
        </app-display-at-text>
      </ng-container>
      <ng-container *ngSwitchCase="displayAttributeTypeEnum.hyperlink">
        <app-display-at-hyperlink
          class="block-container full-size-container"
          [ownerId]="tableUniqueId"
          [attribute]="column.meta.attributeMetadata?.attribute"
          [attributeId]="column.key"
          [artifact]="artifact"
          [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
          [dataType]="column.meta.attributeMetadata?.dataType"
        >
        </app-display-at-hyperlink>
      </ng-container>
      <ng-container *ngSwitchDefault [ngTemplateOutlet]="simpleNonTextTemplate"></ng-container>
    </ng-container>
  </span>
  <span *ngIf="isBoolean()">
    <app-display-at-boolean
      class="block-container full-size-container"
      [ownerId]="tableUniqueId"
      [attributeId]="column.key"
      [artifact]="artifact"
      [dataType]="column.meta.attributeMetadata?.dataType"
      [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
      [selectedEditVariant]="column.meta.displayAtMetadata?.selectedEditCode"
      [doInternalUpdateOfValue]="true"
    >
    </app-display-at-boolean>
  </span>
</ng-template>

<ng-template #simpleNonTextTemplate>
  <span [ngStyle]="column.meta.columnFormatSettings?.contentColumnFormat?.styles">
    {{
      artifact
        | showArtifactValueToTable : column.key : options.attributes.listMap : options.dataTypes.listMap : options.users.listMap
        | useDecimalPlaces : column.meta.columnFormatSettings?.contentColumnFormat?.styles.minDecimalPlaces
        : column.meta.columnFormatSettings?.contentColumnFormat?.styles.maxDecimalPlaces
        | replaceRuntimeVariables : artifact
        | async
    }}
  </span>
</ng-template>
<ng-template #systemDate>
  <app-display-at-system-date
    [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
    [dataType]="column.meta.attributeMetadata?.dataType"
    [code]="column.key"
    [artifactNew]="artifact"
    [contentColumnFormat]="column.meta.columnFormatSettings?.contentColumnFormat"
  ></app-display-at-system-date>
</ng-template>

<ng-template #dateTime>
  <app-display-at-datetime
    [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
    [dataType]="column.meta.attributeMetadata?.dataType"
    [artifactNew]="artifact"
    [attrId]="column.key"
    [formatStyles]="column.meta.columnFormatSettings?.contentColumnFormat?.styles"
  ></app-display-at-datetime>
</ng-template>

<ng-template #nonSimpleHyperlink>
  <app-display-at-hyperlink
    class="block-container full-size-container"
    [ownerId]="tableUniqueId"
    [attribute]="column.meta.attributeMetadata?.attribute"
    [attributeId]="column.key"
    [artifact]="artifact"
    [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
    [dataType]="column.meta.attributeMetadata?.dataType"
  >
  </app-display-at-hyperlink>
</ng-template>

<ng-template #columnIdLinkTemplate>
  <a
    [attr.data-key]="column.key + index"
    [hideDelay]="2000"
    [pTooltip]="artifact | showArtifactValueToTable : column.key : options.attributes.listMap : options.dataTypes.listMap : options.users.listMap"
    [queryParams]="getRowIdLinkQueryParams()"
    [routerLink]="getRowIdLink()"
    [showDelay]="500"
    [tooltipDisabled]="!tooltipEnabled"
    class="tooltip-target"
    target="_blank"
    tooltipPosition="left"
  >
    {{ artifact | showArtifactValueToTable : column.key : options.attributes.listMap : options.dataTypes.listMap : options.users.listMap }}
  </a>
</ng-template>

<ng-template #nonColumnIdLinkTemplate>
  <app-display-at-link
    class="block-container full-size-container"
    [ownerId]="tableUniqueId"
    [linkStyleClass]="settings.textWrap === 'wrap' ? 'p-text-wrap' : 'p-text-truncate'"
    [attributeId]="column.key"
    [artifact]="artifact"
    [artifactTypes]="options.artifactTypes"
    [links]="links"
    [linkedData]="linkedData"
    [linksDto]="linksDto"
    [files]="files"
    [filesLoaded]="filesLoaded"
    [linkRestrictionParams]="column.meta.linkRestrictionParams"
    [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
    [showLinkButtons]="settings.showLinkButtons"
    (onAddLinkClick)="addLinkClicked(artifact, column)"
    (onDeleteLinkClick)="deleteLink(artifact, $event)"
    [contentColumnFormat]="column.meta.columnFormatSettings?.contentColumnFormat"
  >
  </app-display-at-link>
</ng-template>

<ng-template #htmlTemplate>
  <!-- <div
    [innerHTML]="artifact | showArtifactValueToTable: column.key:options.attributes.listMap:options.dataTypes.listMap:options.users.listMap | safeHtml"
    class="mce-content-body mce-content-readonly"
  ></div> -->
  <app-display-at-html
    class="block-container full-size-container"
    [ownerId]="tableUniqueId"
    [attributeId]="column.key"
    [attribute]="column.meta.attributeMetadata?.attribute"
    [artifact]="artifact"
    [dataType]="column.meta.attributeMetadata?.dataType"
    [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
  >
  </app-display-at-html>
</ng-template>

<ng-template #enumTemplate let-customEnumOptions="customEnumOptions">
  <app-display-at-enum
    class="block-container full-size-container"
    [ownerId]="tableUniqueId"
    [attributeId]="column.key"
    [attribute]="column.meta.attributeMetadata?.attribute"
    [artifact]="artifact"
    [artifactDto]="options.artifacts.listMap[artifact.id]"
    [dataType]="column.meta.attributeMetadata?.dataType"
    [enumOptions]="customEnumOptions"
    [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
    [selectedEditVariant]="column.meta.displayAtMetadata?.selectedEditCode"
    [doInternalUpdateOfValue]="true"
    [contentColumnFormat]="column.meta.columnFormatSettings?.contentColumnFormat"
  >
  </app-display-at-enum>
</ng-template>

<ng-template #userTemplate>
  <app-display-at-user
    class="block-container full-size-container"
    [defaultIconSize]="userIconSize"
    [ownerId]="tableUniqueId"
    [attributeId]="column.key"
    [artifact]="artifact"
    [usersMap]="options.users.listMap"
    [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
    [selectedEditVariant]="column.meta.displayAtMetadata?.selectedEditCode"
    [doInternalUpdateOfValue]="true"
  >
  </app-display-at-user>
</ng-template>

<ng-template #fileTemplate>
  <app-file-attribute
    [ownerId]="tableUniqueId"
    [attributeId]="column.key"
    [attributesMap]="options.attributes.listMap"
    [clientAttribute]="artifact.attributes[column.key]"
    [files]="files"
    [index]="index"
    [isEditMode]="editMode"
    [isInSavingProcess]="isInSavingProcess"
    [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
    [truncate]="textWrap === 'nowrap'"
    [unSavedFiles]="unSavedFiles"
    [onChangeCb]="updateAttributeValue.bind(this)"
    [contentColumnFormat]="column.meta.columnFormatSettings?.contentColumnFormat"
  >
  </app-file-attribute>
</ng-template>

<ng-template #fileArtifactTemplate>
  <app-file-attribute
    [artifactId]="artifact.id"
    [attributesMap]="options.attributes.listMap"
    [clientAttribute]="artifact.attributes[column.key]"
    [files]="artifact | processFileArtifactToFiles"
    [index]="index"
    [isEditMode]="editMode"
    [isInSavingProcess]="isInSavingProcess"
    [truncate]="textWrap === 'nowrap'"
    [unSavedFiles]="unSavedFiles"
    [onChangeCb]="updateAttributeValue.bind(this)"
  >
  </app-file-attribute>
</ng-template>

<ng-template #editAttributeValueTemplate>
  <app-attribute-value-edit-field
    [value]="getAttribute()?.value"
    [dataType]="getDataType()"
    [disabled]="false"
    [index]="rowIndex"
    [multipleValues]="options.attributes.listMap[column.key].multipleValues"
    [users]="options.users"
    (valueChange)="updateAttributeValue($event)"
    [selectedVariant]="column.meta.displayAtMetadata?.selectedVariantCode"
  >
  </app-attribute-value-edit-field>
</ng-template>

<ng-template #editIsHeadingTemplate>
  <p-checkbox [(ngModel)]="artifact.moduleData.isHeading" [inputId]="'isHeading' + rowIndex" binary="true"></p-checkbox>
</ng-template>
