import { Application } from '@private/pages/application-management/application/types/application.types';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { AdvancedDateFilterObject } from '@shared/components/date-filter/types/date-filter.types';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { FolderFilterEnum, LinkFilterEnum, UserFilterEntry } from '@shared/types/filter.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewTableColumn } from '@shared/types/table.types';
import { Observable, ReplaySubject } from 'rxjs';
import { ListWidgetTableSettingsDto } from './list-widget-settings.types';
import { ListWidgetType } from './list-widget.types';

export class ListWidgetSelected {
  columnsDto: { key: string }[] | null = null;

  columns: NewTableColumn[] = [];
  artifactTypes: SelectOption<string, NewArtifactType>[] = [];
  applications: SelectOption<string, NewApplication>[] = [];
  module: NewArtifact | null = null;
  linkTypes: SelectOption<string, LinkType, LinkDirection>[] = [];
  artifact: NewArtifact | null = null;
  artifacts: NewArtifact[] = [];
  linkFiltersNew: Record<LinkFilterEnum | string, LinkFilterNew> = {};
  folderFilter?: Record<FolderFilterEnum, string>;
  dateFilters: Record<string, AdvancedDateFilterObject> = {};
  userFilters: Record<string, UserFilterEntry> = {};
  exportInProcess = false;

  columnsSubject: ReplaySubject<NewTableColumn[]> = new ReplaySubject(1);

  constructor(selected: Partial<ListWidgetSelected> | null = null) {
    selected && Object.assign(this, selected);
  }

  get columns$(): Observable<NewTableColumn[]> {
    return this.columnsSubject.asObservable();
  }

  toServer(): ListWidgetSelectedDto {
    return {
      columns: this.columns.map(col => ({ key: col.key })),
      applications: this.applications.map(app => ({ id: app.value.id })),
      artifactTypes: this.artifactTypes.map(at => ({ id: at.value.id })),
      linkTypes: this.linkTypes.map(lt => ({ id: lt.value.id, meta: lt.meta })),
      linkFiltersNew: this.linkFiltersNew,
      folderFilter: this.folderFilter,
      dateFilters: this.dateFilters,
      userFilters: this.userFilters,
    };
  }

  setFromDto(selected: ListWidgetSelectedDto, options: any, applicationId: string): void {
    Promise.resolve().then(async () => {
      const { applications, artifactTypes, columns, linkTypes } = selected;

      this.artifactTypes = artifactTypes.map(dto => {
        const artifactType = dto instanceof SelectOption ? dto.value : options.artifactTypes.listMap[dto.id];
        return new SelectOption<string, NewArtifactType>(artifactType.name, artifactType);
      });

      this.linkTypes = linkTypes.map(dto => {
        const linkTypeId = dto instanceof SelectOption ? dto.value.id : dto.id;
        const resultOption = options.linkTypesOptions.find((linkOption: any) => linkOption.value.id === linkTypeId && linkOption.meta === dto.meta);
        return resultOption as SelectOption<string, LinkType, LinkDirection>;
      });

      if (applications?.length) {
        const apps: SelectOption<string, Application>[] = [];
        if (options.applications.list.length) {
          applications.forEach(dto => {
            const application = dto instanceof SelectOption ? dto.value : options.applications.listMap[dto.id];
            application && apps.push(new SelectOption<string, Application>(application?.name, application));
          });
        }
        this.applications = apps;
      } else {
        const application = options.applications.listMap[applicationId];
        this.applications = [new SelectOption<string, Application>(application.name, application)];
      }

      if (options.columns.list?.length) {
        this.setColumnsFromDto(columns, options.columns);
      } else {
        this.columnsDto = columns;
      }

      this.linkFiltersNew = selected.linkFiltersNew || {};
      this.folderFilter = selected.folderFilter;
      this.dateFilters = selected.dateFilters || {};
      this.userFilters = selected.userFilters || {};
    });
  }

  setColumnsFromDto(dto: { key: string }[], options: ListContainer<NewTableColumn>): void {
    const columns = dto.map(col => options.list.find(option => option.key === col.key)).filter(col => col) as NewTableColumn[];
    this.setColumns(columns);
  }

  setColumnsFromSavedDto(options: ListContainer<NewTableColumn>): void {
    if (this.columnsDto) {
      this.setColumnsFromDto(this.columnsDto, options);
      this.columnsDto = null;
    }
  }

  setColumns(columns: NewTableColumn[]): void {
    this.columns = columns;
    this.columnsSubject.next(columns);
  }
}

export interface ListInternalWidgetModelDto {
  listUiType: 'STANDARD';
  listType: ListWidgetType | null;
  selected: ListWidgetSelectedDto;
  settings: ListWidgetTableSettingsDto;
  state: Record<string, any>;
  linkingPopupDtoMap: Record<string, Record<string, string>>;
}

export interface ListWidgetSelectedDto {
  columns: { key: string }[];
  artifactTypes: { id: string }[];
  applications: { id: string }[];
  linkTypes: { id: string; meta: LinkDirection }[];
  linkFiltersNew: Record<LinkFilterEnum | string, LinkFilterNew>;
  folderFilter?: Record<FolderFilterEnum, string>;
  dateFilters: Record<string, AdvancedDateFilterObject>;
  userFilters: Record<string, UserFilterEntry>;
}

export interface LinkFilter {
  filter: LinkFilterEnum | null;
  linkFilterUrlParamKey?: string;
}

export interface LinkFilterNew {
  linkColumns: Record<string, LinkFilterEntry>;
}

export interface LinkFilterEntry {
  linkDirection: LinkDirection;
  linkFilterUrlParamKey?: string;
}
