import { Injectable } from '@angular/core';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { RuntimeStateNotificationService } from '../../shared/services/runtime-state-notification.service';
import { PictureWidgetComponent } from '../picture-widget.component';
import { PictureWidgetModel, PictureWidgetModelDto, PictureWidgetValue } from '../types/picture-widget.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageResponseDto } from '@api/models/page-response-dto';
import { SelectOption } from '@shared/types/shared.types';

@Injectable()
export class PictureWidgetService {
  c: PictureWidgetComponent;
  m: PictureWidgetModel;

  constructor(
      public readonly runtimeStateNotificationService: RuntimeStateNotificationService,
      private readonly widgetService: WidgetService,
      public readonly cache: NewCacheService,
  ) {}

  init(context: PictureWidgetComponent, dto?: PictureWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new PictureWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new PictureWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;

    this.m.options.sidebarList = this.widgetService.pageSidebars.getValue();
    this.widgetService.pageSidebars.subscribe(list => {
      this.m.options.sidebarList = list;
    });

    const pages = this.cache.data.pages.value?.map(dto => new Page(dto as PageResponseDto)) || [];
    this.m.options.pageOptions = pages.map(page => new SelectOption(page.name, page.id, { alias: page.alias }));
  }
}
