<ng-container *ngIf="isReadyToRender">
  <ng-container *ngIf="isLayoutMode; else advancedModeTemplate">
    <app-card-resizer
      (sizeChange)="onSizeChange($event)"
      *ngIf="m.advancedModeOpen | async"
      [size]="(cardWidthInAdvancedMode$ | async) || cardElement.offsetWidth"
      class="mb-3">
    </app-card-resizer>

    <article
      #cardElement
      (click)="onCardClick($event)"
      (resizeEnd)="onCardResizeEnd($event)"
      (resizing)="onCardResizing($event)"
      (mouseenter)="onMouseEnter()"
      (mouseleave)="onMouseLeave()"
      [cardAreaComponents]="cardAreaComponents"
      [class.clickable-card]="isClickable || m.settings.clickAction.isHandled"
      [enableGhostResize]="true"
      [isAdvancedModeOpen]="(m.advancedModeOpen | async)!"
      [ngClass]="resizeService.resizableCardPositionClass$ | async"
      [ngStyle]="(cardStylesForBreakpoint$ | async) | imageFilterStylesAdmin"
      [style.width.px]="cardWidthStyle$ | async"
      [validateResize]="resizeService.validateResize"
      appAreaContentItemsToolbar
      class="d-grid resize pseudo-opacity"
      mwlResizable>
      <ng-container *ngIf="m.advancedModeOpen | async">
        <span [resizeEdges]="{left: true}" class="resize__handle resize__handle--left" mwlResizeHandle></span>
        <span [resizeEdges]="{right: true}" class="resize__handle resize__handle--right" mwlResizeHandle></span>
      </ng-container>

      <ng-container *ngIf="isListMatrixCard; else cardWithContentDrop">
        <app-card-area
          (deleteWidget)="onWidgetDelete($event)"
          (downloadFile)="downloadFile($event)"
          (openAttributeSettings)="openAttributeSettings($event)"
          *ngFor="let area of m.areas; index as areaIndex"
          [area]="area"
          [artifactDto]="artifact"
          [attributeStyles]="m.settings.attributeStyles"
          [breakpoint]="(breakpointAccordingToMode$ | async)!"
          [class.card-area--highlighted]="m.advancedModeOpen | async"
          [class.d-none]="m.layoutPreviewMode"
          [columnsCount]="(m.columnsCount$ | async)!"
          [gridTemplateRows]="m.grid.gridTemplateRows.get(m.activeBreakpoint)!"
          [id]="m.idPrefix + 'cardArea' + areaIndex"
          [isAdvancedModeOpen]="(m.advancedModeOpen | async)!"
          [isInSidebar]="isInSidebar"
          [listItemArtifact]="listItemArtifact"
          [model]="m"
          [ngStyle]="area.content.styles | imageFilterStylesAdmin"
          [ownerId]="hash"
          [rowsCount]="(m.rowsCount$ | async)!"
          appCardAreaInheritCornerBorderRadius
          appCardAreaMinHeight
          class="card-area pseudo-opacity"
          style="word-break: normal">
        </app-card-area>
      </ng-container>

      <ng-template #cardWithContentDrop>
        <app-card-area
          (cdkDropListDropped)="dropIntoArea($event)"
          (deleteWidget)="onWidgetDelete($event)"
          (downloadFile)="downloadFile($event)"
          (openAttributeSettings)="openAttributeSettings($event)"
          *ngFor="let area of m.areas; index as areaIndex"
          [area]="area"
          [artifactDto]="artifact"
          [attributeStyles]="m.settings.attributeStyles"
          [breakpoint]="(breakpointAccordingToMode$ | async)!"
          [cdkDropListData]="area"
          [class.card-area--highlighted]="m.advancedModeOpen | async"
          [class.d-none]="m.layoutPreviewMode"
          [columnsCount]="(m.columnsCount$ | async)!"
          [gridTemplateRows]="m.grid.gridTemplateRows.get(m.activeBreakpoint)!"
          [id]="m.idPrefix + 'cardArea' + areaIndex"
          [isAdvancedModeOpen]="(m.advancedModeOpen | async)!"
          [isInSidebar]="isInSidebar"
          [listItemArtifact]="listItemArtifact"
          [model]="m"
          [ngStyle]="area.content.styles | imageFilterStylesAdmin"
          [ownerId]="hash"
          [rowsCount]="(m.rowsCount$ | async)!"
          [style.outline-color]="colors[areaIndex]"
          appCardAreaInheritCornerBorderRadius
          appCardAreaMinHeight
          cdkDropList
          class="card-area pseudo-opacity"
          style="word-break: normal">
        </app-card-area>
      </ng-template>

      <app-grid-layout-preview
        #layoutPreviewComponent
        *ngIf="m.layoutPreviewMode"
        [activeBreakpoint]="m.activeBreakpoint"
        [areas]="m.areas"
        [cells]="m.cells"
        [columnGap]="m.grid.columnGap.get(m.activeBreakpoint)!"
        [gridTemplateColumns]="m.grid.gridTemplateColumns.get(m.activeBreakpoint)!"
        [gridTemplateRows]="m.grid.gridTemplateRows.get(m.activeBreakpoint)!"
        [rowGap]="m.grid.rowGap.get(m.activeBreakpoint)!">
      </app-grid-layout-preview>
    </article>
  </ng-container>

  <ng-template #advancedModeTemplate>
    <div class="settings-container">
      <p-accordion (onOpen)="onTabOpen(cardAreaStyler)">
        <p-accordionTab [header]="'Widget settings' | translate" [selected]="m.tabState.widgetSettings">
          <app-card-widget-settings></app-card-widget-settings>
        </p-accordionTab>

        <p-accordionTab [(selected)]="m.tabState.contentStyles" [header]="'Card content styles' | translate">
          <app-card-area-styler
            #cardAreaStyler
            [areas]="m.areas"
            [attributeSettings]="m.settings.attributeStyles"
            [options]="m.options">
          </app-card-area-styler>
        </p-accordionTab>

        <p-accordionTab [header]="'Card styles' | translate">
          <p-tabView>
            <p-tabPanel header="{{ 'Standard' | translate }}">
              <app-page-element-styler [applicationId]="applicationId" [styles]="m.styles" (onChange)="onLayoutUpdate()" class="mb-3"></app-page-element-styler>
            </p-tabPanel>
            <p-tabPanel header="{{ 'On hover' | translate }}">
              <app-page-element-styler [applicationId]="applicationId" [styles]="m.hoverStyles" class="mb-3"></app-page-element-styler>
            </p-tabPanel>
            <p-tabPanel header="{{ 'On active' | translate }}">
              <app-page-element-styler [applicationId]="applicationId" [styles]="m.activeStyles" class="mb-3"></app-page-element-styler>
            </p-tabPanel>
          </p-tabView>

          <div class="grid">
            <div class="col-4">
              <h6>{{ 'Min-width' | translate }}</h6>
            </div>
            <div class="col-8">
              <input [(ngModel)]="m.styles.minWidth" pInputText type="text" />
            </div>

            <div class="col-4">
              <h6>{{ 'Max-width' | translate }}</h6>
            </div>
            <div class="col-8">
              <input [(ngModel)]="m.styles.maxWidth" pInputText type="text" />
            </div>
          </div>
        </p-accordionTab>

        <p-accordionTab [(selected)]="m.layoutPreviewMode" [header]="'Card layout' | translate">
          <app-grid-layout-generator
            (activeBreakpointChange)="onActiveBreakpointChange($event)"
            (layoutUpdate)="onLayoutUpdate()"
            [layoutHolder]="m">
          </app-grid-layout-generator>
        </p-accordionTab>
      </p-accordion>
    </div>
  </ng-template>
</ng-container>
