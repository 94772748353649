import { Background } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import {
  BackgroundAttachmentEnum,
  BackgroundPositionEnum,
  BackgroundRepeatEnum,
  BackgroundSizeEnum,
} from '@shared/components/image-pointer/types/image-pointer.types';
import { BackgroundTypeEnum } from '@widgets/shared/types/style.types';

export class PageStyles implements Background {
  backgroundType: BackgroundTypeEnum;
  backgroundColor: string;
  backgroundImage?: string;
  background: string;
  backgroundSize: string;
  backgroundPosition: string;
  backgroundRepeat: string;
  backgroundAttachment: string;
  backgroundOrigin: string;
  backgroundClip: string;
  backgroundBlendMode: string;
  backgroundOpacity: string;

  constructor(styles?: Partial<PageStyles>) {
    if (styles) {
      Object.assign(this, styles);
    }

    if (!this.backgroundRepeat) this.backgroundRepeat = BackgroundRepeatEnum.noRepeat;
    if (!this.backgroundSize) this.backgroundSize = BackgroundSizeEnum.auto;
    if (!this.backgroundPosition) this.backgroundPosition = BackgroundPositionEnum.centerCenter;
    if (!this.backgroundAttachment) this.backgroundAttachment = BackgroundAttachmentEnum.scroll;
    if (!this.backgroundOpacity) this.backgroundOpacity = '1';
  }

  static applyToBody(pageStyles: PageStyles): void {
    if (pageStyles) {
      const body = document.body;

      body.style.backgroundColor = pageStyles.backgroundColor === 'initial' || !pageStyles.backgroundColor ? '' : pageStyles.backgroundColor;
      body.style.backgroundClip = pageStyles.backgroundClip || '';
      body.style.backgroundBlendMode = pageStyles.backgroundBlendMode || '';

      body.style.setProperty('--background-image', pageStyles.background || '');
      body.style.setProperty('--background-size', pageStyles.backgroundSize || '');
      body.style.setProperty('--background-position', pageStyles.backgroundPosition || '');
      body.style.setProperty('--background-repeat', pageStyles.backgroundRepeat || '');
      body.style.setProperty('--background-attachment', pageStyles.backgroundAttachment || '');
      body.style.setProperty('--background-origin', pageStyles.backgroundOrigin || '');
      body.style.setProperty('--opacity', pageStyles.backgroundOpacity || '');
    }
  }
}
