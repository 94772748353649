<div class="main-rules-container full-height-container">
  <div class="rule-list">
    <div class="add-rule-element clickable" [class.active]="selectedRule && selectedRule === rule" (click)="addNewWorkflowRule()">
      <div>
        <i class="bi bi-plus-circle"></i>
        {{ 'WORKFLOWS.COMMON.ADD_NEW_RULE' | translate }}
      </div>
    </div>
    <p-tabView [activeIndex]="activeIndex" styleClass="workflow-tab-view" (onChange)="onTabChange($event.index)">
      <p-tabPanel header="{{ 'WORKFLOWS.COMMON.PAGE_RULES' | translate }}">
        <div class="rule-list-container">
          <div class="rules-title-header">
            <ng-container [ngTemplateOutlet]="ruleHeaders"></ng-container>
          </div>
          @if (pageRules?.length) {
            <div
              *ngFor="let rule of pageRules; trackBy: trackByRuleFn"
              class="clickable single-rule-container single-rule"
              [class.selected]="selectedRule?.id === rule.id"
              (click)="selectRule(rule)"
            >
              <span [class.inactive]="!rule.active">
                <div>{{ rule.name }}</div>
              </span>
              <span [class.inactive]="!rule.active">
                  {{ rule | getRuleDefaultArtifactType : artifactTypes }}
              </span>
              <span [pTooltip]="'WORKFLOWS.COMMON.GLOBAL_RULE' | translate"
                    [class.visibility-hidden]="!(rule | isGlobalWorkflowUsedByPage : pageId)"
                    class="global-rule-mark">
                G
              </span>
              <span class="priority-rule-mark"> {{ rule.priority }} </span>
            </div>
          }

          @if (!pageRules?.length && !selectedRule) {
            <div class="no-rules-placeholder">{{ 'WORKFLOWS.COMMON.NO_PAGE_RULES' | translate }}</div>
          }

          @if (selectedRule && selectedRule === rule) {
            <div class="clickable single-rule-container single-rule" [class.selected]="selectedRule === rule">
              <span>
                <i class="bi bi-plus-info"></i>
                @if (rule.name) {
                  {{ rule.name }}
                } @else {
                  [ <span class="new-rule-placeholder"> {{ 'WORKFLOWS.COMMON.ADDING_NEW_RULE' | translate }} . . . </span> ]
                }
              </span>
            </div>
          }

        </div>
      </p-tabPanel>

      <p-tabPanel header="{{ 'WORKFLOWS.COMMON.GLOBAL_RULES' | translate }}">
        <div class="rule-list-container">
          <div class="rules-title-header rules-title-header-global">
            <ng-container [ngTemplateOutlet]="ruleHeaders"></ng-container>
          </div>
          @if (globalRules?.length) {
            <div
              *ngFor="let rule of globalRules; trackBy: trackByRuleFn"
              class="clickable single-rule-container single-rule-container-global single-rule"
              [class.selected]="selectedRule?.id === rule.id"
              [class.inactive]="!rule.active"
              (click)="selectRule(rule)"
            >
              <span [class.inactive]="!rule.active">
                {{ rule.name }}
              </span>
              <span [class.inactive]="!rule.active">
                  {{ rule | getRuleDefaultArtifactType : artifactTypes }}
              </span>
            </div>
          } @else {
            <div class="no-rules-placeholder">{{ 'WORKFLOWS.COMMON.NO_GLOBAL_RULES' | translate }}</div>
          }
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>

  <div class="rule-detail">
    <app-workflows-rule
      #workflowRuleComponent
      *ngIf="selectedRule"
      [rule]="selectedRule"
      [isNew]="rule === selectedRule"
      [artifactTypes]="artifactTypes?.list"
      [attributes]="attributes"
      [dataTypes]="dataTypes"
      [linkTypes]="linkTypes"
      [applications]="applications"
      [users]="users"
      [pageWidgets]="pageWidgets"
      [editable]="selectedRule.ownerId === pageId"
      [ruleAdministrationType]="RuleAdministrationType.PAGE"
      (onSaveRule)="doSaveRule($event)"
      (onUseRule)="doUseRule($event)"
      (onDeleteRule)="doDeleteRule($event)"
    ></app-workflows-rule>
    <div class="rule-bottom-action-bar">
      <!-- <ng-container *ngIf="editable && hasValidTriggers()"> -->
      <ng-container *ngIf="activeIndex === 0 && selectedRule?.ownerId === pageId">
        <button
          *ngIf="rule === selectedRule"
          pButton
          label="{{ 'WORKFLOWS.COMMON.SAVE_RULE' | translate }}"
          class="p-button p-button-success workflows-action-btn"
          (click)="doSaveRule(selectedRule)"
        ></button>
        <button
          *ngIf="rule !== selectedRule"
          pButton
          label="{{ 'WORKFLOWS.COMMON.UPDATE_RULE' | translate }}"
          class="p-button p-button-warning workflows-action-btn"
          (click)="doSaveRule(selectedRule)"
        ></button>
        @if (selectedRule?.id) {
          <button
            pButton
            label="{{ 'WORKFLOWS.COMMON.DELETE_RULE' | translate }}"
            class="p-button p-button-danger workflows-action-btn"
            (click)="doDeleteRule(selectedRule)"
          ></button>
        }
      </ng-container>
      <button
        *ngIf="activeIndex === 1 && selectedRule"
        pButton
        label="{{ 'WORKFLOWS.COMMON.USE_RULE' | translate }}"
        class="p-button p-button-success workflows-action-btn"
        (click)="doUseRule(selectedRule)"
      ></button>
      <button
        *ngIf="selectedRule?.id && selectedRule | isGlobalWorkflowUsedByPage : pageId"
        pButton
        label="{{ 'WORKFLOWS.COMMON.UNUSE_RULE' | translate }}"
        class="p-button p-button-danger workflows-action-btn"
        (click)="doUnuseRule(selectedRule)"
      ></button>
    </div>
  </div>
</div>

<!-- FILTER DIALOGS -->
<p-overlayPanel #nameFilterOp [style]="{ 'max-width': '250px', width: '250px' }">
  <input [autofocus]="true" pInputText [(ngModel)]="nameFilterValue" class="mb-4 w-100">
  <ng-container
    [ngTemplateOutlet]="filterButtons"
    [ngTemplateOutletContext]="{ clear: clearNameFilter.bind(this), filter: filterByName.bind(this), dialog: nameFilterOp }"></ng-container>
</p-overlayPanel>

<p-overlayPanel #artifactTypeFilterOp [style]="{ 'max-width': '250px', width: '250px' }">
  <span class="dynamic-label">
    <p-multiSelect
      [(ngModel)]="artifactTypeFilterValue"
      [options]="artifactTypes.list | transformArrayToSelectOptions : 'name' : 'id'"
      styleClass="artifact-type-filter-selection"
      (onChange)="filterByArtifactType()">
    </p-multiSelect>
  </span>
  <ng-container
    [ngTemplateOutlet]="filterButtons"
    [ngTemplateOutletContext]="{ clear: clearArtifactTypeFilter.bind(this), filter: filterByArtifactType.bind(this), dialog: artifactTypeFilterOp }">
  </ng-container>
</p-overlayPanel>

<ng-template #filterButtons let-clear="clear" let-filter="filter" let-dialog="dialog">
  <div class="dialog-buttons">
    <p-button [label]="'Clear' | translate" severity="primary" outlined="true" (click)="clear(); dialog.hide();"></p-button>
    <p-button [label]="'Apply' | translate" severity="primary" (click)="filter(); dialog.hide()"></p-button>
  </div>
</ng-template>

<ng-template #ruleHeaders>
  <div>
    <span> Name </span>
    <span class="pi pi-filter clickable" [class.active]="nameFilterActive" (click)="nameFilterOp.toggle($event)"></span>
  </div>
  <div>
    <span> Artifact type </span>
    <span class="pi pi-filter clickable" [class.active]="artifactTypeFilterActive" (click)="artifactTypeFilterOp.toggle($event)"></span>
  </div>
</ng-template>
