import { Injectable } from '@angular/core';
import { TenantUpdateRequestDto } from '@api/models/tenant-update-request-dto';
import { TenantTenantService } from '@api/services';
import { CurrentTenantComponent } from '@private/pages/current-tenant/tenant/current-tenant.component';
import { CurrentTenantModel } from '@private/pages/current-tenant/tenant/types/current-tenant.types';
import { CoreService } from '@shared/core/services/core.service';
import { getDefaultSystemPublicPages } from '@shared/methods/system-public.pages.methods';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class CurrentTenantService extends CoreService<CurrentTenantComponent, CurrentTenantModel> {
  constructor(private readonly tenantTenantService: TenantTenantService) {
    super();
  }

  async setCurrentTenant(): Promise<void> {
    this.m.tenant = await firstValueFrom(this.tenantTenantService.tenantControllerGet());
    if (!this.m.tenant.systemPages) this.m.tenant.systemPages = getDefaultSystemPublicPages();
    if (!this.m.tenant.scriptModels) this.m.tenant.scriptModels = [];
  }

  async updateCurrentTenant(): Promise<void> {
    const { oslcRootServices, domain, registrationAllowed, systemPages, scriptModels } = this.m.tenant;
    try {
      const body: TenantUpdateRequestDto = { oslcRootServices, domain, registrationAllowed, systemPages: systemPages as any, scriptModels };

      await firstValueFrom(this.tenantTenantService.tenantControllerUpdate({ body }));
      await this.c.announcement.success('Successful tenant update');
    } catch (error) {
      await this.c.announcement.error('Unsuccessful tenant update');
    }
  }
}
