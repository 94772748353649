import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { DisplayAtEnumComponent } from './display-at-enum.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [DisplayAtEnumComponent],
  imports: [CommonModule, WidgetContainerModule, MultiSelectModule, FormsModule, TooltipModule],
  exports: [DisplayAtEnumComponent],
})
export class DisplayAtEnumModule {}
