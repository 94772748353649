import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomScriptSettingsModel } from '@shared/components/custom-script-settings/types/custom-script-settings.model';
import { ScriptsUtil } from '@shared/utils/scripts.util';
import { Button } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Ripple } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { CustomScriptModel, ScriptType } from '../../types/custom-script.types';

@Component({
  selector: 'app-custom-script-settings',
  standalone: true,
  imports: [
    CdkDrag,
    CdkDragHandle,
    CdkDropList,
    FloatLabelModule,
    InputTextModule,
    Ripple,
    TranslateModule,
    TooltipModule,
    FormsModule,
    InputTextareaModule,
    RadioButtonModule,
    CheckboxModule,
    DialogModule,
    Button,
  ],
  templateUrl: './custom-script-settings.component.html',
  styleUrl: './custom-script-settings.component.scss',
})
export class CustomScriptSettingsComponent {
  @Input({ required: true }) scriptModels: CustomScriptModel[];

  m = new CustomScriptSettingsModel();
  ScriptType = ScriptType;

  add(): void {
    this.scriptModels.push(new CustomScriptModel());
  }

  remove(index: number): void {
    this.scriptModels.splice(index, 1);
  }

  dropItem(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.scriptModels, event.previousIndex, event.currentIndex);
  }

  toggleEdit(scriptModel: CustomScriptModel): void {
    this.m.editingScriptId = this.m.editingScriptId === scriptModel.id ? '' : scriptModel.id;
  }

  showDialog() {
    this.m.showParsingDialog = true;
  }

  parseHtmlString() {
    this.scriptModels.push(...ScriptsUtil.parseScriptModelsFromHtmlString(this.m.htmlStringForParsing));
    this.m.showParsingDialog = false;
  }
}
