<span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>

<ng-container *ngIf="dataType.isText && !attribute.multipleValues">
  <textarea
    (blur)="onBlur()"
    [(ngModel)]="attr.value"
    (ngModelChange)="onChange()"
    [id]="'strField' + uniqueId"
    [placeholder]="placeholder"
    [style.min-height]="formatSettings.value.fieldStyles.minTextareaHeight"
    autoResize="autoResize"
    pInputTextarea
    rows="1"
    class="one-row-text-area"
  >
  </textarea>
  <app-artifact-attribute-form-field-label-with-icon
    [attribute]="attribute"
    [formatSettings]="formatSettings"
    [label]="label"
    [fieldHtmlId]="'strField' + uniqueId"
    [canContainPlaceholder]="true"
    [labelBehaviour]="labelBehaviour"
    [placeholder]="placeholder"
  >
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>
<ng-container *ngIf="dataType.isHtml && !attribute.multipleValues && !!uniqueId">
  <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
  <editor
    (onBlur)="onBlur()"
    [(ngModel)]="attr.value"
    (ngModelChange)="onChange()"
    [id]="'htmlField' + uniqueId"
    [init]="settings"
    apiKey="y0en5l9qmetfdo5lld6p5xc7xs39weexnhrzvdtoxzs1a7kr"
    class="editor-enabled"
    outputFormat="html"
  >
  </editor>
  <textarea [(ngModel)]="attr.value" [id]="'htmlFieldHidden' + uniqueId" autoResize="autoResize" class="display-none"
            pInputTextarea rows="1"> </textarea>
  <app-artifact-attribute-form-field-label-with-icon
    [attribute]="attribute"
    [formatSettings]="formatSettings"
    [label]="label"
    [fieldHtmlId]="'htmlFieldHidden' + uniqueId"
    [canContainPlaceholder]="true"
    [labelBehaviour]="labelBehaviour"
    [placeholder]="placeholder"
  >
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>

<ng-container *ngIf="(dataType.isText || dataType.isHtml) && attribute.multipleValues">
  <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
  <p-chips
    [(ngModel)]="attr.value"
    (ngModelChange)="onChange()"
    (onBlur)="onBlur()"
    [inputId]="'intFieldM' + uniqueId"
    [ngClass]="{ 'p-chips-filled': attr.value?.length }"
    [placeholder]="placeholder"
  >
  </p-chips>
  <app-artifact-attribute-form-field-label-with-icon
    [attribute]="attribute"
    [formatSettings]="formatSettings"
    [label]="label"
    [fieldHtmlId]="'intFieldM' + uniqueId"
    [canContainPlaceholder]="true"
    [labelBehaviour]="labelBehaviour"
    [placeholder]="placeholder"
  >
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>
