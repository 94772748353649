import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TextWidgetModelDto } from '@api/models';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { AnnouncementService } from '@shared/services/announcement.service';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';
import { TextWidgetModel, TextWidgetValue } from '@widgets/text-widget/types/text-widget.types';
import { DISABLED, IS_IN_SIDEBAR, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { WidgetsCoreComponent } from '../widgets-core/components/widgets-core.component';

@Component({
  selector: 'app-text-widget',
  templateUrl: './text-widget.component.html',
  styleUrls: ['./text-widget.component.scss'],
})
export class TextWidgetComponent extends WidgetsCoreComponent<TextWidgetValue> implements OnInit, AfterViewInit {
  settings: any;
  initialized = false;
  m: TextWidgetModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(WIDGET) public readonly widget: BlockPartWidget<TextWidgetValue>,
    @Inject(LABEL) public readonly label: string,
    @Inject(IS_LAYOUT_MODE) public readonly isLayoutMode: boolean,
    @Inject(DISABLED) public readonly disabled: boolean,
    @Inject(IS_IN_SIDEBAR) public readonly isInSidebar: boolean,
    private readonly cdr: ChangeDetectorRef,
    private readonly tinyMceHelper: TinyMceHelper,
  ) {
    super(route, router, announcement, elRef);
  }

  ngOnInit(): void {
    this.isLayoutMode ? this.layoutMode() : this.advancedMode();

    this.prepareContext(this.isLayoutMode && this.widget.value?.model ? (this.widget.value.model as TextWidgetModelDto) : null);
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initialized = true;
      this.cdr.markForCheck();
    });
  }

  layoutMode(): void {
    this.settings = this.tinyMceHelper.getTinyMceLayoutSettings();
  }

  advancedMode(): void {
    this.settings = this.tinyMceHelper.getTinyMceAdvancedSettings();
    this.settings.inline = false;
    this.settings.height = '500px';
    this.settings.sandbox_iframes = false;
  }

  onfocus(e: EventObj<FocusEvent>): void {
    if (this.isInSidebar) e.editor.editorContainer.classList.add('sidebar-tox-tinymce');
  }

  private prepareContext(dto: TextWidgetModelDto | null): void {
    if (!this.widget.value) this.widget.value = new TextWidgetValue(this.tinyMceHelper);
    if (dto) this.widget.value.model = new TextWidgetModel(this.tinyMceHelper, dto);
    if (!this.widget.value.model) this.widget.value.model = new TextWidgetModel(this.tinyMceHelper);
    this.m = this.widget.value.model;
  }
}
