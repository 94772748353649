import { Component, Inject, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LinkResponseDto, LinkTypeResponseDto, ListWidgetTypeResponseDto, PageBlockPartWidgetResponseDto } from '@api/models';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NewCoreListComponent } from '@shared/core/components/new-core-list.component';
import { LinkMethods } from '@shared/methods/link.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BaseFolderFilterService } from '@shared/services/filter/filter-types/base-folder-filter.service';
import { BaseNumericFilterService } from '@shared/services/filter/filter-types/base-numeric-filter.service';
import { ArtifactLinkService } from '@shared/services/links/artifact-link.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { NewArtifact } from '@shared/types/artifact.types';
import { Exception } from '@shared/types/exception.types';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';
import { ComplexFilterMetadata, NewTableColumn } from '@shared/types/table.types';
import { DateUtil } from '@shared/utils/date.util';
import { CreatingPopupModel } from '@widgets/link-popup/types/creating-popup.model';
import { LinkPopupSelected } from '@widgets/link-popup/types/link-popup-selected.types';
import { LinkPopupWidgetModel } from '@widgets/link-popup/types/link-popup.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ListWidgetHelper } from '@widgets/list-widget-new/services/list-widget.helper';
import { ListWidgetService } from '@widgets/list-widget-new/services/list-widget.service';
import { ArtifactGroupingService } from '@widgets/shared/components/artifact-list-table/services/grouping/artifact-grouping.service';
import { ListWidgetTableHelper } from '@widgets/shared/components/artifact-list-table/services/list-widget-table.helper';
import { GroupAttributeItem } from '@widgets/shared/components/artifact-list-table/types/list-widget-grouping.types';
import { LinkDialogOpenArguments } from '@widgets/shared/types/link-popup/link-dialog-open-arguments';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { RuntimeStateNotificationService } from '../shared/services/runtime-state-notification.service';

@Component({
  selector: 'app-link-popup',
  templateUrl: './artifact-link-dialog.component.html',
  styleUrls: ['./artifact-link-dialog.component.scss'],
  providers: [ListWidgetService, ListWidgetHelper],
})
export class ArtifactLinkDialogComponent extends NewCoreListComponent<NewArtifact> {
  @Input() dtoMap: Record<string, Record<string, ListWidgetTypeResponseDto>>;
  @Input() applicationId: string;
  @Input() successCb: (artifacts?: NewArtifact[]) => void | Promise<void>;
  @Input() boilerplateCb: (artifacts: NewArtifact[]) => void | Promise<void>;
  @ViewChild('table') table: Table;

  m: LinkPopupWidgetModel = new LinkPopupWidgetModel();
  creatingPopUpModel = new CreatingPopupModel();
  restrictions: Record<string, ArtifactTypeLinkRestriction[]> = {};

  queryParams: Params = {};

  constructor(
    dateUtil: DateUtil,
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    public readonly route: ActivatedRoute,
    public readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    protected readonly cache: NewCacheService,
    public readonly groupingService: ArtifactGroupingService,
    public readonly listWidgetTableHelper: ListWidgetTableHelper,
    public readonly helper: ListWidgetHelper,
    public readonly localStorageService: LocalStorageService,
    public readonly announcement: AnnouncementService,
    protected readonly baseNumericFilter: BaseNumericFilterService,
    protected readonly baseFolderFilter: BaseFolderFilterService,
    private readonly artifactLinkService: ArtifactLinkService,
  ) {
    super(cache, baseNumericFilter, baseFolderFilter, dateUtil);
  }

  get creatingPopUpLabel(): string {
    return `Create "${this.m.selected.artifactType?.name}"`;
  }

  setWidgetAsAdvancedMode(): void {
    this.m.isSettingsSidebarVisible = true;
  }

  onSettingsSidebarHide(): void {
    this.storeDto();
    this.m.isSettingsSidebarVisible = false;
  }

  async open(args: LinkDialogOpenArguments): Promise<void> {
    if (!this.m.isFirstLoad) await this.onInit();

    const { originalArtifact, linkTypeId, linkDirection, restriction, successCb, boilerplateCb } = args;
    const linkType = this.m.options.linkTypes.listMap[linkTypeId];
    successCb && (this.successCb = successCb);
    boilerplateCb && (this.boilerplateCb = boilerplateCb);

    this.creatingPopUpModel = new CreatingPopupModel();

    this.m.originalArtifact = originalArtifact;
    this.m.directionalLinkType = new DirectionalLinkType(linkDirection, linkType);
    this.m.restriction = restriction;
    this.m.setRelevantArtifactTypes();
    this.m.selected = new LinkPopupSelected();
    this.m.selected.artifactType = this.m.options.relevantArtifactTypes[0].value;
    this.m.selected.artifactTypes = [new SelectOption(this.m.selected.artifactType.name, this.m.selected.artifactType)];
    this.m.fromDto(this.dtoMap);
    this.m.options.setGroupCollapseOptions(this.m.settings.grouping.pagination);
    this.updateColumns();
    await this.initApplicableColumns();

    this.setPresentationalColumnsIfColumnsAreEmpty();
    await this.setRestrictions();

    this.loadCreateWidget();

    if (this.m.settings.grouping.groupingAttributes[0])
      this.groupingService.setGroupingHandler(this.m.hash, this.m.settings.grouping.groupingAttributes[0].value, this.m.options);

    if (this.m.state.table) this.localStorageService.set(this.m.hash, this.m.state.table);

    setTimeout(() => {
      this.m.displayModal = true;
      this.m.settings.showTable = true;
    });
  }

  close(): void {
    this.m.displayModal = false;
    this.m.settings.showTable = false;
  }

  initDateFilters(): void {
    if (this.table) {
      const keys = Object.keys(this.table.filters).filter(key => key.includes('date') || key.includes('time'));
      keys.forEach(key => {
        (this.table.filters[key] as any[]).forEach(filter => filter.value && (filter.value = new Date(filter.value)));
      });
    }
  }

  onSelectedColumnsChange(columns: NewTableColumn[]): void {
    this.m.selected.setColumns(columns);
  }

  async onArtifactTypeChange(): Promise<void> {
    this.m.settings.showTable = false;
    this.storeDto();
    this.m.reset();
    this.m.fromDto(this.dtoMap);
    this.updateColumns();
    await this.initApplicableColumns();
    this.m.selected.artifacts = [];
    this.m.selected.artifact = null;
    this.initDateFilters();
    this.setPresentationalColumnsIfColumnsAreEmpty();
    await this.setRestrictions();
    this.m.selected.artifactTypes = [new SelectOption(this.m.selected.artifactType.name, this.m.selected.artifactType)];
    this.localStorageService.remove(this.m.hash);

    if (this.m.state.table) this.localStorageService.set(this.m.hash, this.m.state.table);

    this.creatingPopUpModel.widget = null;
    this.loadCreateWidget();

    setTimeout(() => (this.m.settings.showTable = true), 100);
  }

  async create(): Promise<void> {
    try {
      if (this.m.originalArtifact?.id) {
        const successArtifacts: NewArtifact[] = [];
        let success = 0;
        let failed = 0;

        for await (const artifact of this.m.selected.artifacts) {
          const destinationArtifactId = this.m.directionalLinkType.isOutgoing ? artifact.id : this.m.originalArtifact?.id;
          const sourceArtifactId = this.m.directionalLinkType.isOutgoing ? this.m.originalArtifact?.id : artifact.id;
          await this.createLink(destinationArtifactId as string, sourceArtifactId as string).then(successed => {
            successed ? success++ : failed++;
            successArtifacts.push(artifact);
          });
        }

        this.successCb && (await this.successCb(successArtifacts));

        let message = `Created ${success} links`;
        if (failed) message += `, failed to create ${failed} ${failed === 1 ? 'link' : 'links'}`;

        await this.announcement.info(message);
      } else {
        this.boilerplateCb && (await this.boilerplateCb(this.m.selected.artifacts));
        this.successCb && (await this.successCb(this.m.selected.artifacts));
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.close();
    }
  }

  async createLink(destinationArtifactId: string, sourceArtifactId: string): Promise<boolean> {
    const linkTypeId = this.m.directionalLinkType.id;
    const newLinkRes = await lastValueFrom(this.artifactLinkService.createLink$(linkTypeId, destinationArtifactId, sourceArtifactId, true, this.m.hash));
    let newLinkDto: LinkResponseDto | null = null;
    if (newLinkRes.meta.errors?.[0]) {
      this.announcement.error(newLinkRes.meta.errors[0].errorMessage);
    }
    newLinkDto = newLinkRes.data[0];
    return Boolean(newLinkDto);
  }

  onGroupAttributeChangeCb(groupAttribute: GroupAttributeItem | undefined): void {
    groupAttribute && this.groupingService.setGroupingHandler(this.m.hash, groupAttribute, this.m.options);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transformFiltersToComplexData(filters: Record<string, FilterMetadata[]>): Record<string, ComplexFilterMetadata> {
    return {};
  }

  storeDto(): void {
    if (this.isPreviewMode) return;
    if (!this.m.selected.artifactTypes[0]) return;

    if (!this.dtoMap[this.m.directionalLinkType.idAndDirection]) this.dtoMap[this.m.directionalLinkType.idAndDirection] = {};

    const tableState = this.localStorageService.get(this.m.hash);
    if (tableState) {
      if (tableState.selection) tableState.selection = [];
      this.m.state.table = tableState;
    }

    this.dtoMap[this.m.directionalLinkType.idAndDirection][this.m.selected.artifactTypes[0].value.id] = this.m.toServer();
  }

  onLinkingPopupHide() {
    this.storeDto();
    this.close();
    this.m.reset();
    this.m.selected = new LinkPopupSelected();
    localStorage.removeItem(this.m.ids.hash);
  }

  async openCreatePopUp(): Promise<void> {
    this.loadCreateWidget();
    this.creatingPopUpModel.visible = true;
  }

  protected async onInit(): Promise<void> {
    super.onInit();
    this.helper.init(this);
    await this.m.options.init(this.cache);
    this.m.ids = { hash: this.m.hash, applicationId: this.applicationId };
    this.m.isFirstLoad = false;
    this.subscribeToCreateEvent();
    this.subscribeToQueryParamsChange();
  }

  private subscribeToQueryParamsChange(): void {
    this.registerSubscription(this.route.queryParams.subscribe(params => (this.queryParams = params)));
  }

  private subscribeToCreateEvent(): void {
    this.registerSubscription(
      this.runtimeStateNotificationService.events$.subscribe(event => {
        if (
          event.isOfTypeCreateArtifact &&
          event.data.artifactTypeId === this.m.selected.artifactType?.id &&
          !this.creatingPopUpModel.alreadyCreatedArtifactMap.has(event.data?.id)
        ) {
          this.m.settings.showTable = false;
          this.creatingPopUpModel.alreadyCreatedArtifactMap.set(event.data.id, true);
          this.creatingPopUpModel.visible = false;
          setTimeout(() => (this.m.settings.showTable = true), 100);
        }
      }),
    );
  }

  private async loadCreateWidget(): Promise<void> {
    if (!this.m.selected.artifactType?.defaultWidgets?.artifactWidgetTemplateId) return;

    const templateDto = await this.cache.data.templates.getAsync(this.m.selected.artifactType.defaultWidgets.artifactWidgetTemplateId!).catch(e => {
      console.error(
        new Exception({
          name: 'LinkingPopUpCreateTemplateLoad',
          message: 'Some error occurred when trying to load widget template for creating artifact from Linking PopUp',
          originalEvent: e,
        }),
      );
      return null;
    });

    if (!templateDto) return;

    const widgetDto = await this.cache.data.widgets.getAsync((templateDto.template as PageBlockPartWidgetResponseDto).widgetId!).catch(e => {
      console.error(
        new Exception({
          name: 'LinkingPopUpCreateArtifactWidgetLoad',
          message: 'Some error occurred when trying to load widget for creating artifact from Linking PopUp',
          originalEvent: e,
        }),
      );
      return null;
    });

    if (!widgetDto) return;

    this.creatingPopUpModel.widget = new BlockPartWidget(widgetDto);
  }

  private async initApplicableColumns(): Promise<void> {
    this.m.options.applicableColumns = await this.generateApplicableColumns();
  }

  private async generateApplicableColumns(): Promise<NewTableColumn[]> {
    if (!this.m.selected.artifactType) return [];

    const relevantLinkTypes = (this.cache.data.linkTypes.value as LinkTypeResponseDto[])!.filter(dto =>
      (dto.restrictions || []).some(
        restriction =>
          restriction.sourceArtifactTypeId === this.m.selected.artifactType.id || restriction.destinationArtifactTypeId === this.m.selected.artifactType.id,
      ),
    );
    const restrictions = LinkMethods.getLinkRestrictionsForArtifactType(this.m.selected.artifactType.id, relevantLinkTypes);

    return this.m.options.columns.list.filter(column => {
      if (!column.meta.isLink) return true;
      else {
        if (!restrictions?.length) return false;

        const [key, direction] = column.key.split('_');
        return restrictions.some(restriction => restriction.linkType?.value === key && restriction.linkType.meta === direction);
      }
    });
  }

  private updateColumns(): void {
    const columns = this.listWidgetTableHelper.generateOptionColumns(this.m);
    this.m.options.columns.setList(columns, 'key');
    this.m.selected.setColumnsFromSavedDto(this.m.options.columns);
  }

  private setPresentationalColumnsIfColumnsAreEmpty() {
    if (!this.m.selected.columns.length) {
      this.m.selected.setColumns(this.m.selected.artifactType.primaryAttributes.map(id => this.m.options.columns.listMap[id]).filter(Boolean));
    }
  }

  private async setRestrictions(): Promise<void> {
    this.restrictions = await LinkMethods.getGroupedLinkRestrictions(this.m.options.artifactTypes.list, this.m.options.linkTypes.list);
  }
}
