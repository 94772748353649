export class MenuWidgetWorkflowValueItem {
	key: string;
	type?: WorkflowDataSourceTypes = WorkflowDataSourceTypes.static;
	valueType: WorkflowValueType;
	value?: string = '';
	isOptional?: boolean;

	constructor(dto: MenuWidgetWorkflowValueItem) {
		dto && Object.assign(this, dto);
	}

	toServer(): MenuWidgetWorkflowValueItemDto {
		const dto: MenuWidgetWorkflowValueItemDto = {
			key: this.key,
			valueType: this.valueType,
		} as MenuWidgetWorkflowValueItemDto;
		this.type && (dto.type = this.type);
		this.value && (dto.value = this.value);
		this.isOptional && (dto.isOptional = this.isOptional);
		return dto;
	}

	get isArray(): boolean {
		return this.valueType === WorkflowValueType.array;
	}

	get isString(): boolean {
		return this.valueType === WorkflowValueType.string;
	}

	get isUrlType(): boolean {
		return this.type === WorkflowDataSourceTypes.urlKey;
	}

	get isVariableType(): boolean {
		return this.type === WorkflowDataSourceTypes.variable;
	}
}

export class MenuWidgetWorkflowValueItemDto {
	key: string;
	type?: WorkflowDataSourceTypes;
	valueType: WorkflowValueType;
	value?: string = '';
	isOptional: boolean;
}

export type WfItem = { [key: string]: Array<'ARRAY' | 'STRING' | 'OPTIONAL' | 'REQUIRED'> };

export enum WorkflowDataSourceTypes {
	static = 'static',
	urlKey = 'urlKey',
	variable = 'variable',
}

export enum WorkflowValueType {
	string= 'string',
	array= 'array',
}
