<div class="artifact-widget">
  <div *ngIf="!isLayoutMode" class="artifact-widget_settings settings">
    <app-artifact-widget-type-picker [m]="m" [onArtifactTypeChange]="onArtifactTypeChange.bind(this)">
    </app-artifact-widget-type-picker>

    <div
      *ngIf="m?.options && m?.settings && (m.settings.widgetType | shouldShowArtifactWidgetSettings : m.selected.linkType?.value)"
      class="container settings_form"
      id="settings_form"
    >
      <app-artifact-widget-item-picker
        *ngIf="m?.selected.artifactType || (m.settings.widgetType | isLoginWidgetType) || (m.settings.widgetType | isRecoverPasswordWidgetType)"
        [model]="m"
        [onItemSelect]="onItemSelectCb.bind(this)" [queryParams]="urlParams">
      </app-artifact-widget-item-picker>

      <p-accordion
        *ngIf="m.selected.artifactType || (m.settings.widgetType | isLoginWidgetType) || (m.settings.widgetType | isRecoverPasswordWidgetType)">
        <p-accordionTab [(selected)]="isWidgetSettingsTabSelected" header="Widget settings">
          <app-artifact-widget-settings
            [artifactType]="m.selected.artifactType"
            [model]="m"
            [saveButtonOptions]="m.options.saveButton"
            [saveAndNotifyButtonOptions]="m.options.saveAndNotifyButton"
            [widgetSettings]="m.settings"
            (onChangeUrlKey)="setFolderPath()"
          >
          </app-artifact-widget-settings>
        </p-accordionTab>

        <p-accordionTab [(selected)]="isFormItemSettingsTabSelected" header="Form item settings">
          <app-artifact-widget-form-item-settings [model]="m"
                                                  [hash]="widget.id"></app-artifact-widget-form-item-settings>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <div #formDiv *ngIf="isLayoutMode" [ngStyle]="m?.settings?.styles">
    <app-artifact-widget-form
      #form
      *ngIf="m?.form?.length && !m?.isFirstLoad && !isLoadingData"
      [applicationId]="applicationId"
      [changeFormFocus]="changeFormFocus.bind(this)"
      [hash]="widget.id"
      [model]="this.m"
      [onLinkDialogOpen]="onLinkDialogOpen.bind(this)"
      [ids]="ids"
      [resetConfigurableSettings]="resetConfigurableSettingsToDefault.bind(this)"
    >
    </app-artifact-widget-form>
    <div #spinner [ngClass]="{ hidden: !isLoadingData }" class="spinner loader-custom">
      <div class="loader-custom__wrapper">
        <span class="pi pi-spin pi-spinner loader-custom__wheel"></span>
      </div>
    </div>
  </div>

  <app-link-popup #linkDialog [applicationId]="ids.applicationId" [dtoMap]="m.linkingPopupDtoMap"></app-link-popup>
</div>
