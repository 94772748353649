import { PageBlockPartWidgetResponseDto } from '@api/models/page-block-part-widget-response-dto';
import { PageSectionRequestDto } from '@api/models/page-section-request-dto';
import { PageSectionResponseDto } from '@api/models/page-section-response-dto';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageElementTypes } from '@private/pages/page-management/page-builder-graphical/types/page-element-types';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { PageSectionHide } from '@private/pages/page-management/page-builder-graphical/types/page-section-hide';
import { PageSectionStyles } from '@private/pages/page-management/page-builder-graphical/types/page-section-styles';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';
import { RowLocation } from '@private/pages/page-management/page-builder-graphical/types/row-location';
import { SidebarModalWidgetModel } from '@widgets/sidebar-modal-widget/types/sidebar-modal.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';

function itemByIndexOrLast<T>(array: T[], index: number): T | undefined {
  return array[index] || array[array.length - 1];
}

export class PageSection {
  modalId: string | null;
  index: number;
  innerIndex: number;
  pageElementType: PageElementTypes = PageElementTypes.section;

  constructor(
    public rows: PageRow[] = [],
    public templateId: string | null = null,
    public styles: PageSectionStyles = new PageSectionStyles(),
    public templateName: string | null = null,
    public sectionHide: PageSectionHide = new PageSectionHide(),
    public htmlId?: string,
  ) {}

  get requestDto(): PageSectionRequestDto {
    return {
      templateId: this.templateId,
      rows: this.templateId ? null! : this.rows.map((row: PageRow) => row.requestDto),
      styles: this.templateId ? {} : this.styles,
      htmlId: this.htmlId,
      sectionHide: this.sectionHide,
    };
  }

  get templateUpdateDto(): PageSectionRequestDto {
    return {
      templateId: null,
      rows: this.rows.map((row: PageRow) => row.requestDto),
      styles: this.styles,
      htmlId: this.htmlId,
      sectionHide: this.sectionHide,
    };
  }

  get copyDto(): PageSectionRequestDto {
    return {
      templateId: null,
      rows: this.rows.map((row: PageRow) => row.requestDto),
      styles: this.styles,
      htmlId: undefined,
      sectionHide: new PageSectionHide(this.sectionHide),
    };
  }

  get isTemplate(): boolean {
    return !!this.templateId;
  }

  get blocks(): PageBlock[] {
    return this.rows.reduce((blocks: PageBlock[], row: PageRow) => [...blocks, ...row.blocks], []);
  }

  get parts(): PageBlockPart[] {
    return this.rows.reduce((parts: PageBlockPart[], row: PageRow) => [...parts, ...row.parts], []);
  }

  get partsWithWidgets(): PageBlockPart[] {
    return this.rows.reduce((parts: PageBlockPart[], row: PageRow) => [...parts, ...row.parts.filter(({ widget }: PageBlockPart) => !!widget)], []);
  }

  static fromTemplateDto({ template, id, name }: TemplateResponseDto): PageSection {
    const sectionDto = template as PageSectionResponseDto;
    const rows = sectionDto.rows?.map(PageRow.fromDto);
    return new PageSection(rows, id, new PageSectionStyles(sectionDto.styles), name, sectionDto.sectionHide, (template as any).htmlId);
  }

  setTemplateDto(dto: TemplateResponseDto): void {
    const sectionDto = dto.template as PageSectionResponseDto;

    this.rows = sectionDto.rows?.map(PageRow.fromDto) || [];
    this.styles = new PageSectionStyles(sectionDto.styles);
    this.templateName = dto.name;
    if (sectionDto.sectionHide) this.sectionHide = sectionDto.sectionHide;
  }

  getPartsWithWidgetToLoad(innerWidgetsOnly = false): PageBlockPart[] {
    return this.rows.reduce((parts: PageBlockPart[], row: PageRow) => {
      row.blocks.forEach((block: PageBlock) => {
        block.parts.forEach((part: PageBlockPart) => {
          if (!innerWidgetsOnly && part.widget?.id) {
            parts.push(part);
          }
          if (innerWidgetsOnly && part.widget?.code === WidgetType.sidebarModal) {
            (part.widget.value.model as SidebarModalWidgetModel).page.sections = part.widget.value.model.page.sections?.map((s: PageSection) => {
              const section = new PageSection(
                s.rows.map(
                  row =>
                    new PageRow(
                      row.blocks.map(
                        block =>
                          new PageBlock(
                            block.parts.map(part => {
                              part.widget && !part.widget.id && (part.widget = null);
                              const newPart = new PageBlockPart(part.widget, part.styles);
                              newPart.widget?.id && parts.push(newPart);
                              return newPart;
                            }),
                          ),
                      ),
                      row.layout,
                      row.templateId,
                      row.styles,
                      row.rowHide,
                    ),
                ),
                s.templateId,
                s.styles,
                null,
                s.sectionHide,
                s.htmlId,
              );
              return section;
            });
          }
          if (innerWidgetsOnly && part.widget?.code === WidgetType.sidebar) {
            part.widget.value.model.parts = part.widget.value.model.parts?.map((innerPart: PageBlockPart) => {
              const widgetId = (innerPart as any).widgetId || innerPart.widget?.id;
              const { templateId, templateName } = innerPart.widget || {};
              const widget = new BlockPartWidget({ widgetId, templateId } as PageBlockPartWidgetResponseDto);
              templateName && (widget.templateName = templateName);

              const newPart = new PageBlockPart(widget);
              (newPart.widget as BlockPartWidget).id = widgetId;

              parts.push(newPart);
              return newPart;
            });
          }
        });
      });
      return parts;
    }, []);
  }

  getPartsWithCardWidgetToLoad(): PageBlockPart[] {
    return this.getPartsWithWidgetToLoad().filter(({ widget }: PageBlockPart) => widget?.code === WidgetType.card);
  }

  getWidgetsWithTemplateToLoad(isSidebarOnly = false): BlockPartWidget[] {
    return this.rows.reduce((widgets: BlockPartWidget[], row: PageRow) => {
      row.blocks.forEach((block: PageBlock) => {
        block.parts.forEach(({ widget }: PageBlockPart) => {
          if (!isSidebarOnly && widget?.templateId) {
            widgets.push(widget);
          }

          if (isSidebarOnly && widget?.code === WidgetType.sidebarModal) {
            widget.value.model.page.sections = widget.value.model.page.sections?.map((s: PageSection) => {
              const section = new PageSection(
                s.rows.map(
                  row =>
                    new PageRow(
                      row.blocks.map(block => new PageBlock(block.parts.map(part => new PageBlockPart(part.widget, part.styles)))),
                      row.layout,
                      row.templateId,
                      row.styles,
                      row.rowHide,
                    ),
                ),
                s.templateId,
                s.styles,
                null,
                s.sectionHide,
                s.htmlId,
              );

              section.partsWithWidgets?.forEach((innerPart: PageBlockPart) => {
                const widgetId = (innerPart as any).widget?.widgetId;
                const templateId = (innerPart as any).widget?.templateId;

                const data: PageBlockPartWidgetResponseDto = widgetId
                  ? ({ widgetId } as PageBlockPartWidgetResponseDto)
                  : ({ templateId } as PageBlockPartWidgetResponseDto);

                const widget = new BlockPartWidget(data);
                innerPart.widget = widget;
                widgets.push(widget);
              });

              return section;
            });
          }

          if (isSidebarOnly && widget?.code === WidgetType.sidebar) {
            widget.value.model.parts = widget.value.model.parts?.map((innerPart: PageBlockPart) => {
              const widgetId = (innerPart as any).widgetId;
              const templateId = (innerPart as any).templateId;

              const data: PageBlockPartWidgetResponseDto = widgetId
                ? ({ widgetId } as PageBlockPartWidgetResponseDto)
                : ({ templateId } as PageBlockPartWidgetResponseDto);
              const widget = new BlockPartWidget(data);
              innerPart.widget = widget;
              widgets.push(widget);
              return innerPart;
            });
          }
        });
      });

      return widgets;
    }, []);
  }

  removeIdsForReuse(removeHtmlIds = false): void {
    this.templateId = null;
    this.templateName = null;
    removeHtmlIds && (this.htmlId = undefined);
    this.rows.forEach((row: PageRow) => row.removeIdsForReuse(removeHtmlIds));
  }

  deleteRow({ rowIndex }: Pick<RowLocation, 'rowIndex'>): void {
    this.rows.splice(rowIndex, 1);
  }

  deleteBlockPart({ rowIndex, blockIndex, partIndex }: Omit<PartLocation, 'sectionIndex'>): void {
    const row = this.rows[rowIndex];
    row.deleteBlockPart({ blockIndex, partIndex });
    this.deleteRowIfEmpty({ rowIndex });
  }

  redesignByScheme(scheme: string[]): void {
    const newRows = this.getRowsFromScheme(scheme);
    this.pastePartsWithWidgetsIntoRedesignedRows(newRows);

    this.rows = newRows;
  }

  private deleteRowIfEmpty({ rowIndex }: Pick<RowLocation, 'rowIndex'>): void {
    const row = this.rows[rowIndex];

    if (!row.blocks.length) {
      this.deleteRow({ rowIndex });
    }
  }

  private getRowsFromScheme(scheme: string[]): PageRow[] {
    return scheme.map((row: string) => {
      const layout = row.split('+').filter(item => !!item);
      const blocks = layout.map(() => new PageBlock([new PageBlockPart()]));

      return new PageRow(blocks, layout);
    });
  }

  private pastePartsWithWidgetsIntoRedesignedRows(redesignedRows: PageRow[]): void {
    this.rows.forEach((row: PageRow, rowIndex: number) => {
      const redesignedRow = itemByIndexOrLast(redesignedRows, rowIndex)!;

      row.blocks.forEach((block: PageBlock, blockIndex: number) => {
        if (block.parts.some(({ widget }: PageBlockPart) => !!widget)) {
          const redesignedBlock = itemByIndexOrLast(redesignedRow.blocks, blockIndex)!;

          redesignedBlock.parts.forEach((part: PageBlockPart) => part.widget?.saveStateBeforeRelocation());
          block.parts.forEach((part: PageBlockPart) => {
            part.widget?.code !== WidgetType.sidebarModal && part.widget?.saveStateBeforeRelocation();
          });

          redesignedBlock.parts = [...redesignedBlock.parts, ...block.parts];
        }
      });
    });
  }
}
