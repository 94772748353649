export enum DefaultElvisActions {
  logout = 'logout',
  goToProfile = 'goToProfile',
  removeQueryParamsFromThisWidget = 'removeQueryParamsFromThisWidget',
  removeAllQueryParams = 'removeAllQueryParams',
  customEvent = 'customEvent',
  doNothing = 'Do nothing',
}

export enum MenuWidgetItemActions {
  beWfTrigger = 'beWfTrigger',
}
