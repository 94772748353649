import { inject, Pipe, PipeTransform } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { PageHelper } from '@shared/helpers/page-helper';
import { ImageMethods } from '@shared/methods/image.methods';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';

@Pipe({
  name: 'imageFilterStyles',
})
export class ImageFilterStylesPipe implements PipeTransform {
  private readonly apiConfiguration = inject(ApiConfiguration);
  private readonly authorizationService = inject(AuthorizationService);
  private readonly pageHelper = inject(PageHelper);

  internalImageIdToUrlPipe(imageFileId: string): string {
    if (!imageFileId) return '';

    let token = this.authorizationService.getToken;
    if (!token || this.pageHelper.usePublicToken) token = this.authorizationService.getAnonymousToken;

    let url = `url(${ImageMethods.getUrlFromImageFileId(imageFileId, this.apiConfiguration.rootUrl, token)})`;
    return url;
  }

  transform(styles: Partial<StylesDto>): Partial<StylesDto> {

    delete styles.background;
    let convertedStyles = { ...styles };

    if (styles.backgroundImage) {
      if (!ImageMethods.checkIsExternalUrl(styles.backgroundImage)) {
        styles.backgroundImage = this.internalImageIdToUrlPipe(styles.backgroundImage);
      }

      if(styles.backgroundOpacity) {

        convertedStyles = {
          ...styles,
          '--background-repeat': styles?.backgroundRepeat,
          '--background-size': styles?.backgroundSize,
          '--background-position': styles?.backgroundPosition,
          '--background-attachment': styles?.backgroundAttachment,
          '--background-image': styles?.backgroundImage,
          '--opacity': styles?.backgroundOpacity || '1',
        };
      }
    }

    let stylesMap = Object.entries(convertedStyles)
      .filter(([key, value]) =>
        value !== undefined &&
        value !== null &&
        value !== '' &&
        key !== 'backgroundImage' &&
        key !== 'background')
      .reduce(
        (acc, [key, value]) => {
          const kebabKey = this.toKebabCase(key);
          acc[kebabKey as string] = value as string;
          return acc;
        },
        {} as { [key: string]: string },
      );

    return stylesMap;
  }

  private toKebabCase(str: string): string {
    return str.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`);
  }
}
