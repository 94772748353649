import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ArtifactLinkResponseDto, LinkTypeResponseDto } from '@api/models';
import { TenantLinkTypeService } from '@api/services';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { WorkflowRuleConditionComponent } from '@workflows/components/rule/condition';
import { RuleUtilsService, WorkflowActionFactory, WorkflowTriggerFactory } from '@workflows/services';
import { RuleAdministrationType } from '@workflows/shared';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import {
  ImplementedWorkflowOwnerType,
  RuleTriggerType,
  WorkflowAction,
  WorkflowActionType,
  WorkflowOwnerType,
  WorkflowRule,
  WorkflowTrigger,
  WorkflowType,
} from '@workflows/types';
import { RuleConditionGroup } from '@workflows/types/conditions/rule-condition-group';
import { WorkflowTriggerManual } from '@workflows/types/triggers/trigger-manual';
import { map, Observable, shareReplay } from 'rxjs';

@Component({
  selector: 'app-workflows-rule',
  templateUrl: './workflow-rule.component.html',
  styleUrls: ['./workflow-rule.component.scss', '../workflow-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowRuleComponent implements OnInit, OnChanges {
  @Input() rule: WorkflowRule;
  @Input() isNew: boolean;
  @Input() artifactTypes: NewArtifactType[];
  @Input() attributes: ListContainer<NewAttribute> = new ListContainer();
  @Input() dataTypes: ListContainer<NewDataType>;
  @Input() linkTypes: ListContainer<LinkType>;
  @Input() pages: ListContainer<Page> = new ListContainer();
  @Input() pageWidgets: BlockPartWidget[] = [];
  @Input() applications: ListContainer<NewApplication> = new ListContainer();
  @Input() users: ListContainer<ArtifactLinkResponseDto> = new ListContainer();
  @Input() editable: boolean;
  @Input() ruleAdministrationType: RuleAdministrationType;

  @Output() onUseRule: EventEmitter<WorkflowRule> = new EventEmitter();
  @Output() onSaveRule: EventEmitter<WorkflowRule> = new EventEmitter();
  @Output() onDeleteRule: EventEmitter<WorkflowRule> = new EventEmitter();

  @ViewChild('condition') condition: WorkflowRuleConditionComponent;
  @ViewChild('ruleNameInput') ruleNameInput: ElementRef;

  selectableActions: WorkflowAction[];
  defaultArtifactType?: NewArtifactType;
  linkableArtifactTypes$: Observable<NewArtifactType[]> | undefined;

  ruleTypes: WorkflowType[];
  ruleOwnerTypes: WorkflowOwnerType[];
  ruleOwnerOptions: (NewArtifactType | LinkType | Page)[];
  ruleUsedInOptions: (NewArtifactType | LinkType | Page)[];
  OwnerType = WorkflowOwnerType;

  private validTrigger?: WorkflowTrigger;

  constructor(
    private triggerFactory: WorkflowTriggerFactory,
    private actionFactory: WorkflowActionFactory,
    private tenantLinkTypeService: TenantLinkTypeService,
    private ruleUtilsService: RuleUtilsService,
    private readonly conditionConverter: RuleConditionValueConverterService,
  ) {
    this.ruleTypes = Object.values(WorkflowType);
    this.ruleOwnerTypes = Object.values(WorkflowOwnerType).filter(type => ImplementedWorkflowOwnerType[type]);
  }

  ngOnInit(): void {
    this.initiateOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rule?.currentValue) {
      this.onArtifactTypeChange(this.rule.defaultArtifactTypeId);
      this.updateValidTrigger();
    }
  }

  onRuleTypeChange(type: WorkflowType): void {
    if (type === WorkflowType.be) {
      this.ruleOwnerTypes = [WorkflowOwnerType.ARTIFACT, WorkflowOwnerType.LINK, WorkflowOwnerType.CUSTOM];
    } else {
      this.ruleOwnerTypes = [WorkflowOwnerType.PAGE];
    }
  }

  onOwnerTypeChange(type: WorkflowOwnerType): void {
    this.initiateOptions(type);

    this.rule.ownerId = null;
    this.rule.usedIn = [];

    if (type === WorkflowOwnerType.CUSTOM) {
      this.rule.triggers = [this.triggerFactory.getWorkflowTrigger(RuleTriggerType.MANUAL)];
    }

    if (type === WorkflowOwnerType.CUSTOM || type === WorkflowOwnerType.LINK) {
      this.onTriggerPropertyUpdate();
    }

    this.updateValidTrigger();
  }

  onOwnerChange(ownerId: string): void {
    if (
      this.rule.ownerType === WorkflowOwnerType.ARTIFACT ||
      this.rule.ownerType === WorkflowOwnerType.PAGE ||
      this.rule.ownerType === WorkflowOwnerType.LINK
    ) {
      this.rule.usedIn = ownerId ? [ownerId] : null;
    }
  }

  onGlobalChange(isGlobal: boolean): void {
    if (!isGlobal && [WorkflowOwnerType.ARTIFACT, WorkflowOwnerType.LINK, WorkflowOwnerType.PAGE].includes(this.rule.ownerType)) {
      this.rule.ownerId && (this.rule.usedIn = this.rule.ownerId ? [this.rule.ownerId] : null);
    }
  }

  initiateOptions(type: WorkflowOwnerType = this.rule.ownerType): void {
    if (type === WorkflowOwnerType.ARTIFACT) {
      this.ruleOwnerOptions = this.ruleUsedInOptions = this.artifactTypes;
    } else if (type === WorkflowOwnerType.PAGE) {
      this.ruleOwnerOptions = this.ruleUsedInOptions = this.pages.list;
    } else if (type === WorkflowOwnerType.LINK) {
      this.ruleOwnerOptions = this.ruleUsedInOptions = this.linkTypes.list;
    } else if (type === WorkflowOwnerType.CUSTOM) {
      this.ruleOwnerOptions = this.ruleUsedInOptions = [];
    }

    // TESTING PURPOSES ONLY
    // this.predefineData();
  }

  predefineData(): void {
    this.defaultArtifactType = this.artifactTypes.find(at => at.id === '65d8da754ae03b94af4abd30');
    this.rule.triggers = [this.triggerFactory.getWorkflowTrigger(RuleTriggerType.MANUAL)];
    this.rule.actions = [this.actionFactory.getWorkflowAction(WorkflowActionType.SCHEDULE_WORKFLOW)];
  }

  onTriggerTypeUpdate(triggerType: RuleTriggerType, trigger: WorkflowTrigger) {
    const newTrigger = this.triggerFactory.getWorkflowTrigger(triggerType);
    this.rule.triggers = this.rule.triggers.map(item => (item === trigger ? newTrigger : item));
    this.updateValidTrigger();
    if (this.ruleUtilsService.isLinkSpecificTriggerType(triggerType)) {
      this.doInitOnLinkTrigger();
    }
    this.onTriggersUpdate();
  }

  onTriggerPropertyUpdate() {
    this.updateValidTrigger();
    if (this.validTrigger && !this.rule.actions.length) {
      this.addNewAction();
    }
    this.onTriggersUpdate();
  }

  onTriggersUpdate(): void {
    if (this.condition) {
      this.condition.updateAvailableAttributes();
    }

    if (this.rule.ownerType === WorkflowOwnerType.LINK) {
      if (this.validTrigger && !this.rule.actions.length) {
        this.addNewAction();
      }
    }
  }

  addNewTrigger() {
    this.rule.triggers = [...this.rule.triggers, this.triggerFactory.getBaseWorkflowTrigger()];
  }

  deleteTrigger(trigger: WorkflowTrigger) {
    this.rule.triggers = this.rule.triggers.filter(item => item !== trigger);
    this.onTriggersUpdate();
  }

  onActivateConditions(): void {
    this.rule.condition = RuleConditionGroup.defaultGroup(this.conditionConverter);
  }

  onDeactivateConditions(): void {
    this.rule.condition = null;
  }

  onActionTypeUpdate(actionType: WorkflowActionType, action: WorkflowAction) {
    const newAction = this.actionFactory.getWorkflowAction(actionType);
    this.rule.actions = this.rule.actions.map(item => (item === action ? newAction : item));
  }

  onActionPropertyUpdate(updatedAction: WorkflowAction) {
    if (updatedAction.isValid() && !this.rule.actions.length) {
      this.addNewAction();
    }
  }

  addNewAction(index?: number) {
    this.rule.actions.splice(index !== undefined ? index + 1 : this.rule.actions.length, 0, this.actionFactory.getBaseWorkflowAction());
    this.selectableActions = this.rule.actions.filter(action => action.isCalculatedValueType());
  }

  deleteAction(action: WorkflowAction) {
    this.rule.actions = this.rule.actions.filter(item => item !== action);
  }

  hasValidTriggers(): boolean {
    return !!this.validTrigger;
  }

  saveRule() {
    this.onSaveRule.emit(this.rule);
  }

  deleteRule() {
    this.onDeleteRule.emit(this.rule);
  }

  onArtifactTypeChange(artifactTypeId?: string) {
    this.defaultArtifactType = (artifactTypeId && this.artifactTypes.find(at => at.id === artifactTypeId)) || undefined;
    this.checkAndInitLinkTriggers();
  }

  onAddTriggerInputs(): void {
    const trigger = this.rule.triggers[0];
    if (trigger.type === RuleTriggerType.MANUAL) {
      (trigger as WorkflowTriggerManual).inputDataClientArray = [{ key: '', isArray: false, isOptional: false }];
    }
  }

  onRemoveTriggerInputs(): void {
    const trigger = this.rule.triggers[0];
    if (trigger.type === RuleTriggerType.MANUAL) {
      (trigger as WorkflowTriggerManual).inputDataClientArray = [];
    }
  }

  focusNameInput(): void {
    this.ruleNameInput.nativeElement.focus();
  }

  private doInitOnLinkTrigger() {
    this.linkableArtifactTypes$ = this.getLinkableArtifactTypes$();
  }

  private checkAndInitLinkTriggers() {
    if (this.ruleUtilsService.isLinkSpecificRule(this.rule)) {
      this.doInitOnLinkTrigger();
    }
  }

  private getLinkableArtifactTypes$(): Observable<NewArtifactType[]> | undefined {
    if (!this.defaultArtifactType) {
      return undefined;
    }
    const filter = LinkMethods.getArtifactLinkTypesFilter(this.defaultArtifactType.id);
    return this.tenantLinkTypeService.linkTypeControllerList({ filter }).pipe(
      shareReplay(),
      map(result => {
        const linkTypesPerArtifact: Record<string, LinkTypeResponseDto> = {};
        const linkTypeResponses = result.data;
        linkTypeResponses.forEach(item => {
          item.restrictions?.forEach(restriction => {
            const artifactTypeId =
              restriction.destinationArtifactTypeId === this.defaultArtifactType?.id ? restriction.sourceArtifactTypeId : restriction.destinationArtifactTypeId;
            linkTypesPerArtifact[artifactTypeId] = item;
          });
        });
        return this.artifactTypes.filter(item => !!linkTypesPerArtifact[item.id]);
      }),
    );
  }

  private updateValidTrigger() {
    this.validTrigger = this.rule.triggers.find(trigger => trigger.isValid());
  }
}
