import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { ListWidgetTypeResponseDto } from '@api/models/list-widget-type-response-dto';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageStyles } from '@private/pages/page-management/page-builder-graphical/types/page-styles';
import { ImageMethods } from '@shared/methods/image.methods';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { WidgetCreateRequestDto, WidgetUpdateRequestDto } from '@shared/types/widget.types';
import { AttributeUtil } from '@shared/utils/attribute.util';
import { ListWidgetModel } from '@widgets/shared/components/artifact-list-table/types/list-widget.types';
import { BackgroundTypeEnum } from '@widgets/shared/types/style.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { TableMethods } from '../methods/table.methods';
import { LocalStorageService } from '../services/local-storage.service';
import { NewTableColumn, TableColumn } from '../types/table.types';

@Injectable({ providedIn: 'root' })
export class PageHelper {
  usePublicToken = false;
  private _isOnPage = false;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly apiConfiguration: ApiConfiguration,
    private readonly attributeUtil: AttributeUtil,
    private readonly widgetService: WidgetService,
  ) {}

  get isOnPage(): boolean {
    return this._isOnPage;
  }

  set isOnPage(isOnPage: boolean) {
    this._isOnPage = isOnPage;
  }

  async getPreparedToServerListWidget(part: PageBlockPart): Promise<WidgetCreateRequestDto | WidgetUpdateRequestDto> {
    const widget = part.widget!;

    if (widget.code === WidgetType.listNew) {
      await this.saveAndProcessLinkingPopupWidgets((widget.value.model as ListWidgetModel).linkingPopupDtoMap);
    }

    const dto = widget.requestDto;

    // TODO - to completely refactor this part of code, it is very very bad solution
    if ([WidgetType.list, WidgetType.listNew].includes(widget.code)) {
      const state = this.localStorageService.get(part.hash);

      if (state) {
        if (!state.tableFormatSettings && (dto as any).value.model.state?.tableFormatSettings) {
          state.tableFormatSettings = (dto as any).value.model.state?.tableFormatSettings;
        }
        delete state.selection;
        state.columnOrder = (dto as any).value.model.selected.columns.map((col: TableColumn) => col.key);

        if (this.isColumnCountInconsistent(state, part)) this.fixColumnCountInconsistency(state);

        (dto as any).value.model.state = state;
        if (state.filters) {
          const attributesMap = widget.value.model.selected.columns.reduce((res: Map<string, string>, col: NewTableColumn) => {
            res.set(this.attributeUtil.getUrlQueryAttributeName(col.label), col.key);
            return res;
          }, new Map<string, string>());

          TableMethods.removeIrrelevantFilters((dto as any).value.model.state.filters, widget.value?.model?.state?.filters || {}, attributesMap);
          TableMethods.formatTableFilterNamesToServer((dto as any).value.model.state.filters);
        }
        this.localStorageService.remove(part.hash as string);
      }
    }

    return dto;
  }

  setBackgroundToBody(pageStyles: PageStyles, token: string, publicToken: string): void {
    if (pageStyles.backgroundType == BackgroundTypeEnum.image) {
      if (pageStyles.background) {
        const isExternal = ImageMethods.checkIsExternalUrl(pageStyles.background);
        if (!isExternal) {
          if (!token || this.usePublicToken) token = publicToken;
          pageStyles.background = `url(${ImageMethods.getUrlFromImageFileId(pageStyles.background, this.apiConfiguration.rootUrl, token)})`;
        }
      }
    }

    if (pageStyles.backgroundType == BackgroundTypeEnum.gradient && pageStyles.backgroundImage) {
      pageStyles.background = pageStyles.backgroundImage;
    }

    if (pageStyles) PageStyles.applyToBody(pageStyles);
  }

  resetBackgroundOnBody(): void {
    document.body.style.background = '';
  }

  async saveAndProcessLinkingPopupWidgets(linkingPopupDtoMap: Record<string, Record<string, Partial<ListWidgetTypeResponseDto>>> = {}): Promise<void> {
    await Promise.all(
      Object.values(linkingPopupDtoMap).map(async map => {
        return await Promise.all(
          Object.entries(map).map(async ([key, value]) => {
            delete value.created;
            delete value.updated;
            delete value.deleted;

            const dto = await this.widgetService.saveWidget(value as WidgetCreateRequestDto | WidgetUpdateRequestDto);
            Object.assign(map[key], dto);
            map[key] = value;
          }),
        );
      }),
    );
  }

  private isColumnCountInconsistent(state: any, part: Partial<PageBlockPart>): boolean {
    const utilColsCount = part.widget?.value.model.settings.editableRow ? 2 : 1;
    return state.columnWidths && state.columnWidths.split(',').length - utilColsCount !== state.columnOrder.length;
  }

  private fixColumnCountInconsistency(state: Record<string, any>): void {
    const colWidths = state.columnWidths.split(',');
    const difference = state.columnOrder.length - (colWidths.length - 2);
    for (let i = 0; i < difference; i++) {
      colWidths.splice(colWidths.length - 2, 0, '200');
    }
    state.columnWidths = colWidths.join(',');
  }
}
