import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { MenuItem } from '@widgets/menu-widget/types/menu-widget.types';
import {
  LinkQueryParamActionEnum,
  LinkQueryParamMap,
  MenuItemCurrentPageBehaviorEnum
} from '@widgets/menu-widget/types/tree-types';
import { cloneDeep } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { DefaultElvisActions, MenuWidgetItemActions } from '../types/actions.types';
import { AuthorizationService } from './authorization/authorization.service';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';
import { SharedMethods } from '@shared/methods/shared.methods';
import { ListContainer } from '@shared/types/list-container.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { PageResponseDto } from '@api/models/page-response-dto';
import { ID_KEY, WINDOW_TARGET_BLANK, WINDOW_TARGET_SELF } from '@shared/constants/constants';
import { TenantWorkflowService } from '@api/services/tenant-workflow.service';
import { MenuWidgetWorkflowValueItem } from '@widgets/shared/components/action-list/components/menu-widget-actions/types/menu-widget-workflow-action.types';
import { ReplaceRuntimeVariablesPipe } from '@widgets/shared/pipes/replace-runtime-variables.pipe';

@Injectable({ providedIn: 'root' })
export class ElvisActionService {
  queryParams: Params;
  pages = new ListContainer<Page>();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authorizationService: AuthorizationService,
    private readonly confirmationService: ConfirmationService,
    private readonly cache: NewCacheService,
    private readonly workflowService: TenantWorkflowService,
    private readonly replaceRuntimeVariablesPipe: ReplaceRuntimeVariablesPipe,
  ) {
    this.init();
  }

  async fireElvisAction(action: ActionItem, menuItems: MenuItem[] = [], queryParams: Params): Promise<void> {
    this.queryParams = queryParams;
    switch (action?.type) {
      case DefaultElvisActions.goToProfile:
        return this.goToProfile();
      case DefaultElvisActions.logout:
        return this.logout();
      case DefaultElvisActions.removeAllQueryParams:
        return this.removeAllQueryParams();
      case DefaultElvisActions.removeQueryParamsFromThisWidget:
        return this.removeQueryParamsFromThisWidget(menuItems, queryParams);
      case MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage:
        return this.stayOnPage(action);
      case MenuItemCurrentPageBehaviorEnum.leaveCurrentPage:
        return this.leavePage(action);
      case MenuWidgetItemActions.beWfTrigger:
        return this.beWfTriggerHandler(action);
    }
  }

  private init(): void {
    this.cache.data.pages.subscribe(pages =>
        this.pages.setList(
            (pages as PageResponseDto[]).filter(page => !page.deleted).map(dto => new Page(dto)),
            ID_KEY,
        ),
    )
  }

  private async beWfTriggerHandler(action: ActionItem): Promise<void> {
    return new Promise(async resolveMain => {

    const data = action.model.workflow;
    if (!data) return;
    const inputDataValue = {};

    const promiseArr = (data.data as MenuWidgetWorkflowValueItem[]).map(item => {
      return new Promise(resolve => {
        // @ts-ignore
        let itemValue = item.isUrlType? this.queryParams[item.value]: item.value;
        item.isArray && (itemValue = itemValue.split(','));

        if (item.isVariableType) {
          const method = item.isArray? 'transformArray': 'transform';
          this.replaceRuntimeVariablesPipe[method](itemValue).then(val => {
            // @ts-ignore
            inputDataValue[item.key] = val;
            resolve(val);
          });
        } else {
          // @ts-ignore
          inputDataValue[item.key] = itemValue;
          resolve(itemValue);
        }
      });
    });
    await Promise.all(promiseArr);

    this.workflowService.workflowControllerScheduleWorkflow({
      id: data.id,
      body: {
        inputDataValue,
        datetime: (new Date()).toISOString(),
        waitUntilFinished: true
      },
    }).subscribe((() => {
      setTimeout(() => {
        this.cache.data.artifacts.removeItems(Object.values(inputDataValue));
        this.cache.data.users.removeItems(Object.values(inputDataValue));
        resolveMain();
      }, 1000);
    }));

    });
  }

  private async leavePage(action: ActionItem): Promise<void> {
    this.navigateWithRouter(action);
  }

  private async stayOnPage(action: ActionItem): Promise<void> {
    this.setQueryParamsFromLinkItem(action).then(() => {
      if (action.fragmentParam) {
        const offset = SharedMethods.computeStickyOffset();
        SharedMethods.scrollToHtmlElement(action.fragmentParam, offset);
      }
    });
  }

  get params(): Record<string, string> {
    const params = {};
    (this.router.url.split('?')[1] || '')
        .split('&')
        .filter(p => !p.includes('prevPageUrl'))
        .forEach(p => {
          const parts = p.split('=');
          // @ts-ignore
          params[parts[0]] = parts[1];
        })
    return params;
  }

  async navigateWithRouter(action: ActionItem): Promise<any> {
    const key = (action.isPageSelection ? action.pageId: action.url) || '';
    const alias = this.pages?.listMap[key]?.alias || '';
    const urlTreeRoute = action.useAlias && action.isPageSelection ? [alias] : [key];
    const queryParams = action.queryParamsListToMap().parametersToAdd;
    const params = this.params;

    if (action.holdAllUrlParam) {
      Object.keys(params).forEach(key => {
        // @ts-ignore
        queryParams[key] = params[key];
      });
    } else {
      action.queryParamsListToMap().parametersToHold.forEach(key => {
        // @ts-ignore
        params[key] && (queryParams[key] = params[key]);
      })
    }

    const navigationExtra: NavigationExtras = { queryParams };
    action.fragmentParam && (navigationExtra.fragment = action.fragmentParam);

    const urlTree = this.router.createUrlTree(urlTreeRoute, navigationExtra);

    if (!action.isPageSelection) {
      const params = urlTree.toString().split('?')[1] || '';
      const url = action.url + (params ? `?${params}`: '');
      const target = action.openInNewTab ? WINDOW_TARGET_BLANK : WINDOW_TARGET_SELF;
      window.open(url, target, 'noopener');
      return;
    }

    if (action.openInNewTab) {
      window.open(urlTree.toString(), WINDOW_TARGET_BLANK, 'noopener');
      return;
    }

    await this.router.navigateByUrl(urlTree);
  }

  private async setQueryParamsFromLinkItem(action: ActionItem): Promise<void> {
    const menuLinkQueryParamsMap = action.queryParamsListToMap();
    const queryParams = this.getQueryParameters(menuLinkQueryParamsMap);
    const navigationExtras = this.getNavigationExtras(queryParams, action);

    await this.router.navigate([], navigationExtras);
  }

  private getQueryParameters(menuLinkQueryParamsMap: LinkQueryParamMap): Params {
    const queryParams = { ...this.queryParams, ...menuLinkQueryParamsMap.parametersToAdd };
    menuLinkQueryParamsMap.parameterKeysToRemove.forEach((paramKey: string) => delete queryParams[paramKey]);
    return queryParams;
  }

  private getNavigationExtras(queryParams: Params, action: ActionItem): NavigationExtras {
    const navigationExtras: NavigationExtras = { queryParams, relativeTo: this.route, replaceUrl: true };
    action.fragmentParam && (navigationExtras.fragment = action.fragmentParam);
    return navigationExtras;
  }

  private async logout(): Promise<void> {
    await this.authorizationService.logoutWithConfirmation(this.confirmationService);
  }

  private async goToProfile(): Promise<void> {
    await this.router.navigateByUrl('/profile');
  }

  private async removeAllQueryParams(): Promise<void> {
    const queryParams = {};
    await this.router.navigate([], { queryParams, relativeTo: this.route, replaceUrl: true });
  }

  private async removeQueryParamsFromThisWidget(menuItems: MenuItem[], queryParams: Params): Promise<void> {
    const params = cloneDeep(queryParams);
    this.removeQueryParamsFromMenuItems(menuItems, params);
    await this.router.navigate([], { queryParams: params, relativeTo: this.route, replaceUrl: true });
  }

  private removeQueryParamsFromMenuItems(menuItems: MenuItem[], params: Params): void {
    menuItems?.forEach(menuItem => {
      menuItem.actions.forEach(action => {
        action.queryParams.forEach(param => param.action === LinkQueryParamActionEnum.addNew && delete params[param.key]);
      })
      menuItem.children.length && this.removeQueryParamsFromMenuItems(menuItem.children, params);
    });
  }
}
