import { ArtifactTypeLinkRestriction } from '@api/models/artifact-type-link-restriction';
import { LinkPopupModelDto } from '@api/models/link-popup-model-dto';
import { LinkPopupSelectedDto } from '@api/models/link-popup-selected-dto';
import { LinkPopupSettingsDto } from '@api/models/link-popup-settings-dto';
import { ListWidgetTypeCreateRequestDto } from '@api/models/list-widget-type-create-request-dto';
import { ListWidgetTypeResponseDto } from '@api/models/list-widget-type-response-dto';
import { ListWidgetTypeUpdateRequestDto } from '@api/models/list-widget-type-update-request-dto';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { SelectOption } from '@shared/types/shared.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ArtifactListTableIdentifiers } from '@widgets/shared/components/artifact-list-table/types/artifact-list-widget-table.types';

import { MenuItem } from 'primeng/api';
import { WidgetModel } from '../../shared/types/widget-model.interface';
import { WidgetTableUtil } from '../../shared/utils/table.util';
import { LinkPopupOptions } from './link-popup-options.types';
import { LinkPopupSelected } from './link-popup-selected.types';
import { LinkPopupSettings } from './link-popup-settings.types';

export class LinkPopupWidgetValue {
  model: LinkPopupWidgetModel = new LinkPopupWidgetModel();

  constructor(value?: LinkPopupWidgetValue) {
    value && Object.assign(this, value);
  }
}

export class LinkPopupWidgetModel implements WidgetModel<any> {
  id?: string;
  hash = SharedMethods.getUniqueId();
  folderPath = '';
  folderId = '';
  addButtonItems: MenuItem[] = [];
  originalArtifact: NewArtifact | null = null;
  options: LinkPopupOptions = new LinkPopupOptions();
  selected: LinkPopupSelected = new LinkPopupSelected();
  settings: LinkPopupSettings = new LinkPopupSettings();
  restriction: ArtifactTypeLinkRestriction | null = null;
  state: Record<string, any> = {};
  displayModal = false;
  directionalLinkType: DirectionalLinkType;
  isSettingsSidebarVisible = false;
  ids: ArtifactListTableIdentifiers;
  isFirstLoad = true;

  fromDto(dtoMap: Record<string, Record<string, ListWidgetTypeResponseDto>>) {
    const artifactTypesDto = dtoMap[this.directionalLinkType.idAndDirection];
    if (!artifactTypesDto) return;

    const dto = artifactTypesDto[this.selected.artifactType.id]?.value?.model;
    if (!dto) return;

    this.id = artifactTypesDto[this.selected.artifactType.id].id;
    this.settings.fromDto(dto.settings as any as LinkPopupSettingsDto, this.options);
    this.selected.setFromDto(dto.selected as any as LinkPopupSelectedDto, this.options);
    this.state = WidgetTableUtil.convertStateFromDto(dto.state, this.options);
  }

  toServer(): ListWidgetTypeResponseDto {
    const linkPopupModelDto: LinkPopupModelDto = {
      listUiType: 'LINKING_POPUP',
      selected: this.selected.toServer(),
      settings: this.settings.toServer(),
      state: WidgetTableUtil.convertStateToServer(this.state),
    };

    const dto: ListWidgetTypeCreateRequestDto | ListWidgetTypeUpdateRequestDto = {
      code: 'LIST-NEW',
      value: { model: linkPopupModelDto },
    };

    this.id && ((dto as any).id = this.id);

    return dto as ListWidgetTypeResponseDto;
  }

  setRelevantArtifactTypes(): void {
    const artifactTypeIds = this.directionalLinkType.isOutgoing ? this.restriction?.destinationArtifactTypeIds : this.restriction?.sourceArtifactTypeIds;
    this.options.relevantArtifactTypes = this.options.artifactTypes
      .filterByKey('id', artifactTypeIds || [])
      .map(artifactType => new SelectOption(artifactType.name, artifactType));
  }

  reset(): void {
    this.id = undefined;
    this.settings = new LinkPopupSettings();
    this.selected.setColumns([]);
    this.state = {};
  }
}

// export interface LinkPopupModelDto {
//   selected: LinkPopupSelectedDto;
//   settings: LinkPopupSettingsDto;
//   state: Record<string, any>;
//   listUiType: 'LINKING_POPUP';
// }
