import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { DefaultMenuItemButtonFormComponent } from '@widgets/menu-widget/components/menu-widget-settings/components/menu-item-default-settings-form/components/default-menu-item-button-form/default-menu-item-button-form.component';
import { GetSubMenuOffsetPipe } from '@widgets/menu-widget/pipes/get-sub-menu-offset-left.pipe';
import { GetHrefForMenuItemPipe } from '@widgets/menu-widget/pipes/transform-menu-item-url.pipe';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';

import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { StyleFormModule } from '../shared/components/style-form.module';
import { DividerComponent } from './components/menu-item/components/divider/divider.component';
import { SubMenuContainerComponent } from './components/menu-item/components/sub-menu-container/sub-menu-container.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ContentStyleFormComponent } from './components/menu-widget-settings/components/content-style-form/content-style-form.component';
import { DividerHorizontalFormComponent } from './components/menu-widget-settings/components/menu-item-default-settings-form/components/default-divider-horizontal-form/divider-horizontal-form.component';
import { DividerVerticalFormComponent } from './components/menu-widget-settings/components/menu-item-default-settings-form/components/default-divider-vertical-form/divider-vertical-form.component';
import { DefaultStyleSettingsFormComponent } from './components/menu-widget-settings/components/menu-item-default-settings-form/default-style-settings-form.component';
import { MenuItemButtonFormComponent } from './components/menu-widget-settings/components/menu-item-settings-form/components/menu-item-button-form/menu-item-button-form.component';
import { MenuItemTypeFormComponent } from './components/menu-widget-settings/components/menu-item-settings-form/components/menu-item-type-form/menu-item-type-form.component';
import { MenuItemSettingsFormComponent } from './components/menu-widget-settings/components/menu-item-settings-form/menu-item-settings-form.component';
import { MenuSettingsFormComponent } from './components/menu-widget-settings/components/menu-settings-form/menu-settings-form.component';
import { SubMenuSettingsFormComponent } from './components/menu-widget-settings/components/sub-menu-settings-form/sub-menu-settings-form.component';
import { TreeFormComponent } from './components/menu-widget-settings/components/tree-form/tree-form.component';
import { MenuWidgetSettingsComponent } from './components/menu-widget-settings/menu-widget-settings.component';
import { ActiveLinkCheckHelper } from './helpers/active-link-check.helper';
import { MenuWidgetHelper } from './helpers/menu-widget.helper';

import { MenuWidgetComponent } from './menu-widget.component';
import { GetContentStylesPipe } from './pipes/get-content-styles.pipe';
import { GetDividerStylesPipe } from './pipes/get-divider-styles.pipe';
import { GetMenuItemStylesPipe } from './pipes/get-menu-item-styles.pipe';
import { GetSubMenuArrowPipe } from './pipes/get-sub-menu-arrow.pipe';
import { GetSubMenuLeftMarginPipe } from './pipes/get-sub-menu-left-margin.pipe';
import { GetSubMenuTopMarginPipe } from './pipes/get-sub-menu-top-margin.pipe';
import { IsItemInFirstLevelPipe } from './pipes/is-item-in-first-level.pipe';
import { IsItemInTreePipe } from './pipes/is-item-in-tree.pipe';
import { IsMenuHorizontalPipe } from './pipes/is-menu-horizontal.pipe';
import { SplitUpMenuItemsByPositionPipe } from './pipes/split-up-menu-items-by-position.pipe';
import { CustomEventSettingsFormModule } from '@widgets/shared/components/custom-event-settings-form/custom-event-settings-form.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FloatLabelModule } from 'primeng/floatlabel';
import { TreeSelectModule } from 'primeng/treeselect';
import { ActionListModule } from '@widgets/shared/components/action-list/action-list.module';

@NgModule({
  declarations: [
    MenuWidgetComponent,
    MenuWidgetSettingsComponent,
    MenuItemComponent,
    ContentStyleFormComponent,
    SubMenuContainerComponent,
    MenuItemSettingsFormComponent,
    MenuSettingsFormComponent,
    SubMenuSettingsFormComponent,
    TreeFormComponent,
    SplitUpMenuItemsByPositionPipe,
    DividerHorizontalFormComponent,
    DividerVerticalFormComponent,
    MenuItemButtonFormComponent,
    DividerComponent,
    IsMenuHorizontalPipe,
    GetSubMenuTopMarginPipe,
    GetSubMenuLeftMarginPipe,
    IsItemInFirstLevelPipe,
    GetMenuItemStylesPipe,
    GetContentStylesPipe,
    DefaultStyleSettingsFormComponent,
    DefaultMenuItemButtonFormComponent,
    MenuItemTypeFormComponent,
    GetDividerStylesPipe,
    IsItemInTreePipe,
    GetSubMenuArrowPipe,
    GetHrefForMenuItemPipe,
    GetSubMenuOffsetPipe,
  ],
	imports: [
		CommonModule,
		MenubarModule,
		SharedModule,
		TreeModule,
		InputTextModule,
		FormsModule,
		TranslateModule,
		ButtonModule,
		RippleModule,
		DropdownModule,
		CheckboxModule,
		ColorPickerModule,
		OverlayPanelModule,
		TooltipModule,
		BrowserAnimationsModule,
		AccordionModule,
		StyleFormModule,
		DialogModule,
		FloatLabelModule,
		TreeSelectModule,
		ElvisSharedModule,
		TabViewModule,
		DragDropModule,
		CustomEventSettingsFormModule,
		ActionListModule,
	],
  providers: [MenuWidgetHelper, ActiveLinkCheckHelper, IsMenuHorizontalPipe, IsItemInFirstLevelPipe, GetHrefForMenuItemPipe],
  exports: [MenuWidgetComponent, ContentStyleFormComponent],
})
export class MenuWidgetModule {}
