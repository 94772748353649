import { Component, inject, Input, OnInit } from '@angular/core';
import { TenantTenantResponseDto } from '@api/models';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { TENANT_LABEL } from '@shared/constants/constants';
import { CachedUserMeta } from '@shared/types/new-cached-subject.types';
import { SelectOption } from '@shared/types/shared.types';

@Component({
  selector: 'app-tenant-settings',
  templateUrl: './tenant-settings.component.html',
  styleUrls: ['./tenant-settings.component.scss'],
})
export class TenantSettingsComponent implements OnInit {
  @Input() tenant: TenantTenantResponseDto;
  @Input() publicPageOptions: SelectOption<string, string>[] = [];

  cache = inject(NewCacheService);
  userMeta?: CachedUserMeta;
  TENANT_LABEL = TENANT_LABEL;

  ngOnInit() {
    this.userMeta = this.cache.userMeta;
  }
}
