import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models';
import { ID_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetArtifactAttributeValuePath } from '@shared/methods/client-attribute.methods';
import { SharedMethods, WithEmptySelectOption } from '@shared/methods/shared.methods';
import { NewAttribute } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss'],
})
export class UserFilterComponent {
  @Input() m: ArtifactFilter;
  @Input() multipleValues: boolean;
  @Output() onFilterChange: EventEmitter<void> = new EventEmitter<void>();

  options: SelectOption<string, string>[] = [];

  private _options: SelectOption<string, string>[] = [];
  private _optionsMap: Map<string, SelectOption<string, string>[]> = new Map<string, SelectOption<string, string>[]>();

  @Input() set users(users: ListContainer<ArtifactLinkResponseDto>) {
    if (!users) return;

    const allUsersWithCurrentOne = SharedMethods.withCurrentUserOption(
      users.toSelectOptions(GetArtifactAttributeValuePath(GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)), ID_KEY),
    );

    this._options = this.m.isRecordAuthorFilter
      ? allUsersWithCurrentOne
      : WithEmptySelectOption(allUsersWithCurrentOne, (this.m?.attribute as NewAttribute)?.multipleValues);

    this._options.map(option => {
      delete option.meta;
      this._optionsMap.set(option.value, option as any);
      return option;
    });

    if (this.m.value && Array.isArray(this.m.value)) {
      this.m.value?.forEach(option => Object.assign(option, this._optionsMap.get(option.value) || {}));
    }
  }

  onChange(): void {
    this.onFilterChange.emit();
  }

  sortUsers(): void {
    const { selected, others } = this._options.reduce(
      (res: any, option: SelectOption<string, string>) => {
        if (this.m.value?.some((item: SelectOption<string, string>) => item.value === option.value)) res.selected.push(option);
        else res.others.push(option);
        return res;
      },
      { selected: [], others: [] },
    );

    this.options = [...selected, ...others];

    this.onFilterChange.emit();
  }
}
