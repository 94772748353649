import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { FolderDataResponseDto } from '@api/models/folder-data-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantFolderService } from '@api/services/tenant-folder.service';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { FadeAnimation } from '@shared/animations/animations';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { DisplayAtControlService } from '@shared/components/common-display-at';
import { CURRENT_USER_URL_FILTER_VALUE, ID_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetDataTypeFromClientAttribute } from '@shared/methods/artifact.methods';
import { AttributeValueToClient } from '@shared/methods/client-attribute.methods';
import { IsHtml } from '@shared/methods/data-type.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeToClientParams } from '@shared/types/attribute-convert.types';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { CUSTOM_VARIANT_KEY, DisplayAtMetadata } from '@shared/types/display-at-types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { LinkType } from '@shared/types/link-type.types';
import { LinkRestriction, NewLink } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetFormComponent } from '@widgets/artifact-widget/components/artifact-widget-form/artifact-widget-form.component';
import { ArtifactWidgetHelper } from '@widgets/artifact-widget/helpers/artifact-widget.helper';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { ArtifactLinkDialogComponent } from '@widgets/link-popup/artifact-link-dialog.component';
import { ArtifactListTableIdentifiers } from '@widgets/shared/components/artifact-list-table/types/artifact-list-widget-table.types';
import { PRIVATE_USER_FOLDER, PRIVATE_USER_FOLDER_LABEL } from '@widgets/shared/constants/widget.constants';
import { LinkDialogOpenArguments } from '@widgets/shared/types/link-popup/link-dialog-open-arguments';
import { APPLICATION_ID, HASH, IS_LAYOUT_MODE, IS_PREVIEW_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { cloneDeep } from 'lodash';
import { filter, lastValueFrom, map, Observable, switchMap, tap } from 'rxjs';
import { RuntimeStateNotificationService } from '../shared/services/runtime-state-notification.service';
import { WidgetsCoreComponent } from '../widgets-core/components/widgets-core.component';
import { ArtifactWidgetService } from './services/artifact-widget.service';
import { ArtifactWidgetFormItem } from './types/artifact-widget-form.types';
import { ArtifactWidgetOptions } from './types/artifact-widget-options.types';
import { ArtifactWidgetModel, ArtifactWidgetType, ArtifactWidgetValue } from './types/artifact-widget.types';

@Component({
  selector: 'app-artifact-widget',
  templateUrl: './artifact-widget.component.html',
  styleUrls: ['./artifact-widget.component.scss'],
  providers: [ArtifactWidgetService, ArtifactWidgetHelper],
  animations: [FadeAnimation],
})
export class ArtifactWidgetComponent extends WidgetsCoreComponent implements OnInit, OnDestroy {
  @ViewChild('formDiv') formDiv: ElementRef;
  @ViewChild('form') form: ArtifactWidgetFormComponent;
  @ViewChild('spinner') spinnerComponent: any;
  @ViewChild('linkDialog') linkDialog: ArtifactLinkDialogComponent;

  m: ArtifactWidgetModel;
  isLoadingData = true;
  isWidgetSettingsTabSelected = true;
  isFormItemSettingsTabSelected = false;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<ArtifactWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    @Inject(HASH) public hash: string,
    @Inject(IS_PREVIEW_MODE) private readonly isPreviewMode: boolean,
    public readonly s: ArtifactWidgetService,
    public readonly tenantArtifactService: TenantArtifactService,
    public readonly tenantLinkService: TenantLinkService,
    public readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private readonly folderService: TenantFolderService,
    private readonly cache: NewCacheService,
    private readonly widgetService: WidgetService,
    private readonly displayAtControlService: DisplayAtControlService,
  ) {
    super(route, router, announcement, elRef);
  }

  get ids(): ArtifactListTableIdentifiers {
    return { applicationId: this.applicationId, hash: this.widget.id as string };
  }

  async ngOnInit(): Promise<void> {
    await this.s.init(this);
    this.registerDisplayAtEvents();
  }

  onLinkDialogOpen(linkDialogArguments: LinkDialogOpenArguments): void {
    this.linkDialog.open(linkDialogArguments);
  }

  async onArtifactTypeChange(): Promise<void> {
    this.m.generateAttributeOptions(this.m.options.attributes, undefined, this.route);
    this.clearForm();
    await this.s.initLinkRestrictions(this, this.m.selected.artifactType?.id);
    await this.s.initLinkTypeOptions(this.m.options, this);
    this.m.setNoItemEditableFlag(false);

    this.s.initArtifactTypeRequiredAttributes();
    this.m.selected.linkType = null;
    this.setFolderPath();
  }

  changeFormFocus(): void {
    this.m.isFormFocus = !this.m.isFormFocus;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.isLayoutMode) {
      this.m.subscriptions.queryParams$ && this.m.subscriptions.queryParams$.unsubscribe();
      this.m.subscriptions.runtimeState$ && this.m.subscriptions.runtimeState$.unsubscribe();
      this.m.subscriptions.saveDebounce$ && this.m.subscriptions.saveDebounce$.unsubscribe();
    }
  }

  isValueEmpty(attribute: NewClientAttribute): boolean {
    if (!attribute.value) return true;
    if (attribute.value instanceof Date) return false;
    if (Array.isArray(attribute.value)) return !attribute.value.filter(Boolean).length;
    return (attribute.value?.value || attribute.value)?.replace(/\s+/g, '')?.length === 0;
  }

  checkMandatoryFields(form: ArtifactWidgetFormItem[], announce = true): boolean {
    const fields: string[] = [];
    let hasMandatoryValues = true;
    let isLinkRestrictionChecked = false;

    form.forEach(item => {
      if (item.attribute) {
        const attribute = item.attribute.value;
        const newAttribute = this.m.options.attributes.listMap[attribute?.id];
        const dataType = this.m.options.dataTypes.listMap[newAttribute?.dataTypeId];

        if (
          attribute.isMandatory &&
          (this.m.settings.widgetType === ArtifactWidgetType.registration || attribute.id !== GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)) &&
          !dataType?.isCounter
        ) {
          if (
            GetDataTypeFromClientAttribute(attribute, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap)?.baseDataType ===
            BaseDataType.boolean
          ) {
            if (!['true', 'false'].includes(String(attribute.value))) {
              fields.push(item.attribute.label);
              hasMandatoryValues = false;
            }
          } else {
            if (this.isValueEmpty(attribute)) {
              fields.push(item.attribute.label);
              hasMandatoryValues = false;
            }
          }
        }
      } else {
        if (item.linkType) {
          isLinkRestrictionChecked = true;

          if (item.isLinkTypeRequired) {
            if (!this.m.newLinksMap[item.linkType.value.id] && !this.m.linkMap[item.linkType.value.id]) {
              fields.push(item.linkType.label);
              hasMandatoryValues = false;
            }
          }
        }
      }
    });

    if (!isLinkRestrictionChecked) {
      this.m.options.linkTypes.list.forEach((linkType: LinkType) => {
        linkType.restrictions.forEach(restrictions => {
          if (this.s.isLinkRequired(restrictions, LinkDirection.outgoing) || this.s.isLinkRequired(restrictions, LinkDirection.incoming)) {
            fields.push(linkType.name);
            hasMandatoryValues = false;
          }
        });
      });
    }

    !hasMandatoryValues && announce && this.announcement.error(`Mandatory fields: {{fields}} are not set`, { fields: fields.join(', ') });

    return hasMandatoryValues;
  }

  copyOriginalForm(): void {
    this.m.originalForm = cloneDeep(this.m.form);
  }

  async setFolderPath(): Promise<void> {
    !this.m.selected.artifact && (this.m.selected.artifact = new NewArtifact());
    this.m.newArtifactFile = null;

    const id = this.getDestinationFolderId();
    if (!id) return;

    const folder = await lastValueFrom(this.folderService.folderControllerGet({ id }));
    const path =
      GlobalConstants.getValue(GlobalConstantsEnum.currentUserPrivateFolderId) === folder.id
        ? PRIVATE_USER_FOLDER_LABEL
        : (folder.folderPath ? folder.folderPath : '') + folder.name;
    const parentId = folder.id;

    this.m.selected.artifact.folderData = { parentId, path } as FolderDataResponseDto;

    this.m.selected.artifact.parentFolderId = parentId;
    this.m.selected.artifact.folderPath = path;
  }

  getDestinationFolderId(): string | null {
    const { saveToFolderId } = this.m.settings.urlKeys.listeningKeys;
    const id = this.m.selected.artifact?.parentFolderId || this.m.settings.storeToFolderId || this.m.selected.artifactType?.defaultFolderId;
    return this.m.settings.listenForFolderUrlParam && this.m.queryParams && this.m.queryParams[saveToFolderId]
      ? this.m.queryParams[saveToFolderId].split(',')[0]
      : id;
  }

  isPresentInUrl(): boolean {
    const { saveToFolderId } = this.m.settings.urlKeys.listeningKeys;
    return !!this.m.queryParams[saveToFolderId];
  }

  async setData(params: Params, resetLinks = true, resetForm = true, oldParams?: Params): Promise<void> {
    this.isLoadingData = true;
    this.spinnerComponent.nativeElement.style.height = this.formDiv.nativeElement.offsetHeight + 'px';
    const paramId = this.m.settings.urlChangeAction ? params[this.m.settings.urlKeys?.listeningKeys.artifactId] : null;
    const oldParamId = oldParams && this.m.settings.urlChangeAction ? oldParams[this.m.settings.urlKeys?.listeningKeys.artifactId] : null;

    if (this.shouldSkipWidgetReinitialization(paramId, oldParamId, oldParams)) {
      this.isLoadingData = false;
      this.onWidgetDataLoaded();
      return;
    }

    // I am re-setting mandatory, editable and visibility settings because workflows could have modified them
    this.resetConfigurableSettingsToDefault();
    this.m.artifactDto = undefined;

    if (!paramId) {
      this.m.selected.artifact = null;
      this.m.artifactId = null;
      this.m.linkMap = {};
      resetLinks && (this.m.newLinksMap = {});
      let folderId = this.getDestinationFolderId();

      if (folderId === this.m.previousFolderId || !this.isPresentInUrl()) {
        (resetForm || this.m.previousArtifactId !== paramId) && this.m.settings.clearFormOnCreation && this.s.resetForm();
        this.m.generateAttributeOptions(this.m.options.attributes, this.m.options, this.route);
      }

      if (folderId === GlobalConstants.getValue(GlobalConstantsEnum.currentUserPrivateFolderId)) {
        folderId = PRIVATE_USER_FOLDER;
      }
      this.m.previousFolderId = folderId;
      this.m.previousArtifactId = paramId;
      await this.setFolderPath();
      this.isLoadingData = false;
      this.onWidgetDataLoaded();
      this.notifyTriggerWidgetCreateMode();
      return;
    }

    this.m.previousArtifactId = paramId;
    this.m.previousFolderId = this.getDestinationFolderId();
    await this.s.initLinkRestrictions(this, this.m.selected.artifactType?.id);
    this.m.newLinksMap = {};

    try {
      if (paramId && (this.m.settings.urlChangeAction || !this.m.settings.clearFormOnCreation)) {
        let currentUserProfileArtifactId;

        if (paramId === CURRENT_USER_URL_FILTER_VALUE) {
          currentUserProfileArtifactId = (this.cache.userProfile.value as ArtifactLinkResponseDto)?.id;
        }

        const dto = await this.cache.data.artifacts.getAsync(currentUserProfileArtifactId || paramId);
        if (dto?.artifactTypeId !== this.widget?.value?.model?.selected?.artifactType?.id && dto?.artifactTypeId !== this.m.selected.artifactType?.id) {
          this.s.resetForm();
          this.m.doResetForm = true;
          return;
        }

        this.m.artifactDto = dto;

        for (const attributeKey in dto.attributes) {
          const dataType = this.m.options.dataTypes.listMap[this.m.options.attributes.listMap[attributeKey]?.dataTypeId];
          if (dataType?.isHtml) {
            dto.attributes[attributeKey].value = this.s.tinymceHelper.addOrRemoveImageAuth(dto.attributes[attributeKey].value as string, false);
          }
          if (Object.prototype.hasOwnProperty.call(this.m.options.clientAttributesPattern, attributeKey)) {
            this.m.options.clientAttributesPattern[attributeKey] = dto.attributes[attributeKey].value;
          }
        }

        this.m.artifactId = currentUserProfileArtifactId || paramId;
        this.m.selected.artifact = new NewArtifact({ dto, artifactTypesMap: this.m.options.artifactTypes.listMap });
        await this.setLinksMapAndLinkedArtifactsMap();
        this.checkAttributesForCardDisplayVariantset();
        this.m.initAttributesValue = cloneDeep(this.m.selected.artifact.attributes);
      } else {
        this.m.artifactId = null;
      }
    } catch (e) {
      console.log(e);
      await this.announcement.error('Failed to load data for widget');
    } finally {
      !this.m.doResetForm && (await this.mapNewDataValuesToClient());
      this.copyOriginalForm();
      this.m.setHasFormChangedFlag(false);
      this.m.updateShowSaveButtonFlag();
      this.m.doResetForm = false;
      this.m.isEditInProgress = false;
      this.isLoadingData = false;
      this.onWidgetDataLoaded();
    }
  }

  async setLinksMapAndLinkedArtifactsMap(): Promise<void> {
    if (!this.m.artifactId) {
      return;
    }

    const filter = LinkMethods.getLinksFilterForArtifact(this.m.artifactId, this.m.options.linkTypeIdsMap);
    const links = (await lastValueFrom(this.tenantLinkService.linkControllerList({ body: { filter } }))).data;
    const linkedArtifacts = await LinkMethods.getUniqueArtifactsFromLinks(links, this.cache);

    this.m.linksDto = links;
    this.setLinksMap(links);
    this.setLinkedArtifactsMap(linkedArtifacts);
  }

  async mapNewDataValuesToClient(): Promise<void> {
    if (this.m.form?.length) {
      this.mapFormAttributeValuesToClient();
      await this.m.mapFilesToArtifactType(this.cache, this.announcement, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap);
    }
    if (this.m.options.clientAttributes?.length && this.m.selected.artifact) {
      this.mapOptionValuesToClient();
    }
  }

  // TODO: check are we need this function here
  getLinkedArtifactTypesOptions(): SelectOption<string, NewArtifactType>[] {
    const { linkType } = this.m.selected;
    if (!linkType?.value) {
      return [];
    }

    const { restrictions } = this.m.options.linkTypes.listMap[linkType.value.id];
    const artifactTypeIds = new Set<string>();
    let disabledArtifactTypeIds = new Set<string>();

    this.fillArtifactTypeIdsForLinkedArtifactWidget(restrictions, linkType.meta, artifactTypeIds, disabledArtifactTypeIds);
    disabledArtifactTypeIds = this.computeDifferenceBetweenAvailableIds(artifactTypeIds, disabledArtifactTypeIds);

    return this.concatenateArtifactTypeIdsForLinkedArtifactWidget(artifactTypeIds, disabledArtifactTypeIds);
  }

  notifyTriggerWidgetCreateMode(): void {
    if (this.form) {
      this.form.triggerWidgetCreateMode();
      return;
    }

    setTimeout(() => this.notifyTriggerWidgetCreateMode(), 50);
  }

  onItemSelectCb(): void {
    this.isFormItemSettingsTabSelected = true;
    this.isWidgetSettingsTabSelected = false;
  }

  resetConfigurableSettingsToDefault(): void {
    this.resetMandatorySettingsToDefault();
    this.resetEditabilitySettingsToDefault();
    if (this.isPreviewMode) {
      this.resetVisibilitySettingsToDefault();
    }
  }

  private resetMandatorySettingsToDefault(): void {
    const attributes = this.m.selected.artifactType?.attributes;
    if (!attributes) return;

    this.m.form.forEach(item => {
      if (item.attribute) {
        item.attribute.value.isMandatory = attributes[item.attribute.value.id]?.isMandatory;
      }
    });
  }

  private resetEditabilitySettingsToDefault(): void {
    this.form && this.form.resetFormEditabilityToDefault();
  }

  private resetVisibilitySettingsToDefault(): void {
    this.form && this.form.resetFormVisibilityToDefault();
  }

  private mapOptionValuesToClient(): void {
    this.m.options.clientAttributes.forEach(option => {
      if (
        GetDataTypeFromClientAttribute(option.value, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap)?.baseDataType !== BaseDataType.file
      ) {
        option.value.value = AttributeValueToClient(
          new AttributeToClientParams({
            attributes: this.m.options.attributes,
            dataTypes: this.m.options.dataTypes,
            users: this.m.options.users.list,
            value: this.m.selected.artifact?.attributes[option.value.id]?.value,
            clientAttribute: option.value,
          }),
        );
      } else {
        if (!option.value.value) {
          const fileValue = this.m.selected.artifact?.attributes[option.value.id]?.value;
          option.value.value = AttributeValueToClient(
            new AttributeToClientParams({
              attributes: this.m.options.attributes,
              dataTypes: this.m.options.dataTypes,
              users: this.m.options.users.list,
              value: fileValue ? fileValue : '',
              clientAttribute: option.value,
            }),
          );
        }
      }
    });
  }

  private mapFormAttributeValuesToClient(): void {
    this.m.form.forEach(item => {
      if (item?.attribute) {
        const value = this.getAttributeValueOrInitialValue(item);

        item.attribute.value.value = AttributeValueToClient(
          new AttributeToClientParams({
            dataTypes: this.m.options.dataTypes,
            attributes: this.m.options.attributes,
            value,
            users: this.m.options.users.list,
            clientAttribute: item.attribute.value,
          }),
        );

        item.value = item.attribute;

        if (item.attribute?.value.id === GlobalConstants.getValue(GlobalConstantsEnum.sequenceAttributeId)) {
          this.m.initSequence = item.attribute?.value.value;
        }

        const dataType = GetDataTypeFromClientAttribute(item.attribute.value, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap);
        if (IsHtml(dataType?.baseDataType as BaseDataType)) {
          (item.value.value as NewClientAttribute).value = this.s.tinymceHelper.addOrRemoveImageAuth(value as string, false);
        }
      }
    });
  }

  private setLinksMap(links: LinkResponseDto[]): void {
    const linkMap: Record<string, Record<LinkDirection, NewLink[]>> = {};

    links.forEach(link => {
      if (!linkMap[link.linkTypeId]) {
        linkMap[link.linkTypeId] = {
          [LinkDirection.outgoing]: [],
          [LinkDirection.incoming]: [],
        };
      }

      if (link.sourceArtifactId === this.m.artifactId) {
        linkMap[link.linkTypeId][LinkDirection.outgoing].push(link);
      }
      if (link.destinationArtifactId === this.m.artifactId) {
        linkMap[link.linkTypeId][LinkDirection.incoming].push(link);
      }
    });

    this.m.linkMap = linkMap;
  }

  private setLinkedArtifactsMap(linkedArtifacts: ArtifactResponseDto[]): void {
    const linkedArtifactsMap: Record<string, NewArtifact> = {};

    linkedArtifacts.forEach(
      artifact =>
        (linkedArtifactsMap[artifact.id] = new NewArtifact({
          dto: artifact,
          artifactTypesMap: this.m.options.artifactTypes.listMap,
        })),
    );

    this.m.linkedArtifactsMap = linkedArtifactsMap;
  }

  private getAttributeValueOrInitialValue(item: ArtifactWidgetFormItem): any {
    if (!item.attribute) {
      return null;
    }

    let value = this.m.selected.artifact?.attributes[item.attribute.value.id]?.value;
    if (!this.m.selected.artifact) {
      value = value || item.attribute.value.value;
    }

    return value;
  }

  private fillArtifactTypeIdsForLinkedArtifactWidget(
    restrictions: LinkRestriction[],
    direction: LinkDirection,
    artifactTypeIds: Set<string>,
    disabledArtifactTypeIds: Set<string>,
  ): void {
    restrictions.forEach(restriction => {
      if (LinkMethods.isOutgoing(direction)) {
        restriction.singleSource
          ? disabledArtifactTypeIds.add(restriction.destinationArtifactTypeId)
          : artifactTypeIds.add(restriction.destinationArtifactTypeId);
      } else {
        restriction.singleDestination ? disabledArtifactTypeIds.add(restriction.sourceArtifactTypeId) : artifactTypeIds.add(restriction.sourceArtifactTypeId);
      }
    });
  }

  private computeDifferenceBetweenAvailableIds(artifactTypeIds: Set<string>, disabledArtifactTypeIds: Set<string>): Set<string> {
    return new Set([...disabledArtifactTypeIds].filter(id => !artifactTypeIds.has(id)));
  }

  private concatenateArtifactTypeIdsForLinkedArtifactWidget(
    artifactTypeIds: Set<string>,
    disabledArtifactTypeIds: Set<string>,
  ): SelectOption<string, NewArtifactType>[] {
    return [...artifactTypeIds]
      .map(id => new SelectOption(this.m.options.artifactTypes.listMap[id].name, this.m.options.artifactTypes.listMap[id]))
      .concat(
        [...disabledArtifactTypeIds].map(
          id => new SelectOption(this.m.options.artifactTypes.listMap[id].name, this.m.options.artifactTypes.listMap[id], null, true),
        ),
      );
  }

  private checkAttributesForCardDisplayVariantset() {
    // attributes
    Object.keys(this.m.formatsMap.attribute)
      .filter(key => this.m.formatsMap.attribute[key].value.displayMetadata?.selectedVariantCode === CUSTOM_VARIANT_KEY)
      .forEach(key => {
        const displayMetadata = this.m.formatsMap.attribute[key].value.displayMetadata as DisplayAtMetadata;
        this.loadCardWidget$(displayMetadata.customVariantMetadata?.cardWidgetId as string, key).subscribe();
      });
    // links
    Object.keys(this.m.formatsMap.linkType)
      .filter(key => this.m.formatsMap.linkType[key].value.displayMetadata?.selectedVariantCode === CUSTOM_VARIANT_KEY)
      .forEach(key => {
        const displayMetadata = this.m.formatsMap.linkType[key].value.displayMetadata as DisplayAtMetadata;
        this.loadCardWidget$(displayMetadata.customVariantMetadata?.cardWidgetId as string, key).subscribe();
      });
  }

  private registerDisplayAtEvents(): void {
    const customVariantSubscription = this.displayAtControlService
      .getCustomVariantSelection$()
      .pipe(
        filter(datCustomVariant => !!this.widget?.id && datCustomVariant.ownerId === this.widget.id && !!datCustomVariant.cardWidgetId),
        switchMap(datCustomVariant => this.loadCardWidget$(datCustomVariant.cardWidgetId, datCustomVariant.attributeId)),
      )
      .subscribe();

    this.registerSubscription(customVariantSubscription);
  }

  private loadCardWidget$(cardWidgetId: string, attributeId: string): Observable<BlockPartWidget<any>> {
    return this.cache.data.widgets.get$(cardWidgetId).pipe(
      map(cardWidgetDto => {
        const widget = new BlockPartWidget(cardWidgetDto);
        const cardWidgetModelDto = { ...widget.value.model };
        const model = CardWidgetModel.fromDtoAndOptions(cardWidgetModelDto, this.m.options);
        widget.value = { model };
        this.setCardWidgetOptions(model, this.m.options);
        return widget;
      }),
      switchMap(widget => this.widgetService.loadCardInnerWidgets$(widget.value.model).pipe(map(() => widget))),
      tap(widget => this.displayAtControlService.doUpdateLoadedCustomVariantTemplate(this.widget.id as string, attributeId, widget)),
    );
  }

  private setCardWidgetOptions(model: CardWidgetModel, options: ArtifactWidgetOptions): void {
    model.options.attributes.setList(options.attributes.list, ID_KEY);
    model.options.systemAttributes.setList(options.systemAttributes.list, ID_KEY);
    model.options.dataTypes.setList(options.dataTypes.list, ID_KEY);
    model.options.artifactTypes.setList(options.artifactTypes.list, ID_KEY);
    model.options.linkTypes.setList(options.linkTypes.list, ID_KEY);
    model.options.applications.setList(options.applications.list, ID_KEY);
    model.options.users.setList(options.users.list, ID_KEY);
    model.options.pages.setList(options.pages.list, ID_KEY);
  }

  private clearForm(): void {
    this.m.form = [];
  }

  private onWidgetDataLoaded(): void {
    if (this.form) {
      this.form.triggerWidgetDataLoad();
      return;
    }

    setTimeout(() => this.onWidgetDataLoaded(), 50);
  }

  private shouldSkipWidgetReinitialization(artifactId: string | null, oldArtifactId: string | null, oldParams?: Params): boolean {
    if (artifactId && this.m.previousArtifactId === artifactId) return true;
    if (!artifactId && !this.artifactWidgetLoadedFirstTime(oldParams) && !oldArtifactId) return true;

    return false;
  }

  private artifactWidgetLoadedFirstTime(oldParams?: Params): boolean {
    return !!oldParams && !Object.keys(oldParams).length;
  }
}
