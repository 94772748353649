<div class="grid">
  <div class="col-12 mb-0 pb-0">
    <h4 *ngIf="m.selected?.treeItem?.usesDefaultStyle && (m.items.menu | isItemInFirstLevel : m.selected.menuItem)" class="my-0">
      Using default top level menu item style
    </h4>
    <h4 *ngIf="m.selected?.treeItem?.usesDefaultStyle && !(m.items.menu | isItemInFirstLevel : m.selected.menuItem)" class="my-0">
      Using default sub menu item style
    </h4>
    <h4 class="my-0" *ngIf="!m.selected?.treeItem?.usesDefaultStyle">Using own item style</h4>
  </div>

  <div class="col-12 mb-0" *ngIf="m.selected.treeItem">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox (onChange)="s.onDefaultStylesClick()" [(ngModel)]="m.selected?.treeItem.usesDefaultStyle" binary="true" id="defaultStyle"></p-checkbox>
        <label for="defaultStyle">{{ 'Use default styles' | translate }}</label>
      </span>
    </span>
  </div>

  <div class="col-12" *ngIf="m.selected.treeItem">
    <h6 class="mb-1">UI type</h6>
    <div class="col-12">
      <p-dropdown
        (onChange)="s.onDefaultStylesClick()"
        [(ngModel)]="m.selected.treeItem.uiType"
        [autoDisplayFirst]="false"
        [options]="m.options.menuItemUiType"
        id="e-type"
      >
      </p-dropdown>
    </div>

    <ng-container *ngIf="m.selected.treeItem.uiType === menuItemUiType.button">
      <app-action-list
              [actions]="m.selected.treeItem.actions"
              [showEmptyParamsAsActive]="m.selected?.treeItem.showEmptyParamsAsActive"
              [showMenuWidgetGroup]="true"
              (actionChange)="s.setTreeItemsToMenu()"
              (pageSelect)="s.onPageSelect($event)"
      ></app-action-list>
    </ng-container>

    <div class="col-6">
      <span class="dynamic-label">
        <p-dropdown
            [(ngModel)]="m.selected.treeItem.innerFlexDirection"
            (ngModelChange)="s.setTreeItemsToMenu()"
            [options]="m.options.flexDirection"
            appendTo="body"
            id="direction">
        </p-dropdown>
        <label class="dynamic-label-text" for="direction" translate> Direction </label>
      </span>
    </div>
  </div>
</div>
