import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayAtEnumModule } from '@shared/components/common-display-at';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { ArtifactFiltersModule } from '@widgets/shared/components/artifact-filters/artifact-filters.module';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ListItemClickActionSettingsFormComponent } from './list-item-click-action-settings-form.component';
import { ActionListModule } from '@widgets/shared/components/action-list/action-list.module';

@NgModule({
  declarations: [ListItemClickActionSettingsFormComponent],
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule,
		InputTextModule,
		RadioButtonModule,
		CheckboxModule,
		DropdownModule,
		ElvisSharedModule,
		ArtifactFiltersModule,
		DisplayAtEnumModule,
		ButtonModule,
		AccordionModule,
		RippleModule,
		ActionListModule,
	],
  exports: [ListItemClickActionSettingsFormComponent],
})
export class ListItemClickActionSettingsFormModule {}
