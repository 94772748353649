import { FilterType } from '@shared/types/table.types';
import { QueryParamsUtil } from '@shared/utils/query-params.util';

const removeIrrelevantFilters = (filters: Record<string, any>, originalFilters: Record<string, any>, attributesMap: Map<string, string>): void => {
  const queryParams = QueryParamsUtil.getQueryParamsFromUrlString();

  Object.keys(queryParams).forEach((key: string) => {
    const attributeId = attributesMap.get(key);
    if (!attributeId) return;

    filters[attributeId] = filters[attributeId]?.filter((filter: any) => {
      if (Array.isArray(filter.value)) {
        if (Array.isArray(queryParams[key])) return !filter.value?.every((value: any) => (queryParams[key] as any[]).includes(value));
        else return filter.value.length !== 1 || filter.value[0] !== queryParams[key];
      } else {
        return filter.value !== queryParams[key];
      }
    });

    if (!filters[attributeId]?.length && originalFilters[attributeId]) {
      filters[attributeId] = structuredClone(originalFilters[attributeId]);
    }
  });
};

/**
 * Changes '.' to a '___' in the names in the names of the table filters
 */
const formatTableFilterNamesToServer = (filters: Record<string, any>): void => {
  for (const key in filters) {
    if (key.includes('.')) {
      filters[key.replace(/\./g, '___')] = filters[key];
      delete filters[key];
    }
  }
};

/**
 * Changes '___' to a '.' in the names in the names of the table filters
 */
const formatTableFilterNamesToClient = (filters: Record<string, any>): void => {
  for (const key in filters) {
    if (key.includes('___')) {
      filters[key.replace(/___/g, '.')] = filters[key];
      delete filters[key];
    }
  }
};

const isFilterTypeDate = (filterType: FilterType | null): boolean => {
  return filterType === FilterType.date;
};

const isFilterTypeDateTime = (filterType: FilterType | null): boolean => {
  return filterType === FilterType.datetime;
};

export const TableMethods = {
  removeIrrelevantFilters,
  formatTableFilterNamesToServer,
  formatTableFilterNamesToClient,
  isFilterTypeDate,
  isFilterTypeDateTime,
};
