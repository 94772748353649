import { Injectable, Renderer2 } from '@angular/core';
import { SidebarModalWidgetComponent } from '../sidebar-modal-widget.component';
import {
  SidebarModalPosition,
  SidebarModalWidgetModel,
  SidebarModalWidgetModelDto,
  SidebarModalWidgetValue,
  SizeType,
  TemplateToModal,
  WidgetToModal,
} from '../types/sidebar-modal.types';
import { SidebarModalStyles } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-styles.types';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { PAGE_BUILDER_URL } from '@widgets/shared/constants/widget.constants';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageBuilderEventType } from '@private/pages/page-management/page-builder-graphical/types/page-builder-event-type';
import { PageBuilderGraphicalEventsService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-events.service';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { WidgetResponseDto } from '@shared/types/widget.types';
import { PageBuilderGraphicalDragDropService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-drag-drop.service';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';
import { SectionLocation } from '@private/pages/page-management/page-builder-graphical/types/section-location';
import { TemplatesCloseResultRole, TemplateType } from '@shared/components/templates/types/templates.types';
import { TemplateService } from '@shared/services/page-management/template.service';
import { BlockLocation } from '@private/pages/page-management/page-builder-graphical/types/block-location';
import { ToolbarActionsLayoutLocation } from '@private/pages/page-management/page-builder-graphical/types/toolbar-actions-layout-location';
import { RowLocation } from '@private/pages/page-management/page-builder-graphical/types/row-location';
import { PageBuilderGraphicalService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical.service';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { lastValueFrom } from 'rxjs';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { cloneDeep } from 'lodash';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { SidebarModalConstants } from '@widgets/sidebar-modal-widget/constants/constants';
import { PREVIEW_PENCIL_SELECTOR } from '@widgets/sidebar-widget/constants/sidebar-widget.constants';
import { PageBuilderGraphicalCopyPasterService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-copy-paster.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { RowDropEvent } from '@private/pages/page-management/page-builder-graphical/types/row-drop-event';
import { BlockPartDropEvent } from '@private/pages/page-management/page-builder-graphical/types/block-part-drop-event';
import { SidebarModalStateService } from '@widgets/sidebar-modal-widget/services/sidebar-modal-state.service';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { HideClasses, ScreenState } from '@shared/types/screen.types';
import { SidebarModalAction } from '@widgets/sidebar-modal-widget/types/sidebar-modal-actions.types';

@Injectable()
export class SidebarModalWidgetService {
  c: SidebarModalWidgetComponent;
  m: SidebarModalWidgetModel;

  private pageEl: Element | null;
  private resizeObserver: ResizeObserver | null;

  constructor(
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private renderer: Renderer2,
    private readonly eventsService: PageBuilderGraphicalEventsService,
    public readonly dragDropService: PageBuilderGraphicalDragDropService,
    private readonly templateService: TemplateService,
    public readonly tenantWidgetService: TenantWidgetService,
    private readonly widgetService: WidgetService,
    private readonly s: PageBuilderGraphicalService,
    private readonly localStorageService: LocalStorageService,
    private readonly stateService: SidebarModalStateService,
    public readonly pageBuilderGraphicalCopyPasterService: PageBuilderGraphicalCopyPasterService,
    private readonly translateUtil: TranslateUtil,
  ) {}

  init(context: SidebarModalWidgetComponent, dto?: SidebarModalWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new SidebarModalWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new SidebarModalWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
    this.m.options.initOptions(this.translateUtil);
    this.storeSizeState();

    if (!this.c.isLayoutMode) return;

    !this.m.id && (this.m.id = 'sm-' + (this.c.widget.id || ElvisUtil.makeHash(16)));
    this.m.page.modalId = this.m.id;
    this.m.page.sections.forEach(s => (s.modalId = this.m.id));
    this.dragDropService.setDropListConnectionIds(this.m.page.sections, false, this.m.id);

    context.registerSubscriptions([
      this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<unknown>) => {
        if (event.type === RuntimeStateNotificationEnum.toggleSystemPanel || event.type === RuntimeStateNotificationEnum.togglePageBuilderHeaderVisible) {
          this.handler();
        }
        if (event.type === RuntimeStateNotificationEnum.customEvent) {
          this.actionHandler((event.data as any).type);
        }

      }),
      this.eventsService.subscribe<WidgetToModal>(PageBuilderEventType.addWidgetToModal, (payload: WidgetToModal) => this.onAddWidget(payload)),
      this.eventsService.subscribe<TemplateToModal>(PageBuilderEventType.addTemplateToModal, (payload: TemplateToModal) => this.onAddTemplate(payload)),
      this.eventsService.subscribe<PartLocation>(PageBuilderEventType.addSection, (payload: SectionLocation) => this.onAddSection(payload)),
      this.eventsService.subscribe<PartLocation>(PageBuilderEventType.addBlockPart, (payload: BlockLocation) => this.onAddBlockPart(payload)),
      this.eventsService.subscribe<ToolbarActionsLayoutLocation>(PageBuilderEventType.copy, (payload: ToolbarActionsLayoutLocation) => this.onCopy(payload)),
      this.eventsService.subscribe<ToolbarActionsLayoutLocation>(PageBuilderEventType.paste, (payload: ToolbarActionsLayoutLocation) => this.onPaste(payload)),
      this.eventsService.subscribe<ToolbarActionsLayoutLocation>(PageBuilderEventType.delete, (payload: ToolbarActionsLayoutLocation) =>
        this.onDelete(payload),
      ),
      this.eventsService.subscribe<CdkDragDrop<RowDropEvent>>(PageBuilderEventType.rowDrop, (payload: CdkDragDrop<RowDropEvent>) => this.onRowDrop(payload)),
      this.eventsService.subscribe<CdkDragDrop<BlockPartDropEvent>>(PageBuilderEventType.blockPartDrop, (payload: CdkDragDrop<BlockPartDropEvent>) =>
        this.onBlockPartDrop(payload),
      ),
    ]);

    this.resizeObserver = new ResizeObserver(() => {
      this.m.isOpen && this.m.settings.byPageContent && (this.m.styles = new SidebarModalStyles(this.m.settings));
      setTimeout(() => {
        if (this.isHideSection()) {
          this.m.isOpen = false;
          this.m.settings.isInside && this.clearContentPadding();
        } else if (this.m.settings.isFixed) {
          !this.m.toggled && (this.m.isOpen = true);
          this.m.isOpen && this.render();
        }
      }, 10);
    });
    const className = this.isPageBuilderPage() ? 'page-builder' : 'page';
    const el = document.getElementsByClassName(className)[0];
    el && this.resizeObserver.observe(el);

    if (this.m.settings.isFixed || this.m.settings.showOnInit || this.m.settings.storeState) {
      setTimeout(() => {
        if (this.isHideSection()) {
          return;
        }

        this.m.isOpen = true;

        if (this.m.settings.storeState) {
          const state = this.stateService.getState(this.c.widget.id as string);
          state !== null && (this.m.isOpen = state);
        }

        this.m.isOpen && this.render();
      });
    }
  }

  isHideSection(): boolean {
    const elem = (document as any).getElementById('cont' + this.m.id);
    const parent = elem?.closest('.outer-container') || elem?.closest('.section');
    const body = (document as any).body;
    return (
      (body.classList.contains(ScreenState.pc) && parent.classList.contains(HideClasses.pc)) ||
      (body.classList.contains(ScreenState.tablet) && parent.classList.contains(HideClasses.tablet)) ||
      (body.classList.contains(ScreenState.mobile) && parent.classList.contains(HideClasses.mobile))
    );
  }

  onDestroy(): void {
    if (this.c.isLayoutMode) {
      const el = document.querySelector(`#${this.m.id}`);
      el && el.remove();
      const overlay = document.querySelector(`#${this.m.id}-over`);
      overlay && overlay.remove();
      // remove blur
      const appRoot: any = document.querySelector(`app-limited-main`) || document.querySelector(`app-page-preview`);
      appRoot?.style && (appRoot.style.filter = 'none');

      this.resizeObserver && this.resizeObserver.disconnect();
      this.clearContentPadding();
      this.setPencilPosition(this.m.settings.position, '5px');
    }
  }

  storeSidebarState(): void {
    this.m.page.sections.forEach(section => {
      section.partsWithWidgets.forEach((part: PageBlockPart) => {
        if (!part.widget) {
          return;
        }

        const originalWidgetModel = part.widget.value.model;
        part.widget?.saveStateBeforeRelocation();

        if (part.widget?.code === WidgetType.listNew) {
          const originalWidgetModelState = this.localStorageService.get(originalWidgetModel.hash);
          if (originalWidgetModelState) {
            part.widget.value.model.state = originalWidgetModelState;
          }
        }
      });
    });
  }

  onBorderShadowChange(): void {
    this.m.styles.applyStyles(this.m.settings.styles);
  }

  onOverlayModeChange(): void {
    !this.m.settings.isOverlay && (this.m.settings.isBlur = false);
    this.render();
  }

  onOverlayClick(): void {
    this.m.settings.isCloseByOverlayClick && this.toggleModal();
  }

  toggleModal(): void {
    this.m.isOpen = !this.m.isOpen;
    this.m.toggled = true;

    setTimeout(() => {
      this.m.toggled = false;
    }, 100);

    if (this.m.isOpen) {
      this.render();
    } else {
      this.m.settings.isInside && this.clearContentPadding();
      this.storeSidebarState();
      this.setPencilPosition(this.m.settings.position, '5px');
      this.updateBlur();
    }

    this.m.settings.storeState && this.stateService.setState(this.c.widget.id || this.m.id, this.m.isOpen);
  }

  storeSizeState(): void {
    const { position, width, height } = this.m.settings;
    this.stateService.setSize(position, { width, height });
  }

  afterChangePositionAction(): void {
    const { position, prevPosition } = this.m.settings;
    const { left, right, top, bottom, center } = SidebarModalPosition;

    const size = this.stateService.getSize(position);
    if (size) {
      this.m.settings.width = size.width;
      this.m.settings.height = size.height;
      return;
    }

    if (position === center) {
      this.m.settings.width = SidebarModalConstants.DEFAULT_CENTER_WIDTH;
      this.m.settings.height = SidebarModalConstants.DEFAULT_CENTER_HEIGHT;
    } else if (position === left || position === right) {
      if (prevPosition !== left && prevPosition !== right) {
        this.m.settings.width = SidebarModalConstants.DEFAULT_WIDTH;
        this.m.settings.height = SidebarModalConstants.FULL_SIZE;
      }
      // for top or bottom position case
    } else if (prevPosition !== top && prevPosition !== bottom) {
      this.m.settings.height = SidebarModalConstants.DEFAULT_HEIGHT;
      this.m.settings.width = SidebarModalConstants.FULL_SIZE;
    }
  }

  render(): void {
    if (!this.m.isOpen) return;

    this.m.styles = new SidebarModalStyles(this.m.settings);
    const { left, right, top, bottom } = SidebarModalPosition;
    const position = this.m.settings.position;

    if (this.m.settings.isInside) {
      let paddingValue: string = position === left || position === right ? this.m.styles.width : this.m.styles.height;
      paddingValue && !paddingValue.includes(SizeType.px) && !paddingValue.includes(SizeType.percent) && (paddingValue += SizeType.px);
      paddingValue.includes(SizeType.percent) && (paddingValue = this.m.styles.getValuePx(paddingValue, position === top || position === bottom) + SizeType.px);
      this.setContentPadding(`padding-${position}`, paddingValue);
    }
    (position === right || position === top) && this.setPencilPosition(position);

    setTimeout(() => {
      const el = document.querySelector(`#${this.m.id}`);
      const overlay = document.querySelector(`#${this.m.id}-over`);
      el && document.body.append(el);
      overlay && document.body.append(overlay);
      this.updateBlur();
    });
  }

  updateBlur(): void {
    const appRoot: any = document.querySelector(`app-limited-main`) || document.querySelector(`app-page-preview`);
    let value: string = this.m.settings.blurValue || SidebarModalConstants.DEFAULT_BLUR;
    const parsed = parseInt(value, 10);
    value.length === parsed.toString().length && (value += 'px');
    const filter = this.m.settings.isBlur && this.m.isOpen ? `blur(${value})` : 'none';
    appRoot?.style && (appRoot.style.filter = filter);
  }

  generateNewSection(scheme: string[], previousSectionIndex: number): void {
    const section = new PageSection(this.generatePageRows(scheme));
    section.modalId = this.m.id;
    this.m.page.insertSection(previousSectionIndex, section);
    this.dragDropService.setDropListConnectionIds(this.m.page.sections, true, this.m.id);
  }

  async onAddSection({ sectionIndex, modalId }: SectionLocation): Promise<void> {
    if (modalId !== this.m.id) return;
    await this.showSectionTemplates(sectionIndex);
  }

  async showSectionTemplates(previousSectionIndex: number = this.m.page.sections.length - 1): Promise<void> {
    const { role, templates, sectionScheme } = await this.templateService.pickTemplate(TemplateType.section);

    for (const template of templates || []) {
      if (role === TemplatesCloseResultRole.reuseTemplate) {
        await this.s.pasteSectionTemplateAsReused(template!, previousSectionIndex, this.m.page, this.m.id);
      }

      if (role === TemplatesCloseResultRole.copyTemplate) {
        await this.s.pasteSectionTemplateAsCopy(template!, previousSectionIndex, this.m.page, this.m.id);
      }
    }

    if (role === TemplatesCloseResultRole.generateSection) {
      this.generateNewSection(sectionScheme!, previousSectionIndex);
    }
  }

  addBlockPart(block: PageBlock): void {
    block.parts.push(new PageBlockPart());
    this.dragDropService.setDropListConnectionIds(this.m.page.sections, true, this.m.id);
  }

  private setPencilPosition(position: SidebarModalPosition, _value?: string): void {
    const { right, top } = SidebarModalPosition;
    if (position !== right && position !== top) return;

    const value = _value ? _value : this.m.isOpen ? this.getPaddingValue(position) : SidebarModalConstants.PENCIL_PADDING;
    const element = (document as any).querySelector(PREVIEW_PENCIL_SELECTOR);
    element && (element.style[position] = value);
  }

  private getPaddingValue(position: SidebarModalPosition): string {
    if (!this.m.styles) return '';

    const defaultUnit = SidebarModalConstants.DEFAULT_UNIT;
    const { height, width, getValuePx } = this.m.styles;
    const { topGap, rightGap } = this.m.settings;
    const padding = getValuePx(SidebarModalConstants.PENCIL_PADDING);


    if ([SidebarModalPosition.top, SidebarModalPosition.bottom].includes(position)) {
      return getValuePx(height, true) + getValuePx(topGap, true) + padding + defaultUnit;
    }

    return getValuePx(width) + getValuePx(rightGap) + padding + defaultUnit;
  }

  private onRowDrop(event: CdkDragDrop<RowDropEvent>): void {
    if (event.container.data.modalId !== this.m.id) return;
    this.dragDropService.dropRow(event, this.m.page);
  }

  private onBlockPartDrop(event: CdkDragDrop<BlockPartDropEvent>): void {
    if (event.container.data.modalId !== this.m.id || event.previousContainer.data.modalId !== this.m.id) return;
    this.dragDropService.dropBlockPart(event, this.m.page);

    if (this.m.page.modalId !== event.previousContainer.data.modalId) {
      const { blockIndex, modalId, rowIndex, sectionIndex } = event.previousContainer.data;
      const { partIndex } = event.item.data;
      this.eventsService.publish<PartLocation>(PageBuilderEventType.delete, { blockIndex, modalId, partIndex, rowIndex, sectionIndex });
    }
  }

  private onCopy(location: ToolbarActionsLayoutLocation): void {
    if (location.modalId !== this.m.id) return;
    const layout = this.m.page.getByLocation(location);
    this.pageBuilderGraphicalCopyPasterService.copyLayout(layout);
  }

  private onPaste(location: ToolbarActionsLayoutLocation): void {
    if (location.modalId !== this.m.id) return;
    const layout = this.m.page.getByLocation(location);
    this.pageBuilderGraphicalCopyPasterService.pasteLayout(layout);
    this.dragDropService.setDropListConnectionIds(this.m.page.sections, true, this.m.id);
  }

  private onDelete(location: PartLocation | RowLocation | SectionLocation): void {
    if (location.modalId !== this.m.id) return;

    if ('partIndex' in location) {
      this.s.deleteBlockPart(location, this.m.page);

      return;
    }

    if ('rowIndex' in location) {
      this.s.deleteRow(location, this.m.page);

      return;
    }

    this.s.deleteSection(location, this.m.page);
  }

  private onAddBlockPart(location: BlockLocation): void {
    if (location.modalId !== this.m.id) return;

    const block = this.m.page.getByLocation<PageBlock>(location);
    this.addBlockPart(block);
  }

  private async onAddTemplate(data: TemplateToModal): Promise<void> {
    const { templates, role } = data.templateResult;
    const part: PageBlockPart | null = this.m.page.getByLocation(data.location);

    for (const template of templates || []) {
      await this.pasteWidgetTemplate(template!, part!, role === TemplatesCloseResultRole.reuseTemplate);
    }
  }

  private onAddWidget(data: WidgetToModal): void {
    if (data.location.modalId !== this.m.id) return;
    const blockPartForWidgetPlacement: PageBlockPart | null = this.m.page.getByLocation(data.location);
    const widget = new BlockPartWidget({ code: data.code, value: {} } as WidgetResponseDto);
    blockPartForWidgetPlacement!.widget = widget;
  }

  private async pasteWidgetTemplate(template: TemplateResponseDto, part: PageBlockPart, isReused?: boolean): Promise<void> {
    const dto = await lastValueFrom(this.tenantWidgetService.widgetControllerGet({ id: (template.template as any).widgetId }));

    if (dto.code === WidgetType.sidebar || (dto as any).code === WidgetType.sidebarModal) return;

    part.widget = new BlockPartWidget(cloneDeep(dto));

    if (isReused) {
      part.widget.templateId = template.id;
      part.widget.templateName = template.name;
    } else {
      part.widget.id = null;
    }

    await this.widgetService.loadInnerWidgetsForPartsWithCard([part]);
  }

  private generatePageRows(rows: string[]): PageRow[] {
    return rows.map(row => {
      const layout = row.split('+').filter(item => !!item);
      return new PageRow(
        layout.map(() => new PageBlock([new PageBlockPart()])),
        layout,
        null,
      );
    });
  }

  private clearContentPadding(): void {
    this.setContentPadding(`padding-${this.m.settings.position}`, '0');
  }

  private setContentPadding(paddingType: string, value: string): void {
    this.pageEl ??= document.getElementsByClassName('page')[0];
    this.pageEl && this.renderer.setStyle(this.pageEl, paddingType, value);
  }

  private isPageBuilderPage(): boolean {
    return window.location.href.includes(PAGE_BUILDER_URL);
  }

  private actionHandler(type: string): void {
    this.m.settings.actionSettings.events.forEach(event => {
      const { open, toggle } = SidebarModalAction;

      if (type !== event.eventType || !event.action) return;

      this.m.isOpen = event.action === toggle ? !this.m.isOpen: (event.action === open);
      this.m.settings.storeState && this.stateService.setState(this.c.widget.id || this.m.id, this.m.isOpen);

      this.render();
      !this.m.isOpen && this.updateBlur();
    });
  }

  private handler(): void {
    if (!this.m.isOpen) {
      return;
    }

    this.storeSidebarState();
    this.m.isOpen = false;
    setTimeout(() => {
      this.m.isOpen = true;
      this.render();
    }, 200);
  }
}
