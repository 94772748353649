export enum AttributeActionType {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  SET_MANDATORY = 'SET_MANDATORY',
  SET_OPTIONAL = 'SET_OPTIONAL',
  SET_EDITABLE = 'SET_EDITABLE',
  SET_READONLY = 'SET_READONLY',
  SET = 'SET',
  FORMAT = 'FORMAT',
}
