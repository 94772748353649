<p-table
  #table
  *ngIf="settings?.showTable && (columns$ | async) as columns"
  (cdkDropListDropped)="onRowDrop($event)"
  (onColResize)="onColumnsResize()"
  (onColReorder)="onReorder($event)"
  (onLazyLoad)="onLazyLoad($event)"
  (onRowSelect)="artifactSelect.emit($event.data)"
  (onRowUnselect)="artifactUnselect.emit($event.data)"
  [columns]="columns"
  [lazy]="true"
  [loading]="loading$ | async"
  [ngClass]="{ sticky: settings.sticky }"
  [reorderableColumns]="true"
  [resizableColumns]="true"
  [rowTrackBy]="rowTrackByFn"
  [rows]="settings.rowsPerPage"
  [scrollable]="true"
  [(selection)]="selection"
  [rowSelectable]="isRowSelectable.bind(this)"
  [selectionMode]="getSelectionMode()"
  [metaKeySelection]="false"
  [stateKey]="ids.hash"
  styleClass="no-highlight-sortable {{ getStyleClass(settings) }}"
  [tableStyleClass]="settings.responsive === 'rebuild' && 'p-datatable-responsive alw-table'"
  [totalRecords]="meta.totalCount"
  [value]="groupData$ | async"
  [cdkDropListConnectedTo]="widgetDataShareService.folderIds"
  cdkDropList
  [columnResizeMode]="settings.columnResizeMode"
  [scrollHeight]="settings.scrollHeight"
  dataKey="id"
  editMode="row"
  stateStorage="local"
  appThirdClickUndoSort
>
  <ng-template pTemplate="caption">
    <app-artifact-list-widget-table-caption [addButtonItems]="addButtonItems" [selected]="selected"
                                            [settings]="settings" (onExport)="exportList()">
    </app-artifact-list-widget-table-caption>
  </ng-template>

  <ng-template pTemplate="colgroup">
    <colgroup>
      <col class="fixed-width-col-short" />
      <col *ngFor="let col of columns" class="col-standard" />
      <col *ngIf="settings.editableRow" class="fixed-width-col-buttons" />
    </colgroup>
  </ng-template>

  <ng-template pTemplate="sorticon" let-sortOrder>
    <i
      class="p-sortable-column-icon pi pi-fw"
      [ngClass]="{ 'pi-sort-amount-up-alt': sortOrder === 1, 'pi-sort-amount-down': sortOrder === -1, 'pi-sort-alt': sortOrder === 0 }"
    ></i>
  </ng-template>

  <ng-template let-columns pTemplate="header">
    <tr
      [ngStyle]="{ 'background-color': settings.customColors && settings.background.header, sticky: settings.sticky }">
      <th [pTooltip]="m.tooltipFiltersOutsideView" pResizableColumn style="width: 3rem" tooltipPosition="left"
          [ngStyle]="settings.cellPaddings">
        <span *ngIf="m.tooltipFiltersOutsideView.length" class="pi pi-filter-icon pi-filter"></span>
      </th>

      <!--<ht *ngIf="settings.multiselect">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </ht>-->

      <th *ngFor="let col of columns" class="sort-parent column-header p-text-truncate"
          [ngStyle]="settings.cellPaddings" pReorderableColumn pResizableColumn>
        <app-artifact-list-table-header-column
          [tableUniqueId]="ids.hash"
          [column]="col"
          [sortableColumnKeys]="m.columns.sortableKeys"
          [lwOptions]="options"
          [currentUser]="m.currentUser"
          [disabled]="settings.grouping.groupingAttributes[0] | isColumnFilterDisabled : col"
          [selectedLinkFilters]="selected.linkFiltersNew"
          [selectedDateFilterObject]="selected.dateFilters[col.key]"
          [selectedFolderFilters]="selected.folderFilter"
          [selectedUserFilter]="selected.userFilters[col.key]"
          [teamsWithUsers]="teamsWithUsers"
          [onLinkFilterApplyCb]="onLinkFilterApply.bind(this)"
          [onLinkFilterClearCb]="onLinkFilterClear.bind(this)"
        >
        </app-artifact-list-table-header-column>
      </th>
      <th *ngIf="settings.editableRow" [ngStyle]="settings.cellPaddings"></th>
    </tr>
  </ng-template>

  <ng-template let-groupItem let-columns="columns" let-editing="editing" let-groupIndex="rowIndex" pTemplate="body">
    <!--    <app-artifact-list-table-group [template]="alwTableCell"-->
    <!--                                   [m]="m"-->
    <!--                                   [groupItem]="group"-->
    <!--                                   [columns]="columns"-->
    <!--                                   [settings]="settings"-->
    <!--                                   [options]="options"-->
    <!--                                   [groupIndex]="index"-->
    <!--                                   [editing]="editing"-->
    <!--                                   [canShowTooltipMap]="canShowTooltipMap"-->
    <!--                                   [columnFileTypeMap]="columnFileTypeMap"-->
    <!--                                   [columnHtmlTypeMap]="columnHtmlTypeMap"-->
    <!--                                   [onRowEditInit]="onRowEditInit.bind(this)"-->
    <!--                                   [onRowEditSave]="onRowEditSave.bind(this)"-->
    <!--                                   [handleAddLinkClick]="handleAddLinkClick.bind(this)"-->
    <!--                                   [handleDeleteLinkClick]="handleDeleteLinkClick.bind(this)">-->
    <!--    </app-artifact-list-table-group>-->

    <ng-container *ngIf="!groupItem.isDefaultGroup && (settings.grouping.showEmptyGroups || groupItem.total)">
      <tr (click)="toggleGroup(groupItem)" class="row-group">
        <td [attr.colspan]="settings.editableRow ? columns.length + 2 : columns.length + 1">
          <h5 class="row-group-header">
            <button
              [icon]="groupItem.metaData?.toggled ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              class="p-button-text p-button-rounded p-button-plain mr-2"
              pButton
              pRipple
              type="button"
            ></button>
            <span class="row-group-header__value">{{ groupItem.metaData?.header }}</span>
            <p-badge [value]="groupItem.total || 0" size="medium"></p-badge>
          </h5>
        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="groupItem.isDefaultGroup || groupItem.metaData?.toggled">
      <tr
        #row
        *ngFor="let artifact of getDataForGroup$(groupItem) | async; trackBy: artifactTrackByFn; let rowIndex = index"
        [style.background-color]="
          row.classList.contains('p-highlight') || !settings.customColors
            ? null
            : settings.rowsStriped && rowIndex % 2
            ? settings.background.rowsOdd
            : settings.background.rows
        "
        [pEditableRow]="artifact"
        [pSelectableRow]="artifact"
        [ngClass]="{'disabled' : isRowDisabledByLinkPopup(artifact)}"
        [cdkDragData]="settings.multiselect && selection.length ? selection : artifact"
        cdkDrag
        class="item-draggable"
      >
        <div *cdkDragPlaceholder class="item-placeholder"></div>
        <td class="td-center-content" [ngStyle]="settings.cellPaddings">
          <span cdkDragHandle class="bi bi-arrows-move"></span>
        </td>
        <!--<td *ngIf="settings.rowClickHandle && settings.rowClickHandleAction === 'selectItem'">
          <p-tableCheckbox [value]="artifact"></p-tableCheckbox>
        </td>-->
        <td *ngFor="let col of columns" [ngStyle]="settings.cellPaddings">
          <div class="example-custom-preview" *cdkDragPreview>
            <ng-container
              [ngTemplateOutlet]="alwTableCell"
              [ngTemplateOutletContext]="{ col: col, artifact: artifact, index: +('' + groupIndex + rowIndex) }"
            ></ng-container>
          </div>
          <div class="table-cell-content">
            <!-- TODO check and remove this commented code -->
            <!-- <span [ngClass]="{'p-text-truncate': settings.textWrap === 'nowrap', 'p-text-wrap': settings.textWrap === 'wrap'}" class="p-column-title">
                {{col.label}}
            </span> -->

            <p-cellEditor *ngIf="col.key | isInStringArray : m.columns.editableKeys; else simple"
                          class="full-size-container">
              <ng-template pTemplate="input">
                <ng-container
                  [ngTemplateOutlet]="alwTableCell"
                  [ngTemplateOutletContext]="{
                    col: col,
                    artifact: artifact,
                    index: +('' + groupIndex + rowIndex),
                    editMode: true,
                    isMandatory: isMandatoryField(artifact, col.key)
                  }"
                >
                </ng-container>
              </ng-template>

              <ng-template pTemplate="output">
                <ng-container
                  [ngTemplateOutlet]="alwTableCell"
                  [ngTemplateOutletContext]="{ col: col, artifact: artifact, index: +('' + groupIndex + rowIndex) }"
                >
                </ng-container>
              </ng-template>
            </p-cellEditor>

            <ng-template #simple>
              <ng-container
                [ngTemplateOutlet]="alwTableCell"
                [ngTemplateOutletContext]="{ col: col, artifact: artifact, index: +('' + groupIndex + rowIndex), simple: true }"
              >
              </ng-container>
            </ng-template>
          </div>
        </td>
        <td *ngIf="settings.editableRow" class="utility-buttons" [ngStyle]="settings.cellPaddings"
            [ngClass]="m.isInSavingProcess ? 'no-active': ''">
          <button
            *ngIf="!currentlyEditedRowsMap['editingButton' + groupIndex + rowIndex]"
            (click)="onRowEditInit(artifact, '' + groupIndex + rowIndex)"
            [id]="'editingButton' + groupIndex + rowIndex"
            class="p-button-rounded p-button-text"
            icon="pi pi-pencil"
            pButton
            pInitEditableRow
            pRipple
            type="button"
          ></button>
          <button
            *ngIf="!currentlyEditedRowsMap['editingButton' + groupIndex + rowIndex]"
            (click)="onNavigateToArtifact(artifact)"
            class="p-button-rounded p-button-text"
            icon="pi pi-eye"
            pButton
            pRipple
            type="button"
            [pTooltip]="'Edit complete entry' | translate"
          ></button>
          <button
            *ngIf="currentlyEditedRowsMap['editingButton' + groupIndex + rowIndex]"
            (click)="onRowEditSave(artifact, '' + groupIndex + rowIndex)"
            class="p-button-rounded p-button-text p-button-success"
            icon="pi pi-check"
            pButton
            pRipple
            pSaveEditableRow
            type="button"
          ></button>
          <button
            *ngIf="currentlyEditedRowsMap['editingButton' + groupIndex + rowIndex]"
            (click)="helper.onRowEditCancel(artifact, rowIndex, groupIndex, currentlyEditedRowsMap)"
            class="p-button-rounded p-button-text p-button-danger"
            icon="pi pi-times"
            pButton
            pCancelEditableRow
            pRipple
            type="button"
          ></button>

          <button
            pButton
            pRipple
            type="button"
            class="p-button p-button-rounded p-button-text"
            icon="pi pi-ellipsis-v"
            (click)="onThreeDotsClick($event, op, artifact)"
          ></button>

          <p-overlayPanel #op appendTo="body">
            <div class="flex flex-column">
              <p-button
                *ngIf="selection?.length > 1 && !currentlyEditedRowsMap['editingButton' + groupIndex + rowIndex]"
                pRipple
                styleClass="p-button-text"
                type="button"
                (click)="onEditSelectionClick()"
              >
                <i class="pi pi-pencil mr-2 pi--warning"></i>
                <span class="button-text">
                  {{ selection | getButtonLabel : 'Edit' | translate : { count: selection?.length } }}
                </span>
              </p-button>

              <p-button
                *ngIf="!currentlyEditedRowsMap['editingButton' + groupIndex + rowIndex]"
                pRipple
                styleClass="p-button-text"
                type="button"
                (click)="helper.onArtifactDeleteClick(artifact)"
              >
                <i class="pi pi-trash mr-2 pi--danger"></i>
                <span class="button-text">
                  {{ selection | getButtonLabel : 'Delete' | translate : { count: selection?.length } }}
                </span>
              </p-button>

              <p-button
                *ngIf="!currentlyEditedRowsMap['editingButton' + groupIndex + rowIndex]"
                pRipple
                styleClass="p-button-text"
                type="button"
                (click)="op.hide(); onMoveSelectionClick(folderPicker)"
              >
                <i class="pi pi-folder mr-2"></i>
                <span class="button-text">
                  {{ selection | getButtonLabel : 'Move' | translate : { count: selection?.length } }}
                </span>
              </p-button>

              <p-button
                pCancelEditableRow
                pRipple
                styleClass="p-button-text"
                type="button"
                (click)="m.artifactIdForAcl = artifact.id; accessOverlay.show($event)"
              >
                <i class="pi pi-users mr-2"></i>
                <span [style.color]="'var(--text-color)'">
                  {{ 'Access rights' | translate }}
                </span>
              </p-button>
            </div>

            <app-artifact-list-widget-table-module-utility-buttons
              *ngIf="settings.loadMode | isLoadByModule"
              [artifact]="artifact"
              [rowIndex]="rowIndex"
              [addButtonItems]="addButtonItems"
              [data]="data"
              [settings]="settings"
              [latestLazyLoadEvent]="latestLazyLoadEvent"
              [latestProcessedFilters]="latestProcessedFilters"
              [invokeLazyLoadData]="invokeLazyLoadData.bind(this)"
            ></app-artifact-list-widget-table-module-utility-buttons>
          </p-overlayPanel>
        </td>
      </tr>

      <ng-container *ngIf="settings.showSum && groupItem.artifacts.length">
        <tr>
          <td class="summary"><span class="pi pi-info-circle"></span></td>
          <td *ngFor="let col of columns">
            <div *ngIf="col.meta?.attributeMetadata?.attribute?.id | isNumericColumn: m.attributeSummaryItems">
              <i
                [ngClass]="col.meta?.attributeMetadata?.attribute?.id | getListAttributeIcon: m.attributeSummaryItems"></i>
              <span>
                {{
                  col
                    | getArtifactsSummary : col.meta?.attributeMetadata?.attribute?.id : m.attributeSummaryItems : meta : this.isGroupingActive() : groupItem
                    | useDecimalPlaces : settings.minDecimalPlacesOfSum : settings.maxDecimalPlacesOfSum
                }}
              </span>
            </div>
          </td>
          <td *ngIf="settings.editableRow"></td>
        </tr>
      </ng-container>

      <tr
        *ngIf="settings.rowsPerPage | shouldShowGroupPaginator : totalCountMap[groupItem.id] : settings.grouping.pagination">
        <td [colSpan]="settings.editableRow ? columns.length + 2 : columns.length + 1" class="group-paginator">
          <p-paginator [rows]="settings.rowsPerPage" [totalRecords]="totalCountMap[groupItem.id]"
                       (onPageChange)="onPageChange($event, groupItem)">
          </p-paginator>
        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="groupItems | isTableEmpty">
      <tr>
        <td [colSpan]="settings.editableRow ? columns.length + 2 : columns.length + 1"
            translate>{{ settings.emptyMessage }}
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>

<p-paginator
  *ngIf="settings.rowsPerPage | shouldShowSharedPaginator : meta.totalCount : settings.grouping.pagination : settings.grouping.groupingAttributes"
  [style]="{ 'background-color': (settings.customColors && settings.background.footer) || null }"
  [styleClass]="settings.customColors ? 'no-background' : ''"
  [first]="latestLazyLoadEvent?.first || 0"
  [rows]="settings.rowsPerPage"
  [totalRecords]="meta.totalCount"
  (onPageChange)="onPageChange($event)"
>
</p-paginator>

<div *ngIf="settings.showCount && settings.grouping.pagination === paginationSettingEnum.perTable">
  <div *ngIf="data.length; else emptyCount">
    Showing {{ currentPage * settings.rowsPerPage + 1 }} to {{ currentPage * settings.rowsPerPage + data.length }} out
    of total {{ meta.totalCount }} entries
  </div>
  <ng-template #emptyCount> Data is empty</ng-template>
</div>

<div *ngIf="isNumberOfSelectedVisible">
  {{ selection.length }} {{ 'entries selected' | translate }}
</div>

<!-- element for reloading data after sse events -->
<div *ngIf="isPaginationByTable && sseAddedItems" class="alw-table-reload-element">
  <span>* {{ 'Some data updates occurred on the server' | translate }}</span>
  <button class="p-button p-button-secondary" label="{{ 'Reload data' | translate }}" pButton type="button"
          (click)="reloadDataOnSse()"></button>
</div>

<ng-template #alwTableCell let-col="col" let-artifact="artifact" let-index="index" let-editMode="editMode"
             let-simple="simple" let-isMandatory="isMandatory">
  <app-artifact-list-table-cell
    [tableUniqueId]="ids.hash"
    [column]="col"
    [options]="options"
    [artifact]="artifact"
    [rowIndex]="index"
    [settings]="settings"
    [links]="m.links"
    [linkedData]="m.linkedData"
    [linksDto]="m.linksDto"
    [files]="m.files"
    [unSavedFiles]="m.unSavedFiles"
    [editMode]="editMode"
    [isInSavingProcess]="m.isInSavingProcess"
    [isMandatory]="isMandatory"
    [isSimple]="simple"
    [tooltipEnabled]="canShowTooltipMap[col.key + index]"
    [filesLoaded]="m.filesLoaded"
    class="block-container full-size-container"
    (onAddLinkClick)="handleAddLinkClick($event)"
    (onDeleteLinkClick)="handleDeleteLinkClick($event)"
  >
  </app-artifact-list-table-cell>
</ng-template>

<!-- new link popup -->
<!-- <app-link-popup #linkDialog [applicationId]="ids.applicationId" [loadDataMethod]="loadDataMethod" [dtoMap]="linkingPopupDtoMap"> </app-link-popup> -->

<p-confirmDialog [autoZIndex]="true" key="deleteDialog"></p-confirmDialog>

<app-alw-table-header-menu [tableUniqueId]="ids.hash"></app-alw-table-header-menu>
<app-display-at-overlay [ownerId]="ids.hash"></app-display-at-overlay>

<!-- [originArtifactTypes]="selected.artifactTypes" -->

<app-folder-picker
  #folderPicker
  [setLayoutMode]="true"
  [showTenant]="true"
  (changeFolder)="onFolderSelect($event)">
</app-folder-picker>

<p-overlayPanel #accessOverlay [style]="{ 'max-width': '380px', width: '361px' }" appendTo="body">
  <ng-template pTemplate>
    <app-acl [artifactId]="m.artifactIdForAcl" (updateAcl)="accessOverlay.hide()"></app-acl>
  </ng-template>
</p-overlayPanel>
