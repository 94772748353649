export function Debounce(timeout: number) {
  let timeoutRef: any = null;

  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function (...args: any[]) {
      timeoutRef && clearTimeout(timeoutRef);
      timeoutRef = setTimeout(() => original.apply(this, args), timeout);
    };

    return descriptor;
  };
}
