import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApplicationSelectionComponent } from '@limited/pages/application-selection/application-selection.component';
import { LimitedMainComponent } from '@limited/pages/limited-main/limited-main.component';
import { LimitedMainService } from '@limited/pages/limited-main/services/limited-main.service';
import { AdminMainComponent } from '@private/admin-main.component';
import { AppMainService } from '@private/app.main.service';
import { ApplicationListComponent } from '@private/pages/application-management/application-list/application-list.component';
import { ApplicationComponent } from '@private/pages/application-management/application/application.component';
import { ArtifactListComponent } from '@private/pages/artifact-management/artifact-list/artifact-list.component';
import { ArtifactComponent } from '@private/pages/artifact-management/artifact/artifact.component';
import { ArtifactTypeListComponent } from '@private/pages/artifact-type-management/artifact-type-list/artifact-type-list.component';
import { ArtifactTypeComponent } from '@private/pages/artifact-type-management/artifact-type/artifact-type.component';
import { AttributeListComponent } from '@private/pages/artifact-type-management/attribute-list/attribute-list.component';
import { AttributeComponent } from '@private/pages/artifact-type-management/attribute/attribute.component';
import { DataTypeListComponent } from '@private/pages/artifact-type-management/data-type-list/data-type-list.component';
import { DataTypeComponent } from '@private/pages/artifact-type-management/data-type/data-type.component';
import { LinkTypeListComponent } from '@private/pages/artifact-type-management/link-type-list/link-type-list.component';
import { LinkTypeComponent } from '@private/pages/artifact-type-management/link-type/link-type.component';
import { SessionFlowListComponent } from '@private/pages/current-tenant/session-flow-list/session-flow-list.component';
import { SessionFlowComponent } from '@private/pages/current-tenant/session-flow/session-flow.component';
import { CurrentTenantComponent } from '@private/pages/current-tenant/tenant/current-tenant.component';
import { DashboardComponent } from '@private/pages/dashboard/dashboard.component';
import { DevelopmentComponent } from '@private/pages/development/development.component';
import { JobsComponent } from '@private/pages/jobs-management/jobs/jobs.component';
import { PageChangeGuard } from '@private/pages/page-management/page-builder-graphical/guards/page-change.guard';
import { PageBuilderGraphicalComponent } from '@private/pages/page-management/page-builder-graphical/page-builder-graphical.component';
import { PagesComponent } from '@private/pages/page-management/pages/pages.component';
import { ProfileComponent } from '@private/pages/profile/profile.component';
import { TeamListComponent } from '@private/pages/team-management/team-list/team-list.component';
import { TeamComponent } from '@private/pages/team-management/team/team.component';
import { TemplatesComponent } from '@private/pages/template-management/templates/templates.component';
import { UserListComponent } from '@private/pages/user-management/user-list/user-list.component';
import { UserComponent } from '@private/pages/user-management/user/user.component';
import { WorkflowListComponent } from '@private/pages/workflow-management/workflow-list/workflow-list.component';
import { WorkflowComponent } from '@private/pages/workflow-management/workflow/workflow.component';
import { AppErrorComponent } from '@public/pages/error/app.error.component';
import { PageComponent } from '@shared/components/page/page.component';
import { CanActivateAuthChild } from '@shared/guards/auth-child.guard';
import { CanActivateAuth } from '@shared/guards/auth.guard';
import { CanActivateHasTenant } from '@shared/guards/has-tenant.guard';
import { CanActivateIsNotProduction } from '@shared/guards/is-not-production.guard';
import { CanActivateIsSystemAdmin } from '@shared/guards/is-system-admin.guard';
import { CanActivateIsTenantAdmin } from '@shared/guards/is-tenant-admin.guard';
import { CanActivateIsTenantOrApplicationAdmin } from '@shared/guards/is-tenant-or-application-admin.guard';
import { getSystemPublicPageComponent } from '@shared/methods/system-public.pages.methods';
import { SystemPublicPageType } from '@shared/types/system-public-pages.types';
import { SystemUserListComponent } from './system/pages/system-user-management/system-user-list/system-user-list.component';
import { SystemUserComponent } from './system/pages/system-user-management/system-user/system-user.component';
import { TenantListComponent } from './system/pages/tenant-management/tenant-list/tenant-list.component';
import { TenantComponent } from './system/pages/tenant-management/tenant/tenant.component';
import { SystemComponent } from './system/system.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: LimitedMainComponent,
          providers: [LimitedMainService],
          children: [
            { path: '', pathMatch: 'full', component: PageComponent },
            { path: 'application-selection', component: ApplicationSelectionComponent, canActivate: [CanActivateAuth, CanActivateHasTenant] },
            { path: 'profile', component: ProfileComponent, canActivate: [CanActivateAuth, CanActivateHasTenant] },
            { path: 'page/:id', component: PageComponent, canActivate: [CanActivateAuth, CanActivateHasTenant] },
          ],
        },
        {
          path: 'admin',
          component: AdminMainComponent,
          providers: [AppMainService],
          canActivate: [CanActivateAuth, CanActivateIsTenantOrApplicationAdmin],
          canActivateChild: [CanActivateAuthChild, CanActivateIsTenantOrApplicationAdmin],
          children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'profile', component: ProfileComponent },

            // Jobs management
            { path: 'jobs-list', component: JobsComponent },

            // User management
            { path: 'user-list', component: UserListComponent },
            { path: 'user', component: UserComponent },
            { path: 'user/:id', component: UserComponent },

            // Team management
            { path: 'team-list', component: TeamListComponent },
            { path: 'team', component: TeamComponent },
            { path: 'team/:id', component: TeamComponent },

            // Artifact type management
            { path: 'artifact-type-list', component: ArtifactTypeListComponent },
            { path: 'artifact-type', component: ArtifactTypeComponent },
            { path: 'artifact-type/:id', component: ArtifactTypeComponent },

            { path: 'link-type-list', component: LinkTypeListComponent },
            { path: 'link-type', component: LinkTypeComponent },
            { path: 'link-type/:id', component: LinkTypeComponent },

            { path: 'attribute-list', component: AttributeListComponent },
            { path: 'attribute', component: AttributeComponent },
            { path: 'attribute/:id', component: AttributeComponent },

            { path: 'data-type-list', component: DataTypeListComponent },
            { path: 'data-type', component: DataTypeComponent },
            { path: 'data-type/:id', component: DataTypeComponent },

            // Artifact management
            { path: 'artifact-list', component: ArtifactListComponent },
            { path: 'artifact', component: ArtifactComponent },
            { path: 'artifact/:id', component: ArtifactComponent },

            // Application management
            { path: 'application-list', component: ApplicationListComponent },
            { path: 'application', component: ApplicationComponent },
            { path: 'application/:id', component: ApplicationComponent },

            // Pages management
            { path: 'pages', component: PagesComponent },
            { path: 'page-builder', component: PageBuilderGraphicalComponent, canDeactivate: [PageChangeGuard] },
            { path: 'page-builder/:id', component: PageBuilderGraphicalComponent, canDeactivate: [PageChangeGuard] },

            // Templates management
            { path: 'templates', component: TemplatesComponent },

            // Workflows management
            { path: 'workflow-list', component: WorkflowListComponent },
            { path: 'workflow', component: WorkflowComponent },
            { path: 'workflow/:id', component: WorkflowComponent },

            // Tenant page
            { path: 'tenant', component: CurrentTenantComponent, canActivate: [CanActivateIsTenantAdmin] },
            { path: 'session-flow-list', component: SessionFlowListComponent, canActivate: [CanActivateIsTenantAdmin] },
            { path: 'session-flow', component: SessionFlowComponent },
            { path: 'session-flow/:id', component: SessionFlowComponent },

            // Development page
            { path: 'development', component: DevelopmentComponent, canActivate: [CanActivateIsNotProduction] },
          ],
        },
        {
          path: 'system',
          component: SystemComponent,
          canActivate: [CanActivateAuth, CanActivateIsSystemAdmin],
          canActivateChild: [CanActivateAuthChild, CanActivateIsSystemAdmin],
          children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'profile', component: ProfileComponent },
            // User management
            { path: 'user-list', component: SystemUserListComponent },
            { path: 'user', component: SystemUserComponent },
            { path: 'user/:id', component: SystemUserComponent },
            // Team management
            { path: 'team-list', component: TeamListComponent },
            { path: 'team', component: TeamComponent },
            { path: 'team/:id', component: TeamComponent },
            // Tenant management
            { path: 'tenant-list', component: TenantListComponent },
            { path: 'tenant', component: TenantComponent },
            { path: 'tenant/:id', component: TenantComponent },
            // Development page
            // { path: 'development', component: DevelopmentComponent, canActivate: [CanActivateIsNotProduction] },
          ],
        },
        { path: 'error', component: AppErrorComponent },
        { path: 'access', component: getSystemPublicPageComponent(SystemPublicPageType.access) },
        { path: 'notfound', component: getSystemPublicPageComponent(SystemPublicPageType.notFound) },
        { path: 'login', component: getSystemPublicPageComponent(SystemPublicPageType.login) },
        { path: 'recover-password', component: getSystemPublicPageComponent(SystemPublicPageType.recoverPassword) },
        { path: '**', component: PageComponent },
        // { path: '**', component: LimitedMainComponent },
      ],
      {
        paramsInheritanceStrategy: 'emptyOnly',
        canceledNavigationResolution: 'replace',
        urlUpdateStrategy: 'deferred',
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
