<div class="at-enum-container full-size-container">
  <ng-container [ngSwitch]="selectedVariant">
    <ng-container *ngSwitchCase="'DEFAULT'">
      <div *ngFor="let enumOption of enumOptions; let i = index" [class.cursor-pointer]="isValueClickable()"
           (click)="selectItem(i, $event)" [ngStyle]="contentColumnFormat?.styles">
        <span *ngIf="isActive(i)" class="pi pi-check-circle" style="margin-right: 10px"></span>
        {{ enumOption?.label }}
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.CHIP_V1">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last"
           (click)="selectItem(i, $event)">
        <ng-container
          *ngTemplateOutlet="chipTemplate; context: { data: { enumOption: enumOption, last: last, index: i, choice: 1 } }">
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.CHIP_V2">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last"
           (click)="selectItem(i, $event)">
        <ng-container
          *ngTemplateOutlet="chipTemplate; context: { data: { enumOption: enumOption, last: last, index: i, choice: 2 } }">
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.CHIP_WITH_ICON_ONLY">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last"
           (click)="selectItem(i, $event)">
        <ng-container
          *ngTemplateOutlet="chipTemplate; context: { data: { enumOption: enumOption, last: last, index: i, choice: 3 } }">
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.CHIP_ONLY">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last"
           (click)="selectItem(i, $event)">
        <ng-container
          *ngTemplateOutlet="chipTemplate; context: { data: { enumOption: enumOption, last: last, index: i, choice: 4 } }">
        </ng-container>
      </div>
    </ng-container>

    <ng-template #chipTemplate let-data="data">
      <div
        [class.chip-layout]="data.enumOption && (data.choice === 1 || data.choice === 2)"
        [class.chip-layout-rounded]="data.enumOption && (data.choice === 3 || data.choice === 4)"
        [class.no-color-variant]="!data.enumOption?.backgroundColor"
        [class.cursor-pointer]="isValueClickable()"
        [class.with-separating-margin]="enumOptions.length > 1 && !data.last"
        [style.background-color]="data.enumOption?.backgroundColor"
        [style.color]="data.enumOption?.textColor"
        [ngClass]="isActive(data.index) ? 'active' : ''"
        [pTooltip]="(data.choice == 3 || data.choice == 4) ? data.enumOption?.label : null"
        tooltipPosition="top"
        tooltipStyleClass="custom-tooltip"
      >
        <ng-container *ngIf="!data.enumOption">
          &nbsp;
        </ng-container>
        <ng-container *ngIf="data.enumOption && data.choice == 1">
          {{data.enumOption?.label}}
        </ng-container>
        <ng-container *ngIf="data.enumOption && data.choice == 2">
          <i class="{{ data.enumOption?.icon }} enum-value-icon"></i>
          {{ data.enumOption?.label }}
        </ng-container>
        <ng-container *ngIf="data.enumOption && data.choice == 3">
          <i class="{{ data.enumOption?.icon || '' }} enum-value-icon">{{ data.enumOption?.icon ? '' : '&nbsp;' }}</i>
        </ng-container>
        <ng-container *ngIf="data.enumOption && data.choice == 4">&nbsp;</ng-container>
      </div>
    </ng-template>

    <ng-container *ngSwitchCase="layoutVariant.COLOR_ICON_TEXT">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last" class="chip-layout"
           (click)="selectItem(i, $event)">
        <span
          [class.cursor-pointer]="isValueClickable()"
          [class.with-separating-margin]="enumOptions.length > 1 && !last"
          [ngClass]="isActive(i) ? 'active' : ''"
        >
          <i class="{{ enumOption?.icon }} enum-value-icon" [style.color]="enumOption?.iconColor"></i>
          {{ enumOption?.label }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.COLOR_ICON_COLOR_TEXT">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last" class="chip-layout"
           (click)="selectItem(i, $event)">
        <span
          [class.cursor-pointer]="isValueClickable()"
          [style.color]="enumOption?.textColor"
          [class.with-separating-margin]="enumOptions.length > 1 && !last"
          [ngClass]="isActive(i) ? 'active' : ''"
        >
          <i class="{{ enumOption?.icon }} enum-value-icon" [style.color]="enumOption?.iconColor"></i>
          {{ enumOption?.label }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.COLOR_ICON">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last" class="chip-layout"
           (click)="selectItem(i, $event)">
        <span
          [class.cursor-pointer]="isValueClickable()"
          [class.with-separating-margin]="enumOptions.length > 1 && !last"
          [ngClass]="isActive(i) ? 'active' : ''"
          [pTooltip]="enumOption?.label"
          tooltipPosition="top"
          tooltipStyleClass="custom-tooltip"
        >
          <i class="{{ enumOption?.icon }} enum-value-icon" [style.color]="enumOption?.iconColor"></i>
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.FULL_CELL">
      <div
        *ngFor="let enumOption of enumOptions; let i = index"
        class="full-cell-enum full-size-container"
        [class.no-color-variant]="enumOption && !enumOption?.backgroundColor"
        [class.cursor-pointer]="isValueClickable()"
        [style.background-color]="enumOption && enumOption?.backgroundColor"
        [style.color]="enumOption && enumOption?.textColor"
        (click)="selectItem(i, $event)"
      >
        <span *ngIf="isActive(i)" class="pi pi-check-circle" style="margin-right: 10px"></span>
        {{ enumOption?.label }}
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.FULL_CELL_WITH_ICON">
      <div
        *ngFor="let enumOption of enumOptions; let i = index"
        class="full-cell-enum full-size-container"
        [class.no-color-variant]="enumOption && !enumOption?.backgroundColor"
        [class.cursor-pointer]="isValueClickable()"
        [style.background-color]="enumOption && enumOption?.backgroundColor"
        [style.color]="enumOption && enumOption?.textColor"
        (click)="selectItem(i, $event)"
      >
        <span *ngIf="isActive(i)" class="pi pi-check-circle" style="margin-right: 10px"></span>
        <i class="{{ enumOption?.icon }} enum-value-icon" [style.color]="enumOption?.iconColor"></i>
        {{ enumOption?.label }}
      </div>
    </ng-container>

    <div *ngSwitchCase="'CIRCLE'">not implemented yet</div>

    <div *ngSwitchCase="layoutVariant.DROPDOWN" style="width: 100%">
      <p-multiSelect
        [options]="enumOptions"
        [(ngModel)]="dropDownModel"
        (onChange)="onDropdownChange()"
        [filter]="true"
        [placeholder]="placeholder"
        [style]="{ width: '100%' }"
        filterBy="label"
        appendTo="body"
      ></p-multiSelect>
    </div>

    <div *ngSwitchCase="layoutVariant.CUSTOM">
      <!-- [applicationId]="applicationId" -->
      <ng-container *ngIf="customVariantTemplate$ | async as widget">
        <app-widget-container
          *ngIf="widget.code === widgetType.card"
          [artifact]="artifactDto || artifact"
          [isClickable]="false"
          [isLayoutMode]="true"
          [isListMatrixCard]="true"
          [isPreviewMode]="isPreviewMode"
          [disabled]="isPreviewMode"
          [widget]="widget"
          appWidgetContainerStopPropagation
        >
        </app-widget-container>
        <app-widget-container
          *ngIf="widget.code === widgetType.avr"
          [artifact]="artifactDto || artifact"
          [isLayoutMode]="true"
          [disabled]="isPreviewMode"
          [widget]="widget"
          appWidgetContainerStopPropagation
        >
        </app-widget-container>
      </ng-container>
    </div>
  </ng-container>
</div>
