import { WorkflowActionDto } from '../../../api/models/workflow-action-dto';
import { AttributeActionHandlerService } from '../../shared';
import { WorkflowActionType } from '../action.types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';
import { CommonFeAction } from './common-fe-action';

export type SET_EDITABLE_OR_READONLY_ACTION = WorkflowActionType.SET_EDITABLE | WorkflowActionType.SET_READONLY;

export class WorkflowActionSetAttributeEditable extends AbstractWorkflowAttributeBasedAction<CommonFeAction> {
  constructor(type: SET_EDITABLE_OR_READONLY_ACTION, dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super({ actionSettingDto: CommonFeAction, type, dto, actionAttributeHandler });
  }

  canBeExecuted(): boolean {
    return this.type === WorkflowActionType.SET_EDITABLE || this.type === WorkflowActionType.SET_READONLY;
  }

  execute() {
    const editable = this.type === WorkflowActionType.SET_EDITABLE;
    this.actionAttributeHandler.notifySetAttributeEditableEvent({ artifactTypeId: this.artifactTypeId, attributeId: this.attributeId, editable });
  }

  isValid(): boolean {
    return this.actionAttributeHandler && !!(this.artifactTypeId && this.attributeId);
  }
}
