import { Component, Input } from '@angular/core';
import { ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';
import { MenuItemUiType } from '@widgets/menu-widget/types/tree-types';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';

@Component({
  selector: 'app-menu-item-type-form',
  templateUrl: './menu-item-type-form.component.html',
  styleUrls: ['./menu-item-type-form.component.scss'],
})
export class MenuItemTypeFormComponent {
  @Input() m: MenuWidgetModel;
  @Input() s: MenuWidgetService;
  menuItemUiType = MenuItemUiType;

  selectedAction: ActionItem;

  ID_VALUE = ID_KEY;
  NAME_VALUE = NAME_KEY;
}
