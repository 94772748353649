import { PagePartDto } from '@api/models';
import { SelectOption } from '@shared/types/shared.types';
import { NewSystemUser } from '@shared/types/user.types';
import { WidgetOption } from '@widgets/widgets-core/types/widgets.types';
import { Page } from './page';
import { PageBlockPart } from './page-block-part';
import { PageBuilderSidebar } from './page-builder-sidebar';
import { PageSection } from './page-section';
import { RedesignColumnsModel } from './redesign-columns-model';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';

export class PageBuilderGraphicalModel {
  isInSavingProcess = false;
  exportModalOpened = false;
  selectedPageParts: { id: string; name: string; pagePart: PagePartDto }[] = [];
  isHeaderVisible = true;
  originalAlias = '';
  location: PartLocation;

  constructor(
    public user: NewSystemUser = new NewSystemUser(),
    public page: Page = new Page(),
    public redesignColumnsModel: RedesignColumnsModel = new RedesignColumnsModel(),
    public pageBuilderSidebar: PageBuilderSidebar = new PageBuilderSidebar(),
    public widgetOptions: WidgetOption<any>[] = [],
    public allWidgetOptions: WidgetOption<any>[] = [],
    public applicationOptions: SelectOption<string, string>[] = [],
    public isDefaultTenantPage = false,
    public loading = false,
    public isFirstLoad: boolean = true,
    public sectionForTemplate: PageSection | null = null,
    public partWithWidgetForTemplate: PageBlockPart | null = null,
  ) {}
}
