import { DisplayAtFormatContext } from '@shared/types/display-at-types';
import { NewTableColumn, TableColumnFormatSettings } from '@shared/types/table.types';

export interface TableColumnControlContext {
  event: Event;
  tableColumn: NewTableColumn;
  displayAtFormat: DisplayAtFormatContext;
}

export interface TableColumnContextChangeEvent {
  ownerId: string;
  changeType: TableColumnContextChangeType;
  tableColumn: NewTableColumn;
  tableColumnFormatSettings: TableColumnFormatSettings;
  event?: Event;
  applyToAll?: boolean;
}

export enum TableColumnContextChangeType {
  STYLES,
  VARIANT,
  SORT_ORDER,
}
