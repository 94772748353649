import { Params } from '@angular/router';

export class QueryParamsUtil {
  /**
   * Function returns query params converted into lower case.
   * @returns query params in lower case
   */
  static toLowerCase(queryParams: Params): Params {
    if (!queryParams) {
      return queryParams;
    }
    const lowerCaseParams: Params = {};
    for (const key in queryParams) {
      lowerCaseParams[key.toLowerCase()] = queryParams[key];
    }
    return lowerCaseParams;
  }

  /**
   * Method returns QueryParameters parsed from URL string
   * @example url: https://e-prod.softacus.com/982724542b8d0c97fb5156f0?artifactId=6760961601cc123e3233975d&name=Jan&size=23&isRequired=true
   *          res: { artifactId: '6760961601cc123e3233975d', name: 'Jan', size: '23', isRequired: true }
   * */
  static getQueryParamsFromUrlString(url: string = window.location.href): Record<string, string | number | boolean | (string | number | boolean)[]> {
    const queryParams: Record<string, string | number | boolean | (string | number | boolean)[]> = {};
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);

    params.forEach((value, key) => {
      // Handle comma-separated values
      const values = value.split(',').map(item => {
        if (item === 'true') return true;
        if (item === 'false') return false;
        if (!isNaN(Number(item))) return Number(item);
        return item;
      });

      // If multiple values exist for a key, merge them with existing ones
      if (key in queryParams) {
        if (Array.isArray(queryParams[key])) {
          queryParams[key] = (queryParams[key] as (string | number | boolean)[]).concat(values);
        } else {
          queryParams[key] = [queryParams[key] as string | number | boolean, ...values];
        }
      } else {
        // If only one value, use it directly; otherwise, store as an array
        queryParams[key] = values.length === 1 ? values[0] : values;
      }
    });

    return queryParams;
  }
}
