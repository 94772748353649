import { LangChangeEvent } from '@ngx-translate/core';
import { omitBy } from 'lodash';
import { Environment } from '../../../../environments/environment';

// Core service
export interface CoreServiceI {
  init(context: any, model: any): void;
}

export interface TranslatableI {
  onTranslate(event: LangChangeEvent): void;
}

// Core router
export class CoreParams {
  initOnQueryParamChange = true;
  subscribeToParent = false;

  constructor(params?: Partial<CoreParams>) {
    params && Object.assign(this, params);
  }
}

// Core list
export class ListMetaData {
  constructor(
    public limit: number = Environment.tableConfig.rowsPerPage,
    public skip: number = 0,
    public filters: Record<string, string> = {},
    public sort: Record<string, number | CustomSort> = {},
    public totalCount: number = 0,
    public limitOptions: number[] = Environment.tableConfig.rowsPerPageOptions,
  ) {}

  toQuery(externalFilters: Record<string, any> = {}): Record<string, string> {
    return new ListReqMetaData(this.limit, this.skip, this.filters, this.sort).toQuery(externalFilters);
  }
}

export class ListReqMetaData {
  constructor(
    public limit: number = Environment.tableConfig.rowsPerPage,
    public skip: number = 0,
    public filters: Record<string, string> = {},
    public sort: Record<string, number | CustomSort> = {},
  ) {}

  toQuery(externalFilters: Record<string, any> = {}): Record<string, string> {
    return omitBy(
      {
        limit: String(this.limit),
        skip: String(this.skip),
        filter: JSON.stringify({ ...this.filters, ...externalFilters }),
        sort: JSON.stringify(this.sort),
      },
      val => !val || val === '0' || val === '{}',
    );
  }
}

export interface CustomSort {
  priorityPairs: Array<Array<string | number>>;
  direction: number;
}

export interface ListResMetaDataI {
  limit?: number;
  skip?: number;
  totalCount: number;
}

export interface ListResDtoI<Entity> {
  data: Entity[];
  meta: ListResMetaDataI;
}

export interface ListReqMetaDataI {
  limit: number;
  skip: number;
  filter: string;
  sort: string;
}

export class EmptyListResponse<Entity> {
  constructor(
    public data: Entity[] = [],
    public meta: ListResMetaDataI = { totalCount: 0 },
  ) {}
}

export enum CoreListFilterEnum {
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  contains = 'contains',
  notContains = 'notContains',
  equals = 'equals',
  notEquals = 'notEquals',
  lessThan = 'lt',
  lessThanOrEqualTo = 'lte',
  greaterThan = 'gt',
  greaterThanOrEqualTo = 'gte',
  in = 'in',
  notIn = 'notIn',
  isNot = 'isNot',
  before = 'before',
  after = 'after',
  dateIs = 'dateIs',
  dateIsNot = 'dateIsNot',
  dateBefore = 'dateBefore',
  dateBeforeOrEqualTo = 'dateBeforeOrEqualTo',
  dateAfter = 'dateAfter',
  dateAfterOrEqualTo = 'dateAfterOrEqualTo',
  exists = 'exists',
  between = 'between',
  isEmpty = 'Is empty',
  isNotEmpty = 'Is not empty',
}

export enum FilterToClientEnum {
  startsWith = 'Starts with',
  endsWith = 'Ends with',
  contains = 'Contains',
  notContains = 'Not contains',
  equals = 'Equals',
  notEquals = 'Not equals',
  lt = 'Less than',
  lte = 'Less than or equal to',
  gt = 'Greater than',
  gte = 'Greater than or equal to',
  in = 'Is',
  notIn = 'Is not',
  isNot = 'Is not',
  before = 'Before',
  after = 'After',
  isEmpty = 'Is empty',
  isNotEmpty = 'Is not empty',
}
