<p-card>
  <ng-container *ngIf="tenant">
    <div class="mb-3">
      <h4 class="m-0">{{ TENANT_LABEL | translate }}{{ " '" + tenant.name + "' " }}{{ 'settings' | translate }}</h4>
    </div>

    <span class="dynamic-label mt-4 mb-5">
      <input type="text" id="currentTenantName" pInputText [(ngModel)]="tenant.name" [disabled]="true" />
      <label class="dynamic-label-text" for="currentTenantName" translate>Tenant name</label>
    </span>

    <span class="dynamic-label mt-4 mb-5">
      <input type="text" id="currentTenantDomain" pInputText [(ngModel)]="tenant.domain" />
      <label class="dynamic-label-text" for="currentTenantDomain" translate>Tenant domain</label>
    </span>

    <span class="dynamic-label dynamic-radiobutton">
      <span class="dynamic-radiobutton-box">
        <p-radioButton name="currentTenantIsPrivate" [value]="false" [(ngModel)]="tenant.isPublic"
                       inputId="currentTenantIsPrivateRadio1" [disabled]="true">
        </p-radioButton>
        <label [for]="'currentTenantIsPrivateRadio1'" translate>Private</label>
      </span>
      <span class="dynamic-radiobutton-box">
        <p-radioButton name="currentTenantIsPrivate" [value]="true" [(ngModel)]="tenant.isPublic"
                       inputId="currentTenantIsPrivateRadio2" [disabled]="true">
        </p-radioButton>
        <label [for]="'currentTenantIsPrivateRadio2'" translate>Public</label>
      </span>
      <label class="dynamic-label-text" translate>Public tenant</label>
    </span>

    <span *ngIf="tenant.isPublic === true" class="dynamic-label dynamic-radiobutton">
      <span class="dynamic-radiobutton-box">
        <p-radioButton name="currentTenantIsPrivate" [value]="false" [(ngModel)]="tenant.registrationAllowed"
                       inputId="currentTenantRegistrationAllowedRadio1">
        </p-radioButton>
        <label [for]="'currentTenantRegistrationAllowedRadio1'" translate>Disallowed</label>
      </span>
      <span class="dynamic-radiobutton-box">
        <p-radioButton name="currentTenantIsPrivate" [value]="true" [(ngModel)]="tenant.registrationAllowed"
                       inputId="currentTenantRegistrationAllowedRadio2">
        </p-radioButton>
        <label [for]="'currentTenantRegistrationAllowedRadio2'" translate>Allowed</label>
      </span>
      <label class="dynamic-label-text" translate>Registration allowed</label>
    </span>

    <div class="grid" *ngIf="tenant.isPublic">
      <div class="col-12 pb-0">
        <h5>{{ 'Custom public pages' | translate }}</h5>
      </div>

      <div class="col-4">
        <span class="dynamic-label">
          <p-dropdown [autoDisplayFirst]="false"
                      [filter]="true"
                      [options]="publicPageOptions"
                      [(ngModel)]="tenant.systemPages.loginPageId"
                      [showClear]="true"
                      id="loginPageId"></p-dropdown>
          <label class="dynamic-label-text" for="loginPageId">{{ 'Login page' | translate }}</label>
        </span>
      </div>

      <div class="col-4">
        <span class="dynamic-label">
          <p-dropdown [autoDisplayFirst]="false"
                      [filter]="true"
                      [options]="publicPageOptions"
                      [(ngModel)]="tenant.systemPages.accessPageId"
                      [showClear]="true"
                      id="accessPageId"></p-dropdown>
          <label class="dynamic-label-text" for="accessPageId">{{ 'Access page' | translate }}</label>
        </span>
      </div>

      <div class="col-4">
        <span class="dynamic-label">
          <p-dropdown [autoDisplayFirst]="false"
                      [filter]="true"
                      [options]="publicPageOptions"
                      [(ngModel)]="tenant.systemPages.notFoundPageId"
                      [showClear]="true"
                      id="notFoundPageId"></p-dropdown>
          <label class="dynamic-label-text" for="notFoundPageId">{{ 'Not found page' | translate }}</label>
        </span>
      </div>

      <div class="col-4">
        <span class="dynamic-label">
          <p-dropdown [autoDisplayFirst]="false"
                      [filter]="true"
                      [options]="publicPageOptions"
                      [(ngModel)]="tenant.systemPages.recoverPasswordPageId"
                      [showClear]="true"
                      id="recoverPasswordId"></p-dropdown>
          <label class="dynamic-label-text" for="recoverPasswordId">{{ 'Recover password page' | translate }}</label>
        </span>
      </div>
    </div>

    <div class="grid" *ngIf="userMeta?.isTenantAdmin">
      <div class="col-12 pb-0">
        <h5>{{ 'Custom scripts' | translate }}</h5>
      </div>

      <app-custom-script-settings style="width: 100%" [scriptModels]="tenant.scriptModels"></app-custom-script-settings>
    </div>
  </ng-container>
</p-card>
