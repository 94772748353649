<div *ngIf="m.settings?.widgetType === widgetType.plain" [ngStyle]="m.styles"
     class="plain-mode icon-position-auth">
  <div *ngIf="m.settings.showIcon && m.currentUser | shouldShowLogoutButton" class="full-width-container">
    <app-user-icon [m]="m" [s]="s" (onClick)="logout()"></app-user-icon>
  </div>


  <div *ngIf="!m.currentUser?.isGuest" [ngStyle]="m.settings.userDataStyles" class="plain-mode"
       [ngClass]="isRow ? 'user-container': ''">
    <div *ngIf="m.settings.showName" [ngStyle]="m.settings.userNameStyle" class="user-name"> {{ m.userName }}</div>
    <div *ngIf="m.currentUser | shouldShowEmail : m.settings.showEmail" [ngStyle]="m.settings.userEmailStyle"
         class="email"> {{ m.currentUser?.email }}
    </div>
  </div>

  <ng-container [ngTemplateOutlet]="buttonTemplate"></ng-container>
</div>

<div *ngIf="m.settings?.widgetType === widgetType.overlay">
  <app-user-icon [m]="m" [s]="s" (onClick)="authOP.toggle($event)"></app-user-icon>
</div>

<ng-template #buttonTemplate>
  <ng-container *ngIf="m.settings.showLogoutButton && m.currentUser | shouldShowLogoutButton else loginButton">
    <div class="full-width-container" [ngStyle]="logoutContainerStyles">
      <i
        [ngClass]="m.settings.logoutIcon"
        [ngStyle]="m.settings.logoutIconStyle"
        [pTooltip]="'Log out' | translate"
        (click)="logout()"
        class="pi"
      ></i>
    </div>
  </ng-container>

  <ng-template #loginButton>
    <ng-container *ngIf="m.settings.showLoginButton && (!m.currentUser || m.currentUser?.isGuest)">
      <div class="full-width-container" [ngStyle]="loginContainerStyles">
        <i
          [ngClass]="m.settings.loginIcon"
          [ngStyle]="m.settings.loginIconStyle"
          [pTooltip]="'Log in' | translate"
          (click)="navigateToLogin()"
          class="pi"
        ></i>
      </div>
    </ng-container>
  </ng-template>
</ng-template>

<p-overlayPanel #authOP appendTo="body" [style]="{'max-width':'380px'}">
  <div class="overlay-body" [ngStyle]="m.styles">
    <div *ngIf="!m.currentUser?.isGuest" [ngStyle]="m.settings.userDataStyles" class="plain-mode">
      <span *ngIf="m.settings.showName" [ngStyle]="m.settings.userNameStyle" class="user-name"> {{ m.userName }} </span>
      <span *ngIf="m.currentUser | shouldShowEmail : m.settings.showEmail" [ngStyle]="m.settings.userEmailStyle"
            class="email"> {{ m.currentUser?.email }} </span>
    </div>

    <ng-container [ngTemplateOutlet]="buttonTemplate"></ng-container>
  </div>
</p-overlayPanel>
