import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { WorkflowRule } from '../types';

@Pipe({
  name: 'getRuleDefaultArtifactType',
  standalone: true,
})
export class GetRuleDefaultArtifactTypePipe implements PipeTransform {
  transform(rule: WorkflowRule, artifactTypes: ListContainer<NewArtifactType>): string {
    return rule && rule.defaultArtifactTypeId ? artifactTypes.listMap[rule?.defaultArtifactTypeId].name : '';
  }
}
