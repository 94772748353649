import { DefaultElvisActions } from '@shared/types/actions.types';
import { TreeNode } from '@private/components/type-system-element-dropdown/tree-node';
import { LinkQueryParam, LinkQueryParamActionEnum, LinkQueryParamMap } from '@widgets/menu-widget/types/tree-types';
import { Params } from '@angular/router';
import { ActionType } from '@widgets/shared/types/click-action-settings-dto';
import {
	MenuWidgetWorkflowValueItem,
} from '@widgets/shared/components/action-list/components/menu-widget-actions/types/menu-widget-workflow-action.types';
import {
	ActionWorkflow,
	ActionWorkflowDto
} from '@widgets/shared/components/action-list/components/menu-widget-actions/menu-widget-actions.component';

export class ActionItem {
	type: ActionType = DefaultElvisActions.doNothing;
	selectedType: TreeNode = {
		label: DefaultElvisActions.doNothing,
		data: DefaultElvisActions.doNothing,
	}

	isEdit: boolean;
	queryParams: LinkQueryParam[] = [];
	fragmentParam = '';
	isPageSelection = true;
	pageId: string | null = null;
	useAlias = true;
	url?: string;
	model: ActionItemModelType = {} as ActionItemModelType;

	emittingKey?: string;
	openInNewTab?: boolean;
	externalPage?: string;
	showNumberOfSelected = true;
	holdAllUrlParam?: boolean;

	constructor(dto?: ActionItemDto) {
		dto && this.fromDto(dto);
	}

	fromDto(dto: ActionItemDto): void {
		Object.assign(this, dto);

		if (this.model.workflow?.data.length) {
			this.model.workflow.data = this.model.workflow.data.map(item => new MenuWidgetWorkflowValueItem(item))
		}

		this.selectedType = {
			label: dto.type,
			data: dto.type,
			selectable: true,
		} as TreeNode;
	}

	toServer(): ActionItemDto {
		const model: ActionItemModelTypeDto = {} as ActionItemModelTypeDto;
		const { workflow, name } = this.model;
		name && (model.name = name);
		workflow && (model.workflow = { id: workflow.id, data: workflow.data.map(item => item.toServer()) });

		const dto: ActionItemDto = {
			type: this.type,
			model,
			queryParams: this.queryParams,
			isPageSelection: this.isPageSelection,
			useAlias: this.useAlias,
		};

		this.url && (dto.url = this.url);
		this.pageId && (dto.pageId = this.pageId);
		this.openInNewTab && (dto.openInNewTab = this.openInNewTab);
		this.fragmentParam && (dto.fragmentParam = this.fragmentParam);
		this.emittingKey && (dto.emittingKey = this.emittingKey);
		this.externalPage && (dto.externalPage = this.externalPage);
		this.showNumberOfSelected && (dto.showNumberOfSelected = this.showNumberOfSelected);
		this.holdAllUrlParam && (dto.holdAllUrlParam = this.holdAllUrlParam);
		return dto;
	}

	queryParamsListToMap(): LinkQueryParamMap {
		return this.queryParams.reduce((queryParamsMap: LinkQueryParamMap, param) => {
			if (param.key) {
				param.action === LinkQueryParamActionEnum.addNew && param.value && (queryParamsMap.parametersToAdd[param.key] = param.value);
				param.action === LinkQueryParamActionEnum.removeExisting && queryParamsMap.parameterKeysToRemove.push(param.key);
				param.action === LinkQueryParamActionEnum.holdExisting && queryParamsMap.parametersToHold.push(param.key);
			}
			return queryParamsMap;
		}, new LinkQueryParamMap());
	}

	getAddParamsThatAreInUse(paramsFromUrl: Params): LinkQueryParam[] {
		return this.queryParams.filter(param => param.action === LinkQueryParamActionEnum.addNew && paramsFromUrl[param.key] === param.value);
	}
}

export class ActionItemDto {
	type: string;
	queryParams: LinkQueryParam[];
	fragmentParam?: string;
	isPageSelection: boolean;
	pageId?: string;
	useAlias: boolean;
	url?: string;
	model: ActionItemModelTypeDto;
	emittingKey?: string;
	openInNewTab?: boolean;
	externalPage?: string;
	showNumberOfSelected?:boolean;
	holdAllUrlParam?: boolean;
}

export type ActionItemModelType = { workflow?: ActionWorkflow, name?: string }
export type ActionItemModelTypeDto = { workflow?: ActionWorkflowDto, name?: string }
