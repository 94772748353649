<p-multiSelect
  *ngIf="options"
  (onChange)="onChange()"
  (onPanelShow)="sortUsers()"
  [(ngModel)]="m.value"
  [options]="options"
  defaultLabel="Filter by attribute value"
  optionLabel="label"
  appendTo="body">
</p-multiSelect>
<p-progressSpinner *ngIf="!options"></p-progressSpinner>
