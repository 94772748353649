<div cdkDrag class="toolbar-container" [attr.id]="section.htmlId">
  <div #toolbarRef class="toolbar">
    <i cdkDragHandle class="bi bi-arrows-move toolbar__icon" pRipple pTooltip="{{ 'Move' | translate }}"
       tooltipPosition="top"></i>
    <i
      (click)="style([outerContainer, innerContainer])"
      class="bi bi-sliders toolbar__icon"
      pRipple
      pTooltip="{{ 'Style' | translate }}"
      tooltipPosition="top"
    ></i>
    <i
      (click)="redesignColumns($event)"
      class="bi bi-columns-gap toolbar__icon"
      pRipple
      pTooltip="{{ 'Redesign columns' | translate }}"
      tooltipPosition="top"
    ></i>
    <i
      (click)="saveAsTemplate()"
      *ngIf="!section.isTemplate; else reusedTemplateIcon"
      class="pi pi-save toolbar__icon"
      pRipple
      pTooltip="{{ 'Save as Template' | translate }}"
      tooltipPosition="top"
    ></i>
    <ng-template #reusedTemplateIcon>
      <i
        [escape]="false"
        class="bi bi-recycle toolbar__icon"
        disabled
        pRipple
        pTooltip="<div style='text-align: center'>{{ 'Reused template' | translate }}<br>{{ section.templateName }}</div>"
        tooltipPosition="top"
      ></i>
    </ng-template>
    <i (click)="copy()" class="pi pi-copy toolbar__icon" pRipple pTooltip="{{ 'Copy' | translate }}"
       tooltipPosition="top"></i>
    <i
      (click)="paste()"
      *ngIf="copyPasterService.hasSectionCopy$ | async"
      class="bi bi-clipboard-plus toolbar__icon"
      pRipple
      pTooltip="{{ 'Paste' | translate }}"
      tooltipPosition="top"
    ></i>
    <i (click)="delete()" class="bi bi-trash toolbar__icon" pRipple pTooltip="{{ 'Delete' | translate }}"
       tooltipPosition="top"></i>
    <i (click)="export()" class="pi pi-cloud-download toolbar__icon" pRipple pTooltip="{{ 'Export' | translate }}"
       tooltipPosition="top"></i>
    <i (click)="setId($event)" class="bi bi-key toolbar__icon" pRipple
       pTooltip="{{ 'Set id for autoscroll' | translate }}" tooltipPosition="top"></i>
  </div>

  <div #outerContainer
    [ngClass]="section.styles?.outerContainer.containerClass | containerClassList: {
      pc: section.sectionHide.pc,
      tablet: section.sectionHide.tablet,
      mobile: section.sectionHide.mobile
    }"
    [ngStyle]="section.styles?.outerContainer | imageFilterStylesAdmin"
    class="outer-container pseudo-opacity">
    <div
      #innerContainer
      (cdkDropListDropped)="dropRow($event)"
      [cdkDropListAutoScrollStep]="3"
      [cdkDropListConnectedTo]="dragDropService.sectionIds"
      [cdkDropListData]="{ rows: section.rows, sectionIndex: sectionIndex, modalId: modalId }"
      [id]="dragDropService.sectionIds[sectionIndex]"
      [ngClass]="section.styles.innerContainer.containerClass | containerClassList: {
        pc: false,
        tablet: false,
        mobile: false
      }"
      [ngStyle]="section.styles?.innerContainer | imageFilterStylesAdmin"
      cdkDropList
      cdkDropListLockAxis="y"
      class="inner-container pseudo-opacity"
    >
      <app-page-row
        *ngFor="let row of section.rows; index as rowIndex"
        [borderLeftColor]="section.styles.outerContainer.borderLeftColor + section.styles.innerContainer.borderLeftColor"
        [borderLeftStyle]="section.styles.outerContainer.borderLeftStyle + section.styles.innerContainer.borderLeftStyle"
        [borderLeftWidth]="section.styles.outerContainer.borderLeftWidth + section.styles.innerContainer.borderLeftWidth"
        [containerClass]="section.styles.outerContainer.containerClass + section.styles.innerContainer.containerClass"
        [marginLeft]="section.styles.outerContainer.marginLeft + section.styles.innerContainer.marginLeft"
        [marginRight]="section.styles.outerContainer.marginRight + section.styles.innerContainer.marginRight"
        [paddingLeft]="section.styles.outerContainer.paddingLeft + section.styles.innerContainer.paddingLeft"
        [rowIndex]="rowIndex"
        [row]="row"
        [page]="page"
        [sectionIndex]="sectionIndex"
        [toolbarShiftInPx]="toolbarShiftInPx"
        [modalId]="modalId"
      ></app-page-row>
    </div>
  </div>

  <i (click)="addSection()" class="bi bi-plus-circle toolbar-container__add-sibling"></i>
</div>
