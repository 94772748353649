import { Injectable } from '@angular/core';
import {
  AttributeBasedEvent,
  ModifyAttributeValueEvent,
  ModifyAttributeValueEventWrapper,
  SetAttributeEditableEvent,
  SetAttributeMandatoryEvent,
  SetAttributeValueEvent,
  SetAttributeValueEventWrapper,
  SetLinkValueEvent,
  ShowHideAttributeEvent,
} from '@workflows/shared';
import { Observable, ReplaySubject } from 'rxjs';

/*
  TODO: potential refactoring idea
  - eventListeners: Record<string, number>;
  - eventMap: Record<string, T>;
  - eventMapSubject: ReplaySubject<Record<string, T>>;
  - eventMapObservable: Observable<Record<string, T>>

  *generateKey()
  registerEvent()
  unregisterEvent()
  get map()
  notify()
  contains()
  removeFromMap()
 */
@Injectable({ providedIn: 'root' })
export class AttributeActionHandlerService {
  private showHideEventListeners: Record<string, number>;
  private showHideEventMap: Record<string, ShowHideAttributeEvent>;
  private showHideEventMapSubject: ReplaySubject<Record<string, ShowHideAttributeEvent>>;
  private showHideEventMapObservable: Observable<Record<string, ShowHideAttributeEvent>>;

  private setAttributeValueEventListeners: Record<string, number>;
  private setAttributeValueEventMap: Record<string, SetAttributeValueEvent>;
  private setAttributeValueEventSubject: ReplaySubject<SetAttributeValueEventWrapper>;
  private setAttributeValueEventObservable: Observable<SetAttributeValueEventWrapper>;

  private modifyAttributeValueEventListeners: Record<string, number>;
  private modifyAttributeValueEventMap: Record<string, ModifyAttributeValueEvent>;
  private modifyAttributeValueEventSubject: ReplaySubject<ModifyAttributeValueEventWrapper>;
  private modifyAttributeValueEventObservable: Observable<ModifyAttributeValueEventWrapper>;

  private setLinkValueEventListeners: Record<string, number>;
  private setLinkValueEventMap: Record<string, SetLinkValueEvent>;
  private setLinkValueEventMapSubject: ReplaySubject<Record<string, SetLinkValueEvent>>;
  private setLinkValueEventMapObservable: Observable<Record<string, SetLinkValueEvent>>;

  private setAttributeOptionsEventListeners: Record<string, number>;
  private setAttributeOptionsEventMap: Record<string, SetAttributeValueEvent>;
  private setAttributeOptionsEventSubject: ReplaySubject<SetAttributeValueEventWrapper>;
  private setAttributeOptionsEventObservable: Observable<SetAttributeValueEventWrapper>;

  private setAttributeMandatoryEventListeners: Record<string, number>;
  private setAttributeMandatoryEventMap: Record<string, SetAttributeMandatoryEvent>;
  private setAttributeMandatoryEventMapSubject: ReplaySubject<Record<string, SetAttributeMandatoryEvent>>;
  private setAttributeMandatoryEventMapObservable: Observable<Record<string, SetAttributeMandatoryEvent>>;

  private setAttributeEditableEventListeners: Record<string, number>;
  private setAttributeEditableEventMap: Record<string, SetAttributeEditableEvent>;
  private setAttributeEditableEventMapSubject: ReplaySubject<Record<string, SetAttributeEditableEvent>>;
  private setAttributeEditableEventMapObservable: Observable<Record<string, SetAttributeEditableEvent>>;

  constructor() {
    this.setAttributeValueEventListeners = {};
    this.setAttributeValueEventMap = {};
    this.setAttributeValueEventSubject = new ReplaySubject(1);
    this.setAttributeValueEventObservable = this.setAttributeValueEventSubject.asObservable();

    this.modifyAttributeValueEventListeners = {};
    this.modifyAttributeValueEventMap = {};
    this.modifyAttributeValueEventSubject = new ReplaySubject(1);
    this.modifyAttributeValueEventObservable = this.modifyAttributeValueEventSubject.asObservable();

    this.showHideEventListeners = {};
    this.showHideEventMap = {};
    this.showHideEventMapSubject = new ReplaySubject(1);
    this.showHideEventMapObservable = this.showHideEventMapSubject.asObservable();

    this.setLinkValueEventListeners = {};
    this.setLinkValueEventMap = {};
    this.setLinkValueEventMapSubject = new ReplaySubject(1);
    this.setLinkValueEventMapObservable = this.setLinkValueEventMapSubject.asObservable();

    this.setAttributeOptionsEventListeners = {};
    this.setAttributeOptionsEventMap = {};
    this.setAttributeOptionsEventSubject = new ReplaySubject(1);
    this.setAttributeOptionsEventObservable = this.setAttributeOptionsEventSubject.asObservable();

    this.setAttributeMandatoryEventListeners = {};
    this.setAttributeMandatoryEventMap = {};
    this.setAttributeMandatoryEventMapSubject = new ReplaySubject(1);
    this.setAttributeMandatoryEventMapObservable = this.setAttributeMandatoryEventMapSubject.asObservable();

    this.setAttributeEditableEventListeners = {};
    this.setAttributeEditableEventMap = {};
    this.setAttributeEditableEventMapSubject = new ReplaySubject(1);
    this.setAttributeEditableEventMapObservable = this.setAttributeEditableEventMapSubject.asObservable();
  }

  registerShowHideEventListener(key: string): void {
    this.showHideEventListeners[key] ??= 0;
    this.showHideEventListeners[key]++;
  }

  unregisterShowHideEventListener(key: string): void {
    if (this.showHideEventListeners[key]) {
      this.showHideEventListeners[key]--;
    }
  }

  get showHideEventMap$(): Observable<Record<string, ShowHideAttributeEvent>> {
    return this.showHideEventMapObservable;
  }

  notifyShowHideEvent(event: ShowHideAttributeEvent) {
    const key = this.generateMapKeyForAttributeBasedEvent(event);

    event.toBeConsumed = this.showHideEventListeners[key];
    this.showHideEventMap[key] = event;
    this.showHideEventMapSubject.next(this.showHideEventMap);
  }

  containsShowHideValueEvent(key: string): boolean {
    return !!this.showHideEventMap[key];
  }

  getShowHideValueEvent(key: string): ShowHideAttributeEvent {
    return this.showHideEventMap[key];
  }

  removeShowHideEventFromMap(key: string, notify: boolean): void {
    if (this.showHideEventMap[key].toBeConsumed) {
      this.showHideEventMap[key].toBeConsumed!--;
    }

    if (!this.showHideEventMap[key].toBeConsumed) {
      delete this.showHideEventMap[key];
    }

    notify && this.showHideEventMapSubject.next(this.showHideEventMap);
  }

  registerSetAttributeValueEventListener(key: string): void {
    this.setAttributeValueEventListeners[key] ??= 0;
    this.setAttributeValueEventListeners[key]++;
  }

  unregisterSetAttributeValueEventListener(key: string): void {
    if (this.setAttributeValueEventListeners[key]) {
      this.setAttributeValueEventListeners[key]--;
    }
  }

  get setAttributeValueEventMap$(): Observable<SetAttributeValueEventWrapper> {
    return this.setAttributeValueEventObservable;
  }

  notifySetAttributeValueEvent(event: SetAttributeValueEvent) {
    const key = this.generateMapKeyForAttributeBasedEvent(event);

    event.toBeConsumed = this.setAttributeValueEventListeners[key];
    this.setAttributeValueEventMap[key] = event;
    this.setAttributeValueEventSubject.next({ eventMap: this.setAttributeValueEventMap, event });
  }

  containsSetAttributeValueEvent(key: string): boolean {
    return !!this.setAttributeValueEventMap[key];
  }

  getSetAttributeValueEvent(key: string): SetAttributeValueEvent {
    return this.setAttributeValueEventMap[key];
  }

  removeSetAttributeValueEventFromMap(key: string, notify = false): void {
    if (this.setAttributeValueEventMap[key].toBeConsumed) {
      this.setAttributeValueEventMap[key].toBeConsumed!--;
    }

    if (!this.setAttributeValueEventMap[key].toBeConsumed) {
      delete this.setAttributeValueEventMap[key];
    }

    notify && this.setAttributeValueEventSubject.next({ eventMap: this.setAttributeValueEventMap });
  }

  registerModifyAttributeValueEventListener(key: string): void {
    this.modifyAttributeValueEventListeners[key] ??= 0;
    this.modifyAttributeValueEventListeners[key]++;
  }

  unregisterModifyAttributeValueEventListener(key: string): void {
    if (this.modifyAttributeValueEventListeners[key]) {
      this.modifyAttributeValueEventListeners[key]--;
    }
  }

  get modifyAttributeValueEventMap$(): Observable<ModifyAttributeValueEventWrapper> {
    return this.modifyAttributeValueEventObservable;
  }

  notifyModifyAttributeValueEvent(event: ModifyAttributeValueEvent) {
    const key = this.generateMapKeyForAttributeBasedEvent(event);

    event.toBeConsumed = this.modifyAttributeValueEventListeners[key];
    this.modifyAttributeValueEventMap[key] = event;
    this.modifyAttributeValueEventSubject.next({ eventMap: this.modifyAttributeValueEventMap, event });
  }

  containsModifyAttributeValueEvent(key: string): boolean {
    return !!this.modifyAttributeValueEventMap[key];
  }

  getModifyAttributeValueEvent(key: string): ModifyAttributeValueEvent {
    return this.modifyAttributeValueEventMap[key];
  }

  removeModifyAttributeValueEventFromMap(key: string, notify = false): void {
    if (this.modifyAttributeValueEventMap[key].toBeConsumed) {
      this.modifyAttributeValueEventMap[key].toBeConsumed!--;
    }

    if (!this.modifyAttributeValueEventMap[key].toBeConsumed) {
      delete this.modifyAttributeValueEventMap[key];
    }

    notify && this.modifyAttributeValueEventSubject.next({ eventMap: this.modifyAttributeValueEventMap });
  }

  registerSetLinkValueEventListener(key: string): void {
    this.setLinkValueEventListeners[key] ??= 0;
    this.setLinkValueEventListeners[key]++;
  }

  unregisterSetLinkValueEventListener(key: string): void {
    if (this.setLinkValueEventListeners[key]) {
      this.setLinkValueEventListeners[key]--;
    }
  }

  get setLinkValueEventMap$(): Observable<Record<string, SetLinkValueEvent>> {
    return this.setLinkValueEventMapObservable;
  }

  notifySetLinkValueEvent(event: SetLinkValueEvent) {
    const key = event.targetLinkTypeId.split('_')[0];

    event.toBeConsumed = this.setLinkValueEventListeners[key];
    this.setLinkValueEventMap[key] = event;
    this.setLinkValueEventMapSubject.next(this.setLinkValueEventMap);
  }

  containsSetLinkValueEvent(key: string): boolean {
    return !!this.setLinkValueEventMap[key];
  }

  getSetLinkValueEvent(key: string): SetLinkValueEvent {
    return this.setLinkValueEventMap[key];
  }

  removeSetLinkValueEventFromMap(key: string, notify = false): void {
    if (this.setLinkValueEventMap[key].toBeConsumed) {
      this.setLinkValueEventMap[key].toBeConsumed!--;
    }

    if (!this.setLinkValueEventMap[key].toBeConsumed) {
      delete this.setLinkValueEventMap[key];
    }

    notify && this.setLinkValueEventMapSubject.next(this.setLinkValueEventMap);
  }

  registerSetAttributeOptionsEventListener(key: string): void {
    this.setAttributeOptionsEventListeners[key] ??= 0;
    this.setAttributeOptionsEventListeners[key]++;
  }

  unregisterSetAttributeOptionsEventListener(key: string): void {
    if (this.setAttributeOptionsEventListeners[key]) {
      this.setAttributeOptionsEventListeners[key]--;
    }
  }

  get setAttributeOptionsEventMap$(): Observable<SetAttributeValueEventWrapper> {
    return this.setAttributeOptionsEventObservable;
  }

  notifySetAttributeOptionsEvent(event: SetAttributeValueEvent) {
    const key = this.generateMapKeyForAttributeBasedEvent(event);

    event.toBeConsumed = this.setAttributeOptionsEventListeners[key];
    this.setAttributeOptionsEventMap[key] = event;
    this.setAttributeOptionsEventSubject.next({ eventMap: this.setAttributeOptionsEventMap, event });
  }

  containsSetAttributeOptionsEvent(key: string): boolean {
    return !!this.setAttributeOptionsEventMap[key];
  }

  getSetAttributeOptionsEvent(key: string): SetAttributeValueEvent {
    return this.setAttributeOptionsEventMap[key];
  }

  removeSetAttributeOptionsEventFromMap(key: string, notify = false): void {
    if (this.setAttributeOptionsEventMap[key].toBeConsumed) {
      this.setAttributeOptionsEventMap[key].toBeConsumed!--;
    }

    if (!this.setAttributeOptionsEventMap[key].toBeConsumed) {
      delete this.setAttributeOptionsEventMap[key];
    }

    notify && this.setAttributeOptionsEventSubject.next({ eventMap: this.setAttributeOptionsEventMap });
  }

  registerSetAttributeMandatoryEventListeners(key: string): void {
    this.setAttributeMandatoryEventListeners[key] ??= 0;
    this.setAttributeMandatoryEventListeners[key]++;
  }

  unregisterSetAttributeMandatoryEventListeners(key: string): void {
    if (this.setAttributeMandatoryEventListeners[key]) {
      this.setAttributeMandatoryEventListeners[key]--;
    }
  }

  get setAttributeMandatoryEventMap$(): Observable<Record<string, SetAttributeMandatoryEvent>> {
    return this.setAttributeMandatoryEventMapObservable;
  }

  notifySetAttributeMandatoryEvent(event: SetAttributeMandatoryEvent): void {
    const key = this.generateMapKeyForAttributeBasedEvent(event);

    event.toBeConsumed = this.setAttributeMandatoryEventListeners[key];
    this.setAttributeMandatoryEventMap[key] = event;
    this.setAttributeMandatoryEventMapSubject.next(this.setAttributeMandatoryEventMap);
  }

  containsSetAttributeMandatoryEvent(key: string): boolean {
    return !!this.setAttributeMandatoryEventMap[key];
  }

  getSetAttributeMandatoryEvent(key: string): SetAttributeMandatoryEvent {
    return this.setAttributeMandatoryEventMap[key];
  }

  removeSetAttributeMandatoryEvent(key: string, notify: boolean): void {
    if (this.setAttributeMandatoryEventMap[key].toBeConsumed) {
      this.setAttributeMandatoryEventMap[key].toBeConsumed!--;
    }

    if (!this.setAttributeMandatoryEventMap[key].toBeConsumed) {
      delete this.setAttributeMandatoryEventMap[key];
    }

    notify && this.setAttributeMandatoryEventMapSubject.next(this.setAttributeMandatoryEventMap);
  }

  registerSetAttributeEditableEventListeners(key: string): void {
    this.setAttributeEditableEventListeners[key] ??= 0;
    this.setAttributeEditableEventListeners[key]++;
  }

  unregisterSetAttributeEditableEventListeners(key: string): void {
    if (this.setAttributeEditableEventListeners[key]) {
      this.setAttributeEditableEventListeners[key]--;
    }
  }

  get setAttributeEditableEventMap$(): Observable<Record<string, SetAttributeEditableEvent>> {
    return this.setAttributeEditableEventMapObservable;
  }

  notifySetAttributeEditableEvent(event: SetAttributeEditableEvent): void {
    const key = this.generateMapKeyForAttributeBasedEvent(event);

    event.toBeConsumed = this.setAttributeEditableEventListeners[key];
    this.setAttributeEditableEventMap[key] = event;
    this.setAttributeEditableEventMapSubject.next(this.setAttributeEditableEventMap);
  }

  containsSetAttributeEditableEvent(key: string): boolean {
    return !!this.setAttributeEditableEventMap[key];
  }

  getSetAttributeEditableEvent(key: string): SetAttributeEditableEvent {
    return this.setAttributeEditableEventMap[key];
  }

  removeSetAttributeEditableEvent(key: string, notify: boolean): void {
    if (this.setAttributeEditableEventMap[key].toBeConsumed) {
      this.setAttributeEditableEventMap[key].toBeConsumed!--;
    }

    if (!this.setAttributeEditableEventMap[key].toBeConsumed) {
      delete this.setAttributeEditableEventMap[key];
    }

    notify && this.setAttributeEditableEventMapSubject.next(this.setAttributeEditableEventMap);
  }

  generateMapKey(artifactTypeId: string, attributeId: string): string {
    return `${artifactTypeId}_${attributeId}`;
  }

  private generateMapKeyForAttributeBasedEvent(event: AttributeBasedEvent): string {
    return this.generateMapKey(event.artifactTypeId, event.attributeId);
  }
}
