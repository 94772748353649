import { Padding } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { ID_KEY, URL_KEY_VALUE_ARTIFACT_ID, URL_KEY_VALUE_SAVE_TO_FOLDER_ID } from '@shared/constants/constants';
import { DefaultElvisActions } from '@shared/types/actions.types';
import { SelectOption } from '@shared/types/shared.types';
import { ActionItem, ActionItemDto } from '@widgets/menu-widget/types/menu-action-types';
import { ArtifactFilter, ArtifactFilterDto } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { cloneDeep } from 'lodash';
import { ListWidgetOptions } from '../types/list-widget-options.types';
import { GroupAttributeItem, GroupingSettings, GroupMetaDataItem } from './list-widget-grouping.types';

export const DEFAULT_CELL_PADDING = '0.1rem';

export class ListWidgetTableSettings {
  loadMode: ListWidgetTableLoadModeEnum = ListWidgetTableLoadModeEnum.byArtifactType;
  folderPath = '';
  folderId = '';
  background: TableBackgroundSettings = {};
  showTable = true;
  showSum = false;
  minDecimalPlacesOfSum?: number;
  maxDecimalPlacesOfSum?: number;
  showCount = false;
  // TODO: remove link buttons if inside link popup
  showLinkButtons?: boolean = true;
  sticky = true;
  caption: string | null = null;
  summary: string | null = null;
  emptyMessage = 'No records found';
  multiselect = false;
  editableRow = true;
  rowsPerPage = 50;
  scrollHeight = '';
  columnResizeMode: ListWidgetColumnResizeModeEnum = ListWidgetColumnResizeModeEnum.fit;
  actions: ActionItem[] = [new ActionItem()];
  responsive: ListWidgetTableResponsiveEnum = ListWidgetTableResponsiveEnum.rebuild;
  size: ListWidgetTableSizeEnum = ListWidgetTableSizeEnum.small;
  textWrap: ListWidgetTextWrapEnum = ListWidgetTextWrapEnum.nowrap;
  showLineBreaks = false;
  rowsStriped = false;
  customColors = false;
  showAddButton = false;
  showExportButton = false;
  exportFileName = 'export.xls';
  addButtonLabel = 'Add';
  doFilterOnUrlChange = true;
  doSortOnUrlChange = true;
  emitFolderUrl = false;
  groupingOptions: SelectOption<string, GroupAttributeItem>[] = [];
  groupMetaData: Record<string, GroupMetaDataItem> | null = null;
  grouping = new GroupingSettings();
  filtersForQueryParams: ArtifactFilter[] = [];

  cellPaddings: Padding = {
    paddingBottom: DEFAULT_CELL_PADDING,
    paddingLeft: DEFAULT_CELL_PADDING,
    paddingRight: DEFAULT_CELL_PADDING,
    paddingTop: DEFAULT_CELL_PADDING,
  };

  urlToNavigate = `/page`;
  urlKeys = {
    listeningKeys: {
      artifactId: URL_KEY_VALUE_ARTIFACT_ID,
    },
    emittingKeys: {
      saveToFolderId: URL_KEY_VALUE_SAVE_TO_FOLDER_ID,
    },
  };

  constructor(settings?: Partial<ListWidgetTableSettings>) {
    settings && Object.assign(this, settings);
  }

  get isRowClickHandle(): boolean {
    return !!this.actions.filter(action => action.type !== DefaultElvisActions.doNothing).length;
  }

  toServer(): ListWidgetTableSettingsDto {
    const dto = cloneDeep(this) as ListWidgetTableSettings;
    dto.groupingOptions.forEach(option => {
      option.value.dataTypeId = option.value.dataType?.id || null;
      delete option.value.dataType;
    });
    dto.filtersForQueryParams = dto.filtersForQueryParams.map((filter: ArtifactFilter) => filter.toServer() as any);
    dto.actions && (dto.actions = dto.actions.map(action => action.toServer()) as ActionItem[]);
    return dto as ListWidgetTableSettingsDto;
  }

  fromDto(dto: ListWidgetTableSettingsDto, options: ListWidgetOptions) {
    dto.groupingOptions.forEach(option => {
      option.value.dataType && (option.value.dataType = options.dataTypes.listMap[option.value.dataType.id]);
    });
    Object.assign(this, dto);
    const clickActionSettingsOptions: ClickActionSettingsOptions = {
      attributes: options.attributes.list,
      dataTypes: options.dataTypes.list,
      pages: options.pages.toSelectOptions('name', ID_KEY),
      users: options.users,
    };
    this.filtersForQueryParams = this.filtersForQueryParams.map((filterDto: ArtifactFilterDto) =>
      ArtifactFilter.fromDtoAndOptions(filterDto, clickActionSettingsOptions),
    );
    dto.actions?.length && (this.actions = dto.actions.map(action => new ActionItem(action as ActionItemDto)));
  }
}

export enum ListWidgetTableLoadModeEnum {
  byArtifactType = 'byArtifactType',
  byFolder = 'byFolder',
  byModule = 'byModule',
}

export enum ListWidgetTableResponsiveEnum {
  rebuild = 'rebuild',
  scroll = 'scroll',
}

export enum ListWidgetTableSizeEnum {
  base = 'none',
  small = 'sm',
  normal = 'n',
  large = 'lg',
}

export enum ListWidgetColumnResizeModeEnum {
  fit = 'fit',
  expand = 'expand',
}

export enum ListWidgetTextWrapEnum {
  wrap = 'wrap',
  nowrap = 'nowrap',
}

export class SummaryItem {
  isNumeric = false;
  attributeId: string;
  // summary on page and for all artifacts
  summary = 0;
  totalSummary = 0;
  attributeIcon: string;
  groupSummary: Record<string, number> = {};

  constructor(item?: Partial<SummaryItem>) {
    item && Object.assign(this, item);
  }
}

export interface TableBackgroundSettings {
  header?: string;
  footer?: string;
  rows?: string;
  rowsOdd?: string;
}

export interface ListWidgetTableSettingsDto extends ListWidgetTableSettings {
  dataTypeId: string;
}
